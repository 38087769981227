import * as PIXI from "pixi.js";
import {BehaviorSubject} from "rxjs";

export class PixiInputField extends PIXI.Container {
    /**
     * @param bgImage {string}
     * @param value {string}
     * @param resizeManager {ResizeManager}
     */
    constructor(bgImage, value = '', resizeManager) {
        super();

        this.resizeManager = resizeManager;
        this.originWidth = 1080;
        this.originalFontSize = 50;

        this.updateText$ = new BehaviorSubject(value);
        this.bg = this.addChild(PIXI.Sprite.from(bgImage));

        this.inputField = this.createInput();

        this.on('added', () => this.bg.updateTransform());
        resizeManager.resize$
            .subscribe(_ => {
                if (!this.active) { return; }
                this.onUpdateInputPosition();
            });
    }

    onShow() {
        this.active = true;
        this.onUpdateInputPosition();
        this.inputField.style.display = 'block';
    }

    onHide() {
        this.active = false;
        this.inputField.style.display = 'none';
    }

    onUpdateInputPosition() {
        const resizeData = this.resizeManager.resize$.value;

        const { x, y } = this.bg.toGlobal(new PIXI.Point(0, 0));
        const canvasHtml = document.querySelector('#game-container canvas');
        const bounds = canvasHtml.getBoundingClientRect();

        const dX = this.originWidth / bounds.width;
        const realWidth = ((this.bg.width - 60) / dX) * resizeData.scale;
        const realHeight = ((this.bg.height - 20) / dX) * resizeData.scale;
        const realX = ((x + 30) / dX) + bounds.x;
        const realY = ((y + 10) / dX) + bounds.y;

        this.inputField.style.width = `${realWidth}px`;
        this.inputField.style.height = `${realHeight}px`;
        this.inputField.style.top = `${realY}px`;
        this.inputField.style.left = `${realX}px`;
        this.inputField.style.fontSize = `${(this.originalFontSize / dX) * resizeData.scale }px`;
    }

    createInput() {
        const input = document.createElement('input');
        input.setAttribute('type', 'text');
        input.setAttribute('class', 'input-hidden');
        document.body.appendChild(input);
        input.style.display = 'block';

        this.updateText$
            .subscribe(value => {
                input.value = value;
            })

        input.addEventListener('keyup', e => {
            this.updateText$.next(e.target.value);
        });

        input.addEventListener('change', e => {
            this.updateText$.next(e.target.value);
        });

        return input;
    }
}
