import * as PIXI from "pixi.js";

export class FitPixiText extends PIXI.Text {
    /**
     * @param text {string}
     * @param options {PIXI.ITextStyle}
     * @param maxWidth {number}
     */
    constructor(text, options, maxWidth = 300) {
        super(text, options);

        this.__maxWidth = maxWidth;
        this._previousLength = 0;
    }

    set text(text) {
        super.text = text;

        if (!text) {
            return;
        }

        if (this._previousLength === text.length) {
            return;
        }

        this._previousLength = text.length;

        const bounds = this.getBounds(true);
        if (bounds.width > this.__maxWidth) {
            this.scale.set(this.__maxWidth / bounds.width);
        } else {
            this.scale.set(1);
        }
    }
}
