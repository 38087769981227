import {PlayersPositions} from "./PlayersPositions.js";

export default {
  TeamColors: ['blue', 'red', 'green', 'yellow'],
  Teams: [PlayersPositions.TL, PlayersPositions.TR, PlayersPositions.BR, PlayersPositions.BL],
  PlayersPositions: [PlayersPositions.BL, PlayersPositions.TL, PlayersPositions.TR, PlayersPositions.BR],
  // Teams: ["blue", "red"],
  Team2List: [PlayersPositions.BL, PlayersPositions.TR],
  Team4List: [PlayersPositions.BL, PlayersPositions.TL, PlayersPositions.TR, PlayersPositions.BR],
  TeamsSmall1: ["blue", "green"],
  TeamsSmall2: ["yellow", "red"],
  DETERMINANT_BALANCE: 100000,
  LobbyRooms: [
    { betAmount: 20, players: 2 },
    { betAmount: 50, players: 4 },
    { betAmount: 75, players: 2 },
    { betAmount: 100, players: 2 },
    { betAmount: 125, players: 2 },
    { betAmount: 150, players: 2 },
  ],
  Server: {
    TOKEN_TO_MOVE: "TOKEN_TO_MOVE",
    TOKEN_EATEN: "TOKEN_EATEN",
    DICE_ROLLING: "DICE_ROLLING",
    DICE_ENABLE: "DICE_ENABLE",
    PLAYER_AUTO_PLAY: "PLAYER_AUTO_PLAY",
    PLAYER_PLAY_AGAIN: "PLAYER_PLAY_AGAIN",
    TIMER_START: "TIMER_START",
    ROOM_DISPOSING: "ROOM_DISPOSING",
    GAME_START: "GAME_START",
    GAME_END: "GAME_END",
  },
  Client: {
    DICE_ROLL: "DICE_ROLL",
    PLAYER_PLAY_AGAIN: "PLAYER_PLAY_AGAIN",
    LEAVE_GAME: "LEAVE_GAME",
    GAME_END: "GAME_END",
    __SYNC__: "__SYNC__"
  },
  Errors: {
    NOT_ENOUGH_MONEY: 'NOT_ENOUGH_MONEY',
    NOT_VALID_CURRENCY: 'NOT_VALID_CURRENCY'
  },
  CriticalErrors: {
    SERVER_CRIT_ERROR: 'SERVER_CRIT_ERROR'
  },
  Values: {
    TIMER_SECONDS: 15,
    MAX_SIX_COUNTER: 3,
    NUM_OF_FIELDS: 51,
    SAFE_FIELDS: ["0", "8", "13", "21", "26", "34", "39", "47"],
    Teams: {
      [PlayersPositions.TL]: {
        START_FIELD: "0",
        FINISH_FIELD: "50",
      },
      [PlayersPositions.TR]: {
        START_FIELD: "13",
        FINISH_FIELD: "11",
      },
      [PlayersPositions.BR]: {
        START_FIELD: "26",
        FINISH_FIELD: "24",
      },
      [PlayersPositions.BL]: {
        START_FIELD: "39",
        FINISH_FIELD: "37",
      },
    },
  },
  GameType: {
    SINGLEPLAYER: "SINGLEPLAYER",
    MULTIPLAYER: "MULTIPLAYER",
  },
  TIMER_START_SECONDS: 0,
  TIMER_SECONDS: 15,
  FAST_MOVE_TIMER_SECONDS: 5,
  MULTI_GAME_TIMER_START_SECONDS: 3,
  MULTI_GAME_TIMER_SECONDS: 7,
};