import * as PIXI from "pixi.js";
import i18next from "i18next";
import { gsap } from "gsap";
import {BehaviorSubject} from "rxjs";
import {SpriteButton} from "../objects/SpriteButton";
import {onButtonPressedAnimation} from "../helpers/buttonHelper";
import {PixiInputField} from "../objects/PixiInputField";

export class YourPreferences extends PIXI.Container {
    /**
     * @param model {Model}
     * @param server {Server}
     * @param soundManager {SoundManager}
     * @param resizeManager {ResizeManager}
     */
    constructor(model, server, soundManager, resizeManager) {
        super();

        this.originalWidth = 1080;
        this.originalHeight = 1920;

        this.active = false;

        this.model = model;
        this.server = server;
        this.soundManager = soundManager;
        this.resizeManager = resizeManager;

        this.selectedUsername$ = new BehaviorSubject(this.model.gdh.username);
        this.selectedAvatarIndex$ = new BehaviorSubject(this.model.gdh.avatarIndex);
        this.selectedHiddenUsername$ = new BehaviorSubject(this.model.gdh.usernameHidden);

        this.addTopBar();
        this.addInputUsername();
        this.addChooseAvatar();

        const avatar0 = this.addAvatar(0);
        avatar0.position.set(320, 770);

        const avatar1 = this.addAvatar(1);
        avatar1.position.set(540, 770);

        const avatar2 = this.addAvatar(2);
        avatar2.position.set(760, 770);

        const avatar3 = this.addAvatar(3);
        avatar3.position.set(320, 980);

        const avatar4 = this.addAvatar(4);
        avatar4.position.set(540, 980);

        const avatar5 = this.addAvatar(5);
        avatar5.position.set(760, 980);

        // this.addHideUsername();
        // this.addInfoText();
        this.addSaveBtn();

        this.model.playerPreferences$.subscribe(_ => {
            this.selectedUsername$.next(this.model.gdh.username);
            this.selectedAvatarIndex$.next(this.model.gdh.avatarIndex);
            this.selectedHiddenUsername$.next(this.model.gdh.usernameHidden);
        });
    }

    onOpen() {
        this.active = true;
        this.input.onShow();
    }

    onClose() {
        this.active = false;
        this.input.onHide();
    }

    addTopBar() {
        const topBarText = this.addChild(new PIXI.Text(i18next.t('menu.your_preferences'), {
            fontFamily: "Mybahnschrift",
            fontSize: "64px",
            fill: "#ffffff",
            align: "center",
        }));
        topBarText.anchor.set(0.5);
        topBarText.position.set(538, 76);
    }

    addInputUsername() {
        this.input = this.addChild(new PixiInputField('inputBg', this.selectedUsername$.value, this.resizeManager));
        this.input.position.set(215, 392);
        this.input.updateText$.subscribe(value => {
            this.selectedUsername$.next(value);
        })
    }

    addChooseAvatar() {
        const text = this.addChild(new PIXI.Text(i18next.t('menu.choose_avatar'), {
            fontFamily: "Mybahnschrift",
            fontSize: "40px",
            fill: "#FFD809",
            align: "center",
            wordWrap: true,
            wordWrapWidth: 1200
        }));
        text.position.set(382, 563);
    }

    addAvatar(avatarIndex) {
        const container = this.addChild(new PIXI.Container());
        const avatar = container.addChild(PIXI.Sprite.from(`yourPrefAvatar${avatarIndex + 1}`));
        avatar.anchor.set(0.5);
        const frame = container.addChild(new PIXI.AnimatedSprite([
            PIXI.Texture.from('yourPrefAvatarFrame'),
            PIXI.Texture.from('yourPrefAvatarFrameActive'),
        ]));
        frame.anchor.set(0.5);
        frame.gotoAndStop(0);

        container.eventMode = 'static';
        container.on('pointerdown', () => {
            this.soundManager.playSound('click');
            this.selectedAvatarIndex$.next(avatarIndex);
        });

        this.selectedAvatarIndex$
            .subscribe(index => {
                frame.gotoAndStop(index === avatarIndex ? 1 : 0);
        });

        return container;
    }

    addHideUsername() {
        const container = this.addChild(new PIXI.Container())
        container.position.set(328, 1092);

        container.hitArea = new PIXI.Rectangle(0, 0, 400, 80);
        container.eventMode = 'static';
        container.buttonMode = true;
        container.on('pointerdown', () => {
            this.soundManager.playSound('click');
            this.selectedHiddenUsername$.next(!this.selectedHiddenUsername$.value);
        });

        const text = container.addChild(new PIXI.Text(i18next.t('menu.hide_username'), {
            fontFamily: "Mybahnschrift",
            fontSize: "40px",
            fill: "#FFD809",
            align: "center",
            wordWrap: true,
            wordWrapWidth: 1200
        }));
        text.position.set(102, 16);

        const bg = container.addChild(PIXI.Sprite.from('yourPrefCheckBg'));
        bg.position.set(25, 15);

        const check = container.addChild(PIXI.Sprite.from('yourPrefCheckMark'));
        check.position.set(33, 27);
        check.visible = !!this.selectedHiddenUsername$.getValue();

        this.selectedHiddenUsername$
            .subscribe(value => {
                check.visible = !!value;
                // this.model.gdh.usernameHidden = value;
            });
    }

    addInfoText() {
        const text = this.addChild(new PIXI.Text(i18next.t('menu.other_text'), {
            fontFamily: "Mybahnschrift",
            fontSize: "34px",
            fill: "#FFFFFF",
            align: "center",
            wordWrap: true,
            wordWrapWidth: 640,
        }));
        text.anchor.set(0.5);
        text.position.set(544, 1250);
    }

    addSaveBtn() {
        const saveBtn = this.addChild(new SpriteButton({
            default: 'playButton',
            over: 'playButtonHover',
            pressed: 'playButtonPressed'
        }))
        saveBtn.pivot.set(saveBtn.width / 2, saveBtn.height / 2);
        saveBtn.position.set(540, 1483);

        const loading = saveBtn.addChild(PIXI.Sprite.from('waitingCircle'));
        loading.pivot.set(loading.width / 2, loading.height / 2);
        loading.position.set(175, 87);
        loading.visible = false;

        const timeline = gsap.timeline({ paused: true, repeat: -1 })
            .fromTo(loading, {
                angle: -360
            }, {
                angle: 0,
                duration: 1.2
            });

        const text = saveBtn.addChild(new PIXI.Text(i18next.t('menu.save'), {
            fontFamily: 'Mybahnschrift',
            fontSize: '64px',
            fontWeight: '800',
            fill: "#FFD809",
            align: "center",
            dropShadow: true,
            dropShadowColor: '#000000',
            dropShadowAngle: 0.6,
            dropShadowDistance: 7,
        }));
        text.pivot.set(0.5);
        text.position.set(98, 47);

        saveBtn.pressed$.subscribe(_ => {
            this.soundManager.playSound('click');
            onButtonPressedAnimation(saveBtn)
        });
        saveBtn.click$.subscribe(_ => {
            saveBtn.disabled = true;
            loading.visible = true;
            text.visible = false;

            timeline.restart();

            this.model.gdh.username = `${this.selectedUsername$.value || ''}`;
            this.model.usernameSaved$.next(this.model.gdh.username);
            this.server.saveUsername(`${this.selectedUsername$.value || ''}`);

            this.model.gdh.avatarIndex = this.selectedAvatarIndex$.value;
            this.model.avatarSaved$.next(this.selectedAvatarIndex$.value);
            this.server.saveAvatar(this.selectedAvatarIndex$.value);

            this.model.gdh.usernameHidden = this.selectedHiddenUsername$.value;
            this.server.hideUsername(this.selectedHiddenUsername$.value);

            this.server.updatePreserenses$.next(undefined);

            gsap.delayedCall(0.7, () => {
                timeline.pause(1);
                saveBtn.disabled = false;
                loading.visible = false;
                text.visible = true;
                this.model.closeMenu$.next(true);
            });
        });
    }
}
