import * as PIXI from "pixi.js";
import i18next from 'i18next';
import {BaseModal} from "./BaseModal";

export class RestoreModal extends BaseModal {
  constructor(rootContainer, model, resizeManager, soundManager, server) {
    super(rootContainer, model, resizeManager, soundManager);

    this.server = server;
  }

  createModal() {
    const container = new PIXI.Container();
    const bg = container.addChild(PIXI.Sprite.from('restoreModal'));
    bg.pivot.set(bg.width / 2, bg.height / 2);
    bg.position.set(541, 1050);

    this.text = container.addChild(new PIXI.Text(`${i18next.t('modals.incomplete_game')}`, {
      fontFamily: "Akshar-Medium",
      fontSize: "50px",
      fill: ["#FFFFFF"],
      align: "center",
      stroke: "#490000",
      strokeThickness: 8,
      wordWrapWidth: 900,
      wordWrap: true,
      dropShadow: true,
      dropShadowColor: "#000000",
      dropShadowDistance: 5,
      dropShadowAngle: 0.5,
      leading: 20,
    }));
    this.text.anchor.set(0.5);
    this.text.position.set(545, 1081);

    this.attention = container.addChild(new PIXI.Text(`${i18next.t('modals.progress_lost')}`, {
      fontFamily: "Akshar-Regular",
      fontSize: "40px",
      fill: ["#FFFFFF"],
      align: "center",
      leading: 20,
      wordWrapWidth: 900,
      wordWrap: true,
    }));
    this.attention.anchor.set(0.5);
    this.attention.position.set(545, 1600);

    this.confirmButton = this.createBtn(container, 'modals.continue', () => this.onConfirm());
    this.confirmButton.position.set(291, 1390);

    this.cancelButton = this.createBtn(container, 'modals.new_game', () => this.onExit());
    this.cancelButton.position.set(754, 1390);
    this.addAnimationItems([bg, this.text, this.confirmButton, this.cancelButton, this.attention]);

    return container;
  }

  async onConfirm() {
    this.confirmButton.disabled = true;
    await this.hide();

    const object = this.model.restoreModal$.getValue();
    if (object && typeof object.start === 'function') {
      object.start();
    }

    this.model.restoreModal$.next(false);
  }

  async onExit() {
    // this.cancelButton.disabled = true;
    await this.hide();

    const object = this.model.restoreModal$.getValue();
    if (object && typeof object.close === 'function') {
      object.close();
    }

    this.model.restoreModal$.next(false);
  }

  async show() {
    this.confirmButton.disabled = false;
    this.cancelButton.disabled = false;
    super.show();
  }
}
