export const PlayersPositions = {
    BL: 'bottom-left',
    TL: 'top-left',
    TR: 'top-right',
    BR: 'bottom-right',
};

const GameProperties = {
    currentPlayerTeam: PlayersPositions.BL,
    startFieldIndexes: {
        [PlayersPositions.TL]: 0,
        [PlayersPositions.TR]: 13,
        [PlayersPositions.BR]: 26,
        [PlayersPositions.BL]: 39
    },
    fields: [
        //fields are starting from blue player and following it's moving path
        //h
        {x: 63, y: 252, start: PlayersPositions.TL, safe: true, tokens: []},
        {x: 105, y: 252, tokens: []},
        {x: 146, y: 252, tokens: []},
        {x: 189, y: 252, tokens: []},
        {x: 230, y: 252, tokens: []},
        //v
        {x: 275, y: 208, tokens: []},
        {x: 275, y: 165, tokens: []},
        {x: 275, y: 123, tokens: []},
        {x: 275, y: 83, safe: true, tokens: []},
        {x: 275, y: 41, tokens: []},
        //h
        {x: 275, y: -2, tokens: []},
        {x: 319, y: -2, tokens: []},
        {x: 364, y: -2, tokens: []},
        //v
        {x: 364, y: 40, start: PlayersPositions.TR, safe: true, tokens: []},
        {x: 364, y: 82, tokens: []},
        {x: 364, y: 124, tokens: []},
        {x: 364, y: 166, tokens: []},
        {x: 364, y: 209, tokens: []},
        //h
        {x: 408, y: 253, tokens: []},
        {x: 449, y: 253, tokens: []},
        {x: 491, y: 253, tokens: []},
        {x: 533, y: 253, safe: true, tokens: []},
        {x: 575, y: 253, tokens: []},
        //v
        {x: 617, y: 253, tokens: []},
        {x: 617, y: 297, tokens: []},
        {x: 617, y: 342, tokens: []},
        //h
        {x: 575, y: 342, start: PlayersPositions.BR, safe: true, tokens: []},
        {x: 533, y: 342, tokens: []},
        {x: 491, y: 342, tokens: []},
        {x: 449, y: 342, tokens: []},
        {x: 407, y: 342, tokens: []},
        //v
        {x: 363, y: 386, tokens: []},
        {x: 363, y: 427, tokens: []},
        {x: 363, y: 469, tokens: []},
        {x: 363, y: 510, safe: true, tokens: []},
        {x: 363, y: 552, tokens: []},
        //h
        {x: 363, y: 595, tokens: []},
        {x: 318, y: 595, tokens: []},
        {x: 273, y: 595, tokens: []},
        //v
        {x: 273, y: 553, start: PlayersPositions.BL, safe: true, tokens: []},
        {x: 273, y: 510, tokens: []},
        {x: 273, y: 469, tokens: []},
        {x: 273, y: 426, tokens: []},
        {x: 273, y: 385, tokens: []},
        //h
        {x: 230, y: 342, tokens: []},
        {x: 188, y: 342, tokens: []},
        {x: 146, y: 342, tokens: []},
        {x: 104, y: 342, safe: true, tokens: []},
        {x: 62, y: 342, tokens: []},
        //v
        {x: 20, y: 342, tokens: []},
        {x: 20, y: 298, tokens: []},
        {x: 20, y: 252, tokens: []},
    ],
    blocks: [
        {x: 62, y: 318, team: PlayersPositions.TL},
        {x: 319, y: 61, team: PlayersPositions.TR},
        {x: 575, y: 318, team: PlayersPositions.BR},
        {x: 318, y: 574, team: PlayersPositions.BL},
    ],
    finish: {
        b0: {x: 62, y: 298, tokens: [], lIndex: 0},
        b1: {x: 104, y: 298, tokens: [], lIndex: 1},
        b2: {x: 146, y: 298, tokens: [], lIndex: 2},
        b3: {x: 188, y: 298, tokens: [], lIndex: 3},
        b4: {x: 230, y: 298, tokens: [], lIndex: 4},
        b5: {x: 276, y: 298, tokens: [], lIndex: 5, finish: PlayersPositions.TL},

        r0: {x: 319, y: 39, tokens: [], lIndex: 0},
        r1: {x: 319, y: 82, tokens: [], lIndex: 1},
        r2: {x: 319, y: 124, tokens: [], lIndex: 2},
        r3: {x: 319, y: 165, tokens: [], lIndex: 3},
        r4: {x: 319, y: 207, tokens: [], lIndex: 4},
        r5: {x: 319, y: 257, tokens: [], lIndex: 5, finish: PlayersPositions.TR},

        g0: {x: 575, y: 297, tokens: [], lIndex: 0},
        g1: {x: 533, y: 297, tokens: [], lIndex: 1},
        g2: {x: 491, y: 297, tokens: [], lIndex: 2},
        g3: {x: 449, y: 297, tokens: [], lIndex: 3},
        g4: {x: 407, y: 297, tokens: [], lIndex: 4},
        g5: {x: 359, y: 297, tokens: [], lIndex: 5, finish: PlayersPositions.BR},

        y0: {x: 318, y: 552, tokens: [], lIndex: 0},
        y1: {x: 318, y: 510, tokens: [], lIndex: 1},
        y2: {x: 318, y: 467, tokens: [], lIndex: 2},
        y3: {x: 318, y: 426, tokens: [], lIndex: 3},
        y4: {x: 318, y: 384, tokens: [], lIndex: 4},
        y5: {x: 318, y: 338, tokens: [], lIndex: 5, finish: PlayersPositions.BL},
    },
    // players properties with inital x and y position
    players: [
        {
            x: 83,
            y: 63,
            team: PlayersPositions.TL,
            name: "blue1",
            tokenGameObject: null,
            fieldIndex: -1,
            index: 0,
        },
        {
            x: 168,
            y: 63,
            team: PlayersPositions.TL,
            name: "blue2",
            tokenGameObject: null,
            fieldIndex: -1,
            index: 1,
        },
        {
            x: 83,
            y: 147,
            team: PlayersPositions.TL,
            name: "blue3",
            tokenGameObject: null,
            fieldIndex: -1,
            index: 2,
        },
        {
            x: 168,
            y: 147,
            team: PlayersPositions.TL,
            name: "blue4",
            tokenGameObject: null,
            fieldIndex: -1,
            index: 3,
        },
        {
            x: 474,
            y: 63,
            team: PlayersPositions.TR,
            name: "red1",
            tokenGameObject: null,
            fieldIndex: -1,
            index: 0,
        },
        {
            x: 560,
            y: 63,
            team: PlayersPositions.TR,
            name: "red2",
            tokenGameObject: null,
            fieldIndex: -1,
            index: 1,
        },
        {
            x: 474,
            y: 147,
            team: PlayersPositions.TR,
            name: "red3",
            tokenGameObject: null,
            fieldIndex: -1,
            index: 2,
        },
        {
            x: 560,
            y: 147,
            team: PlayersPositions.TR,
            name: "red4",
            tokenGameObject: null,
            fieldIndex: -1,
            index: 3,
        },
        {
            x: 468,
            y: 445,
            team: PlayersPositions.BR,
            name: "green1",
            tokenGameObject: null,
            fieldIndex: -1,
            index: 0,
        },
        {
            x: 553,
            y: 445,
            team: PlayersPositions.BR,
            name: "green2",
            tokenGameObject: null,
            fieldIndex: -1,
            index: 1,
        },
        {
            x: 468,
            y: 530,
            team: PlayersPositions.BR,
            name: "green3",
            tokenGameObject: null,
            fieldIndex: -1,
            index: 2,
        },
        {
            x: 553,
            y: 530,
            team: PlayersPositions.BR,
            name: "green4",
            tokenGameObject: null,
            fieldIndex: -1,
            index: 3,
        },
        {
            x: 82,
            y: 452,
            team: PlayersPositions.BL,
            name: "yellow1",
            tokenGameObject: null,
            fieldIndex: -1,
            index: 0,
        },
        {
            x: 168,
            y: 452,
            team: PlayersPositions.BL,
            name: "yellow2",
            tokenGameObject: null,
            fieldIndex: -1,
            index: 1,
        },
        {
            x: 82,
            y: 538,
            team: PlayersPositions.BL,
            name: "yellow3",
            tokenGameObject: null,
            fieldIndex: -1,
            index: 2,
        },
        {
            x: 168,
            y: 538,
            team: PlayersPositions.BL,
            name: "yellow4",
            tokenGameObject: null,
            fieldIndex: -1,
            index: 3,
        },
    ],
};

export default GameProperties;
