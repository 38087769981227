import {gsap} from "gsap";

/**
 * Animate button pressed
 * @param animation
 * @param name
 * @param value
 */
export const onPlayAnimation = (animation, name, value) => {
  setDisabledAnimation(animation);

  const duration = animation.animations[name];
  animation.setAnimation(name);
  animation.visible = true;

  animation.tween = gsap.fromTo(animation,
    {
      time: 0,
    },
    {
      time: duration,
      duration: value.time + value.time / 2,
      repeat: 0,
      onComplete: setDisabledAnimation,
      onCompleteParams: [animation]
    })
}

const setDisabledAnimation = (animation) => {
  if (animation.tween) {
    animation.tween.pause(1);
    animation.tween.kill();
    animation.tween = null;
    animation.visible = false;
  }
}

