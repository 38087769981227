import { Spine } from "pixi-spine";
import * as PIXI from 'pixi.js';

export class SpineHelper extends Spine {
    /**
     * @param spineName {string}
     */
    constructor(spineName) {
        const spineData = PIXI.Assets.get(spineName).spineData;
        if (!spineData) {
            throw new Error(`Spine by name "${spineName}" not found or this is not spine resource!`);
        }

        super(spineData);

        this.spineData = spineData;
        this.update(0);
    }

    get animations() {
        return this.spineData.animations.reduce((acc, item) => {
            acc[item.name] = item.duration;
            return acc;
        }, {});
    }

    setAnimation(name) {
        this.state.clearTracks();
        this.skeleton.setToSetupPose();
        this.state.setAnimation(0, name, false);
        this.state.update(0);
        this.autoUpdate = false;
        return this;
    }

    get time() {
        const track = this.state.tracks[0];
        return track && track.trackTime || 0;
    }

    set time(value) {
        // TODO: check active anims
        if (!this.state || !this.state.tracks) return;
        const track = this.state.tracks[0];
        if (track){
            track.trackTime = value;
            this.update(1 / 60);
        }
    }

    changeDuration(name, duration) {
        this.spineData.animations.forEach(item => {
            if (item.name === name) {
                item.duration = duration;
            }
        })
    }
}