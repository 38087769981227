import * as PIXI from "pixi.js";
import {gsap} from "gsap";
import {filter, distinctUntilChanged} from "rxjs";
import { EnumSpriteButtonState } from "./SpriteButton";
import { EnumGameModes } from "../helpers/constants/GameModes";
import { SpineHelper } from "../helpers/SpineHelper";
import {Texture} from "pixi.js";
import {PlayersPositions} from "../data/GameProperties";
import {skip} from "rxjs/operators";
import {ReactiveButton} from "./ReactiveButton";
import {onPlayAnimation} from "../helpers/playAnimationHelper";
export class ReactiveRollButton extends ReactiveButton {
    constructor(model) {
        super(model);

        this.orderPlayersDone$ = model.orderPlayersDone$;
        this.timerPlayerMulti$ = model.timerPlayerMulti$;
        this.activePlayerTeam$ = model.activePlayerTeam$;
        this.tokenMove$ = model.tokenMove$;

        /*this.pressed$
            .pipe(
            filter(_ => this.disabled === false && this.autoPlayEnabled === false),
            switchMap(() => timer(3000).pipe(takeUntil(merge(this.out$, this.click$))))
            )
            .subscribe(_ => this.autoPlayEnabled = true);*/

        this.model.autoPlayEnabled$
            .subscribe(active => {
                this.eventMode = 'static';

                this.model.gdh.isAutomaticRollActive = active;

                if (active || this.disabled) {
                    this.eventMode = 'none';
                }
            });

        this.pressed$
            .pipe(filter(_ => this.autoPlayEnabled === true))
            .subscribe(_ => this.autoPlayEnabled = false);

        this.gameMode$
            .pipe(distinctUntilChanged())
            .subscribe(() => {
                this.visible = true;
                if (this.pulsation) this.pulsation.destroy();

                this.container = this.addChild(new PIXI.Sprite(Texture.EMPTY));
                this.container.position.set(175, 87);

                this.pulsation = this.createAnimation('active');
            })

        this.timerPlayerMulti$
            .pipe(
                distinctUntilChanged(),
                //filter(_ => this.gameMode$.value === EnumGameModes.MULTI),
                filter(_ => !this.model.activeMatchMaking$.value),
            )
            .subscribe(value => {
                if (!value.isTimerVisible && this.gameMode$.value === EnumGameModes.SINGLE && this.activeTeam !== PlayersPositions.BL) {
                    //this.setDisabledAnimation(this.bg);
                    this.setDisabledAnimation(this.pulsation);
                }

                if (value.time !== this.model.gdh.gameTimerValue || value.time === 0 /*|| this.bg.visible*/) {
                    return;
                }

                const isTurnToDoDicing = !(this._disabled && this.activeTeam !== PlayersPositions.BL) && !this.autoPlayEnabled;
                if (isTurnToDoDicing) {
                    onPlayAnimation(this.pulsation, 'active', value);
                    this.model.currentPlayerColor$.next(this.model.gdh.selectedColor);
                }

                //this.playAnimation(this.bg, this._disabled && this.activeTeam !== PlayersPositions.BL ? 'disabled' : 'auto', value);

                this.model.enableRollButton$
                    .pipe(skip(1))
                    .subscribe(active => {
                        if (!active && this.pulsation.tween) {
                            this.pulsation.tween.pause(1);
                            this.pulsation.tween.kill();
                            this.pulsation.tween = null;
                            this.pulsation.visible = false;
                        }
                    });
            })

        this.tokenMove$
            .subscribe(() => {
                //this.setDisabledAnimation(this.bg);
                this.setDisabledAnimation(this.pulsation);
            })

        this.activePlayerTeam$
            .pipe(
                distinctUntilChanged(),
            )
            .subscribe(value => {
                this.activeTeam = value;
            })

        /*this.orderPlayersDone$
            .pipe(
                filter(value => value)
            )
            .subscribe(value => {
                this.autoPlayEnabled = true;
                this.autoPlayEnabled$.next(value);
                if (!value) this.disabled = false;
            })*/
    }

    setDisabledAnimation(animation) {
        if (animation.tween) {
            animation.tween.pause(1);
            animation.tween.kill();
            animation.tween = null;
            animation.visible = false;
        }
    }

    createAnimation(name) {
        const animation = this.container.addChild(new SpineHelper('rollBtn'));
        const trackEntry = animation.state.setAnimation(0, name, true);
        trackEntry.timeScale = 0;
        animation.skeleton.setSkinByName('default');
        animation.zIndex = 1;
        return animation;
    }

    get disabled() {
        return this._disabled;
    }

    set disabled(value) {
        this._disabled = value;
        this.eventMode = value ? 'none' : 'static';
        this._state = EnumSpriteButtonState.DEFAULT;

        /*if (this.gameMode$.value === EnumGameModes.SINGLE) {
            this.visible = !value;
            if (value && this.autoPlayEnabled === false) {
                //this.visible = false;
            } else {
                //this.visible = true;
                this.bg.state.setAnimation(0, "idle", true);
            }
        }*/

        /*if (this.gameMode$.value === EnumGameModes.MULTI) {
            // this._timerIsActive = false;
            if (this.bg.tween) {
                //this.bg.tween.pause(1);
                //this.bg.tween.kill();
                //this.bg.tween = null;
            }

            if (value && this.autoPlayEnabled === false) {
                //this.alpha = 0.5;
            } else {
                //this.alpha = 1;
            }
        }*/
    }

    get autoPlayEnabled() {
        return this.model.autoPlayEnabled$.getValue();
    }

    set autoPlayEnabled(value) {
        this.model.autoPlayEnabled$.next(value);
    }

    press() {
        gsap.getTweensOf(this).forEach(tween => {
            tween.pause(1);
            tween.kill();
        });

        gsap.to(this.container, {
            width: 0.95,
            height: 0.95,
            duration: 0.1,
            ease: "power2.out"
        });
    }

    unpress() {
        gsap.getTweensOf(this).forEach(tween => {
            tween.pause(1);
            tween.kill();
        });

        gsap.to(this.container, {
            width: 1,
            height: 1,
            duration: 0.1,
            ease: "power2.out"
        });
    }
}
