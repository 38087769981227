const logWarn = (error, stack) => {
    console.warn(error, stack);
};

const replacerFunc = () => {
    const visited = new WeakSet();
    return (key, value) => {
        if (typeof value === 'object' && value !== null) {
            if (visited.has(value)) {
                return;
            }
            visited.add(value);
        }
        return value;
    };
};

/**
 * Resolve deep object value
 * @example resolve({ a: {b: 2}}, e => e.a.b, 0): 2, if object value is exist, if not = 0;
 * @param object
 * @param fn
 * @param defaultValue
 * @param reasonLog
 */
export const resolve = (object, fn, defaultValue, reasonLog) => {
    try {
        const result = fn(object);

        if (result === undefined && typeof reasonLog === 'string') {
            logWarn(`Warn resolve: Reason: "${reasonLog}" of undefined`);
        }

        return result === undefined ? defaultValue : result;
    } catch (e) {
        if (typeof reasonLog === 'string') {
            logWarn(`Warn resolve: "${reasonLog}" in ${JSON.stringify(object, replacerFunc)}`, e.stack);
        } else if (reasonLog === undefined) {
            logWarn(`Error resolve: "${e.message}" in ${JSON.stringify(object, replacerFunc)}`, e.stack);
        }

        return defaultValue;
    }
};
