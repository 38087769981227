import * as PIXI from "pixi.js";
import {Subject, throttleTime} from "rxjs";
import {EnumSpriteButtonState} from "./SpriteButton";

export class ReactiveButton extends PIXI.Container {
  constructor(model) {
    super();
    this.model = model;

    this.gameMode$ = model.mode$;

    this.click$ = new Subject();
    this.pressed$ = new Subject();
    this.over$ = new Subject();
    this.out$ = new Subject();

    this._state = EnumSpriteButtonState.DEFAULT;
    this._disabled = false;
    this.eventMode = 'static';
    this.buttonMode = true;

    this.sortableChildren = true;

    this.on('pointerdown', this.onDown, this);
    this.on('pointerup', this.onUp, this);
    this.on('pointerover', this.onOver, this);
    this.on('pointerout', this.onOut, this);

    this.pressed$.pipe(throttleTime(100)).subscribe(_ => this.press());
    this.click$.pipe(throttleTime(100)).subscribe(_ => this.unpress());
    this.over$.pipe(throttleTime(100)).subscribe(_ => this.pointerOver());
    this.out$.pipe(throttleTime(100)).subscribe(_ => {
      this.pointerOut();
      this.unpress();
    });
  }

  onDown(event) {
    this._state = EnumSpriteButtonState.PRESSED;
    this.pressed$.next(event);
  }

  onUp(event) {
    this._state = EnumSpriteButtonState.DEFAULT;
    this.click$.next(event);
  }

  onOver(event) {
    this._state = EnumSpriteButtonState.OVER;
    this.over$.next(event);
  }

  onOut(event) {
    this._state = EnumSpriteButtonState.DEFAULT;
    this.out$.next(event);
  }

  press() {}

  unpress() {}

  pointerOver() {}

  pointerOut() {}
}
