import * as PIXI from "pixi.js";
import {filter, skip} from "rxjs";

export class PreloaderScene extends PIXI.Container {
    /**
     * @param loader {GameLoader}
     */
    constructor(loader) {
        super();

        this.canvasWidth = 1080;
        this.canvasHeight = 1920;

        this.subscription = loader.progress$
            .pipe(skip(1), filter(_ => this.visible))
            .subscribe(progress => {
                this.updateLoader(progress)
            });

        this.init();
    }

    init() {
        const logo = this.addChild(PIXI.Sprite.from('preloaderLogo'));
        logo.position.set((this.canvasWidth - logo.width) / 2, 0);

        const barBg = this.addChild(PIXI.Sprite.from('barBg'));
        barBg.position.set((this.canvasWidth - barBg.width) / 2, this.canvasHeight - 480);

        this.barFill = this.addChild(PIXI.Sprite.from('barFill'));
        this.barFill.position.set((this.canvasWidth - this.barFill.width) / 2, this.canvasHeight - 473);

        const loadingText = this.addChild(PIXI.Sprite.from('loading'));
        loadingText.position.set((this.canvasWidth - loadingText.width) / 2, this.canvasHeight - 360);
    }

    updateLoader(progress) {
        if (!this.barFill) { return; }
        const width = this.barFill.width;
        const height = this.barFill.height;

        if (this.maskFill) {
            this.barFill.mask = undefined;
            this.maskFill.destroy(true);
            this.maskFill = undefined;
        }

        this.maskFill = new PIXI.Graphics();
        this.maskFill.beginFill(0x000000);
        this.maskFill.drawRect(this.barFill.x, this.barFill.y, width * progress, height);
        this.maskFill.endFill();

        this.barFill.mask = this.maskFill;
        this.addChild(this.maskFill);
    }

    onHideScene() {
        if (this.maskFill) {
            this.barFill.mask = undefined;
            this.removeChild(this.maskFill);
            this.maskFill.destroy(true);
            this.maskFill = undefined;
        }

        this.subscription.unsubscribe();
    }
}
