import { Client } from 'colyseus.js';
import { debounceTime, interval, Subject, take } from 'rxjs';
import { distinctUntilChanged, pairwise } from "rxjs/operators";
import { PlayersPositions } from '../data/GameProperties';
import Constants from '../helpers/constants/Constants';
import { EnumGameModes } from '../helpers/constants/GameModes';
import { resolve } from '../helpers/resolve';
import { getParameterByName } from '../helpers/Utils';
import { EnumModals } from "../scenes/ModalsScene";
import GameUtils from "../utils/GameUtils";
import ConnectionService from "./ConnectionService";
import { EventsProxy } from './EventsProxy';
import { Model } from './Model';

export class Server {
  /**
   * @param model {Model}
   */
  constructor(model) {
    this.model = model;

    this.stateListeners = [];

    this._canRejoin = false;

    this._startReconnect = false;
    this.events = new EventsProxy();

    this.mutableTeam = (team) => team;

    this.updatePreserenses$ = new Subject();
    this.updatePreserenses$
      .pipe(debounceTime(1000))
      .subscribe((e) => this.onUpdatePreferences(e));

    this.syncServer$ = new Subject();
    this.syncServer$
      .pipe(debounceTime(300))
      .subscribe((e) => this.sendSyncToServer(e));

    this.sendOfferToGiveUp$ = new Subject();
    this.sendOfferToGiveUp$.subscribe((e) => this.sendOfferToGiveUp(e));

    this.init();
    this.onMessages();

    this.events.on("RECONNECT", async () => {
      if (this._startReconnect === true) {
        return;
      }

      this._startReconnect = true;
      await this.reconnect();
      this._startReconnect = false;
    });

    this.model.rollButtonClicked$.subscribe((_) => this.rollTheDice());

    this.model.selectedToken$.subscribe((tokenIndex) =>
      this.playerSelectedToken(tokenIndex)
    );

    this.model.cashoutModal$
        .pipe(distinctUntilChanged(), pairwise())
        .subscribe(([prevActive, isActive]) => {
          if (prevActive === isActive) { return; }
           this.sendModalCashoutAct(isActive);
        });

    this.model.destroyRoom$
      .subscribe(() => {
        this.destroyRoom();
      });
  }

  init() {
    this.client = new Client(
      getParameterByName("server_url") || process.env.WEB_SOCKET_URL
    );
  }

  async lobby() {
    const user = this.model.gdh.urlParams.get("user");
    const token = this.model.gdh.urlParams.get("token");
    const operatorId = this.model.gdh.urlParams.get('operator_id');
    const currency = this.model.gdh.urlParams.get("currency");

    const ga = await GameUtils.setGoogleAnalitics();
    ga(this.getClientIdGA.bind(this));

    const res = await ConnectionService.send(this.model, {
      operatorId: operatorId,
      supplierUser: user,
      currency: currency,
      token: token
    });

    console.log("clientIdGA", this.clientIdGA);
    this.model.monitoringPlayerTokenMove$.next(false);

    return new Promise((resolve) => {
      if (res && res.status === "OK") {
        this.model.gdh.bets = res.data.settings.bets;
        this.model.gdh.balance = res.data.balance.amount;
        this.model.gdh.currency = res.data.balance.currency;
        this.model.gdh.username = res.data.user.username;
        this.model.gdh.avatarIndex = res.data.user.avatar;
        this.model.gdh.sound = res.data.user.sound;
        this.model.gdh.music = res.data.user.music;

        this.model.gdh.gameSettings = res.data.settings;
        this.realizeGameSettings();

        resolve();
      } else {
        this.model.criticalModal$.next(true);
      }
    });
  }

  realizeGameSettings() {
    if (this.model.gdh.gameSettings.maintenance) {
      this.model.maintenanceModal$.next(true);
    }

    const userId = this.model.gdh.urlParams.get("user");
    if (this.model.gdh.gameSettings.blockedUserIds.includes(userId)) {
      this.model.maintenanceModal$.next(true);
    }

    if (this.model.gdh.gameSettings.types) {
      this.model.gameTypes$.next(this.model.gdh.gameSettings.types);
    }

    if (this.model.gdh.gameSettings.modes) {
      this.model.gameModes$.next(this.model.gdh.gameSettings.modes);
    }

    if (this.model.gdh.gameSettings.players) {
      this.model.availablePlayers$.next(this.model.gdh.gameSettings.players);
    }

    if (this.model.gdh.gameSettings.maintenance) {
      this.model.maintenanceModal$.next(true);
    }
  }

  getClientIdGA(tracker) {
    this.clientIdGA = tracker.get('clientId');
  }

  /**
   * Creates or joins a room, and adds room listeners
   * @return {Promise<void>}
   */
  async join() {
    const user = this.model.gdh.urlParams.get("user");
    const token = this.model.gdh.urlParams.get("token");
    const gameCode = this.model.gdh.urlParams.get("game_code");
    const currency = this.model.gdh.urlParams.get("currency");
    const gameId = this.model.gdh.urlParams.get("gameId");
    const numberOfPlayers = this.model.gdh.numberOfPlayers;
    const restParams = {};

    for (const [key, value] of this.model.gdh.urlParams.entries()) {
      restParams[key] = value;
    }

    this.model.playerPreferences$.next({
      supplier_user: user,
      username: this.model.gdh.username,
      hide_username: this.model.gdh.usernameHidden,
      avatar: this.model.gdh.avatarIndex,
      sound: this.model.gdh.sound,
      music: this.model.gdh.music,
    });

    const data = {
      ...restParams,
      user,
      token,
      gameCode,
      currency,
      gameId,
      gameMode: this.model.mode$.value,
      numberOfPlayers,
      clientIdGA: this.clientIdGA,
    };

    this.isGamestarted = false;
    this.isPlayersAdded = false;
    this.isSuccessRestoreState = undefined;

    try {
      if(!this.roomParameters) {
        this.room = await this.client.create(Constants.ROOM_NAME, data);
      } else {
        this.room = await this.client.joinById(this.roomParameters.roomId, data);
      }

      this.connectedRoom();

      this.onUpdates(0, numberOfPlayers);
    } catch (e) {
      console.log("JOIN ERROR", e);
      this.model.serverError$.next({
        type: Constants.Errors.JOIN.type,
        message: Constants.Errors.JOIN.message,
      });
      this.model.criticalModal$.next(true);
    }
  }

  async joinToMultiRoom() {
    if (this.model.mode$.value !== EnumGameModes.MULTI) {
      return;
    }

    const supplier_user = this.model.gdh.urlParams.get("user");
    const token = this.model.gdh.urlParams.get("token");
    const gameCode =
      this.model.gdh.urlParams.get("game_code") || process.env.GAME_CODE;
    const currency = this.model.gdh.urlParams.get("currency");
    const gameId =
      this.model.gdh.urlParams.get("gameId") || process.env.GAME_ID;
    const numberOfPlayers = this.model.gdh.numberOfPlayers;
    const betAmount = this.model.gdh.currentBet;
    const avatarIndex = this.model.gdh.avatarIndex;
    const username = this.model.gdh.username || "";
    const usernameHidden = this.model.gdh.usernameHidden;
    const color = this.model.gdh.selectedColor;
    const gameMode = this.model.selectedMode$.value;
    const restParams = {};

    for (const [key, value] of this.model.gdh.urlParams.entries()) {
      restParams[key] = value;
    }

    try {
      const availableRooms = await this.client.getAvailableRooms(
        Constants.ROOM_MULTI_NAME
      );

      const matchingRoom = availableRooms.filter(
        (room) =>
          resolve(
            room,
            (e) => e.maxClients === numberOfPlayers,
            false,
            false
          ) &&
          resolve(
            room,
            (e) => e.metadata.betAmount === betAmount,
            false,
            false
          ) &&
          resolve(
            room,
            (e) => e.metadata.currency.toUpperCase() === currency.toUpperCase(),
            false,
            false
          ) &&
          resolve(
            room,
            (e) => e.metadata.players.length < numberOfPlayers,
            false,
            false
          ) &&
          resolve(
            room,
            (e) =>
              !e.metadata.players.some(
                (e) => e.supplier_user === supplier_user
              ),
            false,
            false
          ) &&
          resolve(
            room,
            (e) => e.metadata.gameMode === gameMode,
            false,
            false
          ) &&
          resolve(room, (e) => e.metadata.locked === false, true, false)
      );

      if (matchingRoom.length === 0) {
        this.room = await this.client.create(Constants.ROOM_MULTI_NAME, {
          ...restParams,
          supplier_user,
          token,
          gameCode,
          currency,
          gameId,
          numberOfPlayers,
          betAmount,
          avatarIndex,
          username,
          color,
          gameMode,
          usernameHidden,
        });
      } else {
        this.room = await this.client.joinById(matchingRoom[0].roomId, {
          ...restParams,
          supplier_user,
          token,
          gameCode,
          currency,
          gameId,
          numberOfPlayers,
          betAmount,
          avatarIndex,
          username,
          color,
          gameMode,
          usernameHidden,
        });
      }

      console.log("join multi room", Constants.ROOM_MULTI_NAME, this.room);
      this.connectedRoom();

      const existPlayerLength = resolve(
        matchingRoom,
        (e) => e[0].metadata.players.length,
        0,
        false
      );

      this.onUpdates(existPlayerLength, numberOfPlayers);
    } catch (e) {
      console.log("JOIN ERROR", e);
      this.model.serverError$.next({
        type: Constants.Errors.JOIN.type,
        message: Constants.Errors.JOIN.message,
      });
    }
  }

  connectedRoom() {
    this.events.isConnected = true;
    this.events.availableReconnect = true;
    this.events.onJoinRoom(this.room);
    this.events.clearTimers();

    this.model.gdh.sessionId = this.room.sessionId;
    this.model.gdh.roomId = this.room.id;

    window.r = this.room;

    this.room.onLeave(() => {
      this.model.reconnecting$.next(true);
      this.events.isConnected = false;
    });
  }

  async getRoomPlayers() {
    if (!this.room || !this.room.state) {
      return;
    }

    const configColors = {
      2: { blue: ["green"], red: ["yellow"], green: ["blue"], yellow: ["red"] },
      4: {
        blue: ["red", "green", "yellow"],
        red: ["green", "yellow", "blue"],
        green: ["yellow", "blue", "red"],
        yellow: ["blue", "red", "green"],
      },
    };

    const colors =
      configColors[this.model.gdh.numberOfPlayers][
        this.model.gdh.selectedColor
      ].slice();

    const items = [];
    for (const [sessionId, player] of this.room.state.players.$items) {
      if (sessionId === this.room.sessionId) {
        continue;
      }

      items.push({
        avatarId: player.avatarId,
        username: player.name,
        sessionId,
        color: colors.shift(),
      });
    }

    return items;
  }

  setCheat(diceRolls = []) {
    if (!this.room) {
      return;
    }
    this.events.send("SET_CHEATS", diceRolls);
  }

  destroyRoom() {
    this.events.clearTimers();
    this.events.clearMessages();
    this.events.isConnected = false;
    this.events.availableReconnect = false;
    this.events.stopPingPong();
    this.model.gameRoomStarted$.next(false);

    if (this.model.mode$.value === EnumGameModes.MULTI) {
      this.model.incomesSurrenderPropositions$.next([]);
      this.model.surrenderPropositionCount$.next();
      this.model.sendedSurrenderPropositions$.next([]);
    }

    if (this.room) {
      this.onRemoveStateListeners();
      this.room.removeAllListeners();
      this.room.leave();
      this.room = null;
    }
  }

  async reJoinAfterRoomFailure() {
    if (this._canRejoin === false) {
      return;
    }

    this.model.reconnecting$.next(true);
    this.model.sessionLost$.next(false);

    try {
      this.destroyRoom();

      await this.join();
      this._canRejoin = false;
      this.model.reconnecting$.next(false);

      await interval(1000).pipe(take(1)).toPromise();
    } catch (e) {
      console.warn("RE JOIN ROOM", e);
    } finally {
      this._isRejoining = false;
    }
  }

  /**
   * Reconnect room
   * @return {Promise<void>}
   */
  async reconnect() {
    this.model.reconnecting$.next(true);

    const { roomId, sessionId } = this.model.gdh;

    try {
      if (this.room) {
        this.onRemoveStateListeners();
        this.room.removeAllListeners();
        this.room.connection.close();
        this.room = null;
      }

      this.room = await this.client.reconnect(roomId, sessionId);

      this.events.isConnected = true;
      this.model.reconnecting$.next(false);
      this.events.onJoinRoom(this.room);
      this.events.clearTimers();

      this.room.onLeave(() => {
        this.model.reconnecting$.next(true);
        this.events.isConnected = false;
      });

      window.r = this.room;

      this.onUpdates(0, this.model.gdh.numberOfPlayers, true);

      this.events.send("WAS_RECONNECT");
    } catch (e) {
      if (new RegExp(`room "${roomId}" not found`).test(e.message)) {
        this.model.sessionLost$.next(true);
        this.model.gameRoomStarted$.next(false);
        this.destroyRoom();

        this._canRejoin = true;

        console.error("Server error!", e.message, e.stack);
        return;
      }

      console.error("Reconnect error!", roomId, sessionId, e);
    }
  }

  onUpdates(opponentsBeforeIndex, numbersOfPlayers, isReconnect = false) {
    if (!isReconnect) {
      if (this.model.mode$.value === "single") {
        this.mutableTeam = (team) => team;
      } else {
        this.mutableTeam = (team) =>
          this.getMutableTeamPosition(
            team,
            opponentsBeforeIndex,
            numbersOfPlayers
          );
      }
    }

    this.stateListeners.push(
      this.room.state.listen("activePlayer", (activePlayer) => {
        if (activePlayer) {
          this.model.activePlayerTeam$.next(
            this.mutableTeam(this.room.state.players.get(activePlayer).team)
          );
          this.model.playerActivate$.next(activePlayer);
        }
      })
    );

    this.stateListeners.push(
      this.room.state.listen("timerSecondsLast", (time) => {
        this.model.timerPlayerMulti$.next({
          ...this.model.timerPlayerMulti$.value,
          time,
        });
      })
    );

    this.stateListeners.push(
      this.room.state.listen("timerActive", (available) => {
        this.model.timerPlayerMulti$.next({
          ...this.model.timerPlayerMulti$.value,
          available: available,
          isTimerVisible: available,
        });
      })
    );

    this.stateListeners.push(
      this.room.state.listen("usernameHidden", (usernameHidden) => {
        this.model.gdh.usernameHidden = usernameHidden;
        this.model.usernameHidden$.next(usernameHidden);
      })
    );

    this.stateListeners.push(
      this.room.state.listen("orderPlayersDone", (orderPlayersDone) => {
        this.model.gdh.currentGameState = orderPlayersDone
          ? Constants.GameState.GAME
          : Constants.GameState.START;
        this.model.orderPlayersDone$.next(orderPlayersDone);
      })
    );

    this.room.state.players.onAdd = (player, key) => {
      console.log(">>ADD", player.toJSON(), key === this.room.sessionId);

      if (player.team === PlayersPositions.BL) {
        this.selectedToken = undefined;
      }
      if (this.mutableTeam(player.team) === PlayersPositions.BL) {
        this.model.gdh.cashout = 0;
        this.model.gdh.currentBet = player.betAmount;
      }
      this.model.playerUsernames$[this.mutableTeam(player.team)].next(
        player.usernameHidden ? "__hidden__" : player.name
      );
      this.model.playerAvatars$[this.mutableTeam(player.team)].next(
        player.avatarId
      );
      this.model.playerColors$[this.mutableTeam(player.team)].next(
        this.model.mode$.value === "single"
          ? player.teamColor
          : this.getMutableTeamColor(
              this.mutableTeam(player.team),
              numbersOfPlayers
            )
      );

      player.onChange = (changes) => {
        this.currentTeam = player.team;
        const updateData = this._mergeUpdatesState(changes);
        //console.log("availableTokensToSelect", updateData.availableTokensToSelect);
        if (
          updateData.availableTokensToSelect !== undefined &&
          key === this.room.sessionId
        ) {
          this.model.availableTokens$[this.mutableTeam(player.team)].next(
            updateData.availableTokensToSelect.toArray()
          );
        }

        if (updateData.diceValue !== undefined) {
          if (updateData.diceValue > 0) {
            this.selectedToken = -1;
            // console.log('Get dice ', updateData.diceValue, ' team:', this.mutableTeam(player.team), ' color:', this.model.playerColors$[this.mutableTeam(player.team)].value);
          }

          this.model.dices$[this.mutableTeam(player.team)].next({
            ...this.model.dices$[this.mutableTeam(player.team)].getValue(),
            diceValue: updateData.diceValue,
          });
        }

        if (updateData.diceAnimate !== undefined) {
          this.model.currentPlayerColor$.next(this.getMutableTeamColor(
            this.mutableTeam(player.team),
            numbersOfPlayers
          ));

          this.model.dices$[this.mutableTeam(player.team)].next({
            ...this.model.dices$[this.mutableTeam(player.team)].getValue(),
            diceAnimate: updateData.diceAnimate,
          });
        }

        if (updateData.finishBlocked !== undefined) {
          this.model.blocksVisible$[this.mutableTeam(player.team)].next(
            player.finishBlocked
          );
        }

        if (
          updateData.name !== undefined ||
          updateData.usernameHidden !== undefined
        ) {
          this.model.playerUsernames$[this.mutableTeam(player.team)].next(
            player.usernameHidden ? "__hidden__" : player.name
          );
        }

        if (updateData.avatarId !== undefined) {
          this.model.playerAvatars$[this.mutableTeam(player.team)].next(
            player.avatarId
          );
        }

        if (updateData.team !== undefined && this.room.sessionId === key) {
          const players = this.room.state.players;
          this.model.gdh.team = this.mutableTeam(
            players.get(this.room.sessionId).team
          );
        }

        if (updateData.surrenderPropositionCount !== undefined && this.room.sessionId === key) {
          this.model.surrenderPropositionCount$.next(updateData.surrenderPropositionCount);
        }
      };

      player.tokens.onAdd = (token, index) => {
        token.onChange = (changes) => {
          const updateData = this._mergeUpdatesState(changes);

          const { scaleLength, scalePosition, ...data } = updateData;

          if (updateData.position !== undefined) {
            this.model.tokenMove$.next({team: player.team});
            this.model.tokens$[this.mutableTeam(player.team)][index].next(data);
            this.model.enableCashoutButton$.next({enabled:false});

            if (this.mutableTeam(player.team) === PlayersPositions.BL && updateData.position >= 0) {
              this.model.monitoringPlayerTokenMove$.next(false);
              const numOfTokens = this.model.tokens$[this.mutableTeam(player.team)].filter((el) => el.value.position >= 0).length;
              if (this.selectedToken < 0 && numOfTokens > 1 && !this.model.autoPlayEnabled$.value) {
                this.model.automoveModal$.next(EnumModals.DONT_CLOSE_MENU);
              }
            }
          }

          if (updateData.stateHint !== undefined) {
            this.model.tokenHints$[index].next(data);
          }

          if (scaleLength !== undefined) {
            const scaleConfig =
              this.model.tokensScale$[this.mutableTeam(player.team)][
                index
              ].getValue();
            this.model.tokensScale$[this.mutableTeam(player.team)][index].next({
              ...scaleConfig,
              length: scaleLength,
              position:
                scalePosition === undefined
                  ? scaleConfig.position
                  : scalePosition,
            });
          }
        };
      };

      player.incomesSurrenderPropositions.onAdd = (value) => {
        if (this.room.sessionId === key && value) {
          this.model.incomesSurrenderPropositions$.next([
            ...this.model.incomesSurrenderPropositions$.value,
            value,
          ]);
          this.model.getOfferToGiveUpModal$.next(true);
        }
      };

      player.incomesSurrenderPropositions.onRemove = (value) => {
        if (this.room.sessionId === key && value) {
          const incomesPropositions = this.model.incomesSurrenderPropositions$.value;
          const result = incomesPropositions.filter((item) => item !== value);
          this.model.incomesSurrenderPropositions$.next(result);
        }
      };

      player.sendedSurrenderPropositions.onAdd = (value) => {
        if (this.room.sessionId === key && value) {
          this.model.sendedSurrenderPropositions$.next([
            ...this.model.sendedSurrenderPropositions$.value,
            value
          ]);
        }
      };

      player.sendedSurrenderPropositions.onRemove = (value) => {
        if (this.room.sessionId === key && value) {
          const sendedPropositions = this.model.sendedSurrenderPropositions$.value;
          const result = sendedPropositions.filter(item => item !== value);
          this.model.sendedSurrenderPropositions$.next(result);
        }
      };

      this.isPlayersAdded = true;

      if (this.isSuccessRestoreState === false) {
        this.badRestoreStatus();
      }

      if (!this.isGamestarted) {
        this.gameStart();
      }
    };

    this.room.state.players.onRemove = (player) => {
      this.model.playerLeftGame$.next(this.mutableTeam(player.team));
    };
  }

  getMutableTeamPosition(team, opponentsBeforePlayer, numberOfPlayers) {
    if (numberOfPlayers === 2) {
      if (
        (team === PlayersPositions.BL && opponentsBeforePlayer > 0) ||
        (team === PlayersPositions.TR && opponentsBeforePlayer === 0)
      ) {
        return PlayersPositions.TR;
      }

      return PlayersPositions.BL;
    }

    const config = [
      {
        [PlayersPositions.BL]: PlayersPositions.BL,
        [PlayersPositions.TL]: PlayersPositions.TL,
        [PlayersPositions.TR]: PlayersPositions.TR,
        [PlayersPositions.BR]: PlayersPositions.BR,
      },
      {
        [PlayersPositions.BL]: PlayersPositions.BR,
        [PlayersPositions.TL]: PlayersPositions.BL,
        [PlayersPositions.TR]: PlayersPositions.TL,
        [PlayersPositions.BR]: PlayersPositions.TR,
      },
      {
        [PlayersPositions.BL]: PlayersPositions.TR,
        [PlayersPositions.TL]: PlayersPositions.BR,
        [PlayersPositions.TR]: PlayersPositions.BL,
        [PlayersPositions.BR]: PlayersPositions.TL,
      },
      {
        [PlayersPositions.BL]: PlayersPositions.TL,
        [PlayersPositions.TL]: PlayersPositions.TR,
        [PlayersPositions.TR]: PlayersPositions.BR,
        [PlayersPositions.BR]: PlayersPositions.BL,
      },
    ];

    return config[opponentsBeforePlayer][team];
  }

  getMutableTeamColor(updatedTeam, numberOfPlayers) {
    if (PlayersPositions.BL === updatedTeam) {
      return this.model.gdh.selectedColor;
    }

    if (numberOfPlayers === 2) {
      const colors = {
        blue: "green",
        red: "yellow",
        green: "blue",
        yellow: "red",
      };
      return colors[this.model.gdh.selectedColor];
    }

    const colors = {
      blue: {
        [PlayersPositions.TL]: "red",
        [PlayersPositions.TR]: "green",
        [PlayersPositions.BR]: "yellow",
      },
      red: {
        [PlayersPositions.TL]: "green",
        [PlayersPositions.TR]: "yellow",
        [PlayersPositions.BR]: "blue",
      },
      green: {
        [PlayersPositions.TL]: "yellow",
        [PlayersPositions.TR]: "blue",
        [PlayersPositions.BR]: "red",
      },
      yellow: {
        [PlayersPositions.TL]: "blue",
        [PlayersPositions.TR]: "red",
        [PlayersPositions.BR]: "green",
      },
    };

    return colors[this.model.gdh.selectedColor][updatedTeam];
  }

  syncRoomData() {
    if (this.room.state.orderPlayersDone === false) {
      return;
    }

    this.model.activePlayerTeam$.next(
      this.mutableTeam(
        this.room.state.players.get(this.room.state.activePlayer).team
      )
    );
    this.model.playerActivate$.next(this.room.state.activePlayer);

    const players = Array.from(this.room.state.players.entries()).map(
      ([playerId, player]) => ({
        availableTokensToSelect: player.availableTokensToSelect.toArray(),
        team: this.mutableTeam(player.team),
        diceAnimate: player.diceAnimate,
        diceValue: player.diceValue,
        finishBlocked: player.finishBlocked,
        tokens: Array.from(player.tokens.entries()).map(([_, token]) => ({
          prevPosition: token.position,
          position: token.position,
          killed: token.killed,
        })),
      })
    );

    for (let i = 0; i < players.length; i++) {
      const player = players[i];

      this.model.availableTokens$[this.mutableTeam(player.team)].next(
        player.availableTokensToSelect
      );
      this.model.dices$[this.mutableTeam(player.team)].next({
        diceValue: player.diceValue,
        diceAnimate: player.diceAnimate,
      });
      this.model.blocksVisible$[this.mutableTeam(player.team)].next(
        player.finishBlocked
      );

      for (let y = 0; y < player.tokens.length; y++) {
        this.model.tokens$[this.mutableTeam(player.team)][y].next(
          player.tokens[y]
        );
      }
    }
  }

  onRemoveStateListeners() {
    for (let i = 0; i < this.stateListeners.length; i++) {
      this.stateListeners[i]();
    }

    this.stateListeners = [];
  }

  onMessages() {
    // Reciving the broadcast message sent from server (for all clients)
    this.events.onMessage(Constants.Server.GAME_START, (info) => {
      if (this.isPlayersAdded) {
        this.gameStart();
      }

      if (this.model.gdh.urlParams.get('debug') && info && info.roundId) {
        console.log("RoundId = " + info.roundId);
        console.log("GAME_START balance = ", info.balance);
      }

      this.model.gdh.balance = info.balance;
      this.model.serverUpdateBalance$.next({
        playerId: this.room.sessionId,
        balance: info.balance,
      });
    });

    this.events.onMessage("PLAYER_ROOM_POSITION", (position) => {
      console.log(">>>PLAYER_ROOM_POSITION", position);
    });

    this.events.onMessage("GAME_ENDED", (messageData) => {
      this.model.gdh.isAutomaticRollActive = false;
      if (this.model.mode$.value === "single") {
        this.model.serverGameEnded$.next(messageData);
        return;
      }

      if (messageData.winnerPlayerId === this.room.sessionId) {
        this.model.serverGameEnded$.next(messageData);
        return;
      }

      this.model.serverGameEnded$.next({});
    });

    // Reciving the broadcast message sent from server (for all clients)
    this.events.onMessage(
      "ENABLE_ROLL_BUTTON",
      ({ player: activePlayerId }) => {
        this.model.enableCashoutButton$.next({enabled: !this.model.gdh.isAutomaticRollActive});
        this.model.enableRollButton$.next(
          this.room.sessionId === activePlayerId
        );
      }
    );

    this.events.onMessage("AVATAR_SAVED", ({ avatarIndex, sessionId }) => {
      if (sessionId !== this.room.sessionId) {
        return;
      }
      this.model.gdh.avatarIndex = avatarIndex;
      this.model.avatarSaved$.next(avatarIndex);
    });

    this.events.onMessage("NOT_VALID_CURRENCY", (/*obj*/) => {
      //console.log("NOT_VALID_CURRENCY", obj);
      this.model.sessionLost$.next(false);
      this.model.currecyNotSupportedModal$.next(true);

      this.destroyRoom();

      this._canRejoin = true;
    });

    this.events.onMessage("USERNAME_SAVED", ({ username, sessionId }) => {
      if (sessionId !== this.room.sessionId) {
        return;
      }
      this.model.gdh.username = username;
      this.model.usernameSaved$.next(username);
    });

    this.events.onMessage("WAS_RECONNECT", () => {
      this.model.reconnecting$.next(false);
      this.syncRoomData();
    });

    this.events.onMessage("SERVER_ERROR", (message) => {
      console.error(message);
    });

    this.events.onMessage("SERVER_CRIT_ERROR", (message) => {
      this.model.sessionLost$.next(false);
      this.model.criticalModal$.next(true);

      this.destroyRoom();

      this._canRejoin = true;
      console.error("Server critical error!", message);
    });

    this.events.onMessage("SERVER_BALANCE_ERROR", (message) => {
      this.model.sessionLost$.next(false);
      this.model.balanceErrorModal$.next(true);

      this.destroyRoom();

      this._canRejoin = true;
      console.error("Server critical error!", message);
    });

    this.events.onMessage("NOT_ENOUGH_MONEY", (messages) => {
      this.model.notEnoughMoney$.next(!!messages);
    });

    this.events.onMessage("CASHOUT_PLAYER", (messages) => {
      this.model.cashout$.next({ value: messages.cashout });
      if (!this.model.cashoutModal$.value) {
        this.model.gdh.cashout = messages.cashout;
      }
    });

    /*this.events.onMessage("LOADING_CASHOUT", () => {
      console.log('LOADING_CASHOUT');
      //this.model.enableCashoutButton$.next({enabled:false, removeValue: true});
    });*/

    /*this.events.onMessage("SEND_USER_PREFERENCES", (data) => {
      this.model.gdh.username = data.username;
      this.model.gdh.usernameHidden = data.hide_username;
      this.model.gdh.avatarIndex = data.avatar;
      this.model.gdh.sound = data.sound;
      this.model.gdh.music = data.music;

      this.model.playerPreferences$.next(data);
    });*/

    this.events.onMessage("SEND_GAME_SETTINGS", (data) => {
      this.model.gdh.gameSettings = data;

      this.realizeGameSettings();
    });

    this.events.onMessage("RESTORE_STATE_STATUS", (info) => {
      console.log(">>>RESTORE_STATE_STATUS", info, "isPlayersAdded = ", this.isPlayersAdded, this.isSuccessRestoreState === false);
      this.isSuccessRestoreState = info.isSuccessRestoreState;

      this.model.gdh.balance = info.balance;
      this.model.serverUpdateBalance$.next({
        playerId: this.room.sessionId,
        balance: info.balance,
      });

      if (this.isSuccessRestoreState === false && this.isPlayersAdded) {
        this.badRestoreStatus();
      }
    });

    this.events.onMessage("DISCONNECT_CLIENT", (info) => {
      console.log(">>>DISCONNECT_CLIENT", info);

      this.model.serverUpdateBalance$.next({
        playerId: this.room.sessionId,
        balance: info.balance,
      });

      this.destroyRoom();

      if (info.showReconnectPopup) {
        this.model.sessionLost$.next(true);
      }
    });

    this.events.onMessage("TIMER_ACTIVE", (message) => {
      this.model.gdh.gameTimerValue = message.timerValue;
    });
  }

  gameStart() {
    this.isGamestarted = true;

    const players = this.room.state.players;
    const player = players.get(this.room.sessionId);
    this.model.gdh.username = player.name;
    this.model.usernameSaved$.next(this.model.gdh.username);
    this.model.gdh.balance = player.balance;
    this.model.gdh.possibleWin = this.room.state.possibleWin;
    this.model.gdh.isCashoutBlockUser = player.isCashoutBlockUser;
    this.model.activeMatchMaking$.next(false);
    this.model.serverGameStarted$.next(true);
    this.model.gdh.gameTimerValue = this.room.state.timerValue;
  }

  updateGameSettings() {
    this.events.send(Constants.Client.UPDATE_GAME_SETTINGS, {
      operatorId: this.model.gdh.urlParams.get('operator_id'),
      gameCode: this.model.gdh.urlParams.get('game_code')
    });
  }

  leaveGame(isCashout = false) {
    const user = this.model.gdh.urlParams.get("user");
    const token = this.model.gdh.urlParams.get("token");
    const gameCode = this.model.gdh.urlParams.get("game_code");
    const typeLeave = isCashout ? 'CASHOUT' : 'SURRENDER';

    const options = {
      user,
      token,
      gameCode,
      typeLeave,
    };

    this.events.send(Constants.Client.LEAVE_GAME, options);
  }

  async startPlay(isRestore) {
    if (this.model.gdh.urlParams.get('debug')) {
      console.log("Start Play, restore = " + isRestore);
    }

    await this.join();

    this.mutableTeam = (team) => team;
    this.model.serverGameStarted$.next(false);
    const numberOfPlayers = this.model.gdh.numberOfPlayers;
    const betAmount = this.model.gdh.currentBet;
    const selectedColor = this.model.gdh.selectedColor;
    window.data.stake = betAmount;

    if (isRestore) {
      this.events.send("RESTORE_STATE", {
        roundId: this.round
      });
    } else {
      this.events.send("START_PLAY", {
        ...this.getUrlParams(),
        betAmount,
        numberOfPlayers,
        selectedColor,
        mode: this.model.gdh.mode,
        username: this.model.gdh.username,
        usernameHidden: this.model.gdh.usernameHidden,
        gameSettings: this.model.gdh.gameSettings,
      });
    }

  }

  saveUsername(username) {
    this.model.gdh.username = username;
    this.events.send("SAVE_USERNAME", { username });

    this.updatePreserenses$.next(undefined);
  }

  saveAvatar(avatarIndex) {
    this.model.gdh.avatarIndex = avatarIndex;
    this.events.send("SAVE_AVATAR", { avatarIndex });

    this.updatePreserenses$.next(undefined);
  }

  hideUsername(isHidden = false) {
    this.events.send("HIDE_USERNAME", { isHidden });

    this.updatePreserenses$.next(undefined);
  }

  playerSelectedToken(tokenIndex) {
    this.selectedToken = tokenIndex;
    //this.model.enableCashoutButton$.next({enabled:false, removeValue: true});
    this.model.timerPlayerMulti$.next({
      ...this.model.timerPlayerMulti$.value,
      available: false,
      isTimerVisible: false,
    });
    this.events.send("SERVER_PLAYER_SELECTING_TOKEN", tokenIndex);
  }

  rollTheDice() {
    if (this.currentTeam !== "bottom-left") this.model.enableCashoutButton$.next({enabled:false});
    this.model.timerPlayerMulti$.next({
      ...this.model.timerPlayerMulti$.value,
      available: false,
      isTimerVisible: true
    });

    this.events.send(Constants.Client.DICE_ROLL);
  }

  sendSyncToServer() {
    this.events.send(Constants.Client.__SYNC__);
  }

  async onUpdatePreferences() {
    await ConnectionService._tryToSendPatchRequest(this.model,
      this.model.gdh.urlParams.get("user"),
      {
        username: this.model.gdh.username,
        hideUsername: this.model.gdh.usernameHidden,
        avatar: this.model.gdh.avatarIndex,
        sound: this.model.gdh.sound,
        music: this.model.gdh.music,
      }
    );

    /*this.events.send("UPDATE_USER_PREFERENCES", {
      supplier_user: this.model.gdh.urlParams.get("user"),
      username: this.model.gdh.username,
      hide_username: this.model.gdh.usernameHidden,
      avatar: this.model.gdh.avatarIndex,
      sound: this.model.gdh.sound,
      music: this.model.gdh.music,
    });*/
  }

  sendOfferToGiveUp(opponents) {
    this.events.send('CLIENT_SURRENDER_OFFER', { opponents });
  }

  sendSurrenderAnswer(answer) {
    this.events.send('CLIENT_SURRENDER_ANSWER', answer);
  }

  autoPlayEnabled(value) {
    if (this.isAutoplay === value) return;

    this.isAutoplay = value;
    this.events.send('AUTOPLAY', value);
  }

  /**
   * @param list {{field: string, value: number | string}[]}
   * @private
   */
  _mergeUpdatesState(list) {
    return list.reduce(
      (obj, el) => ({
        ...obj,
        [el.field]: el.value,
      }),
      {}
    );
  }

  getUrlParams() {
    const restParams = {};
    for (const [key, value] of this.model.gdh.urlParams.entries()) {
      const arr = key.split('_');
      for (let i = 1; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      const newKey = arr.join('');
      restParams[newKey] = value;
    }
    return restParams;
  }

  sendModalCashoutAct(val) {
    const name = val ? "SHOW_MODAL_CASHOUT" : "CLOSE_MODAL_CASHOUT"
    this.events.send(name, {  });
  }

  restoreRound(room, round) {
    this.roomParameters = room;
    this.round = round;
    this.model.gdh.numberOfPlayers = this.roomParameters.maxClients;
  }

  clearRound() {
    this.roomParameters = null;
  }

  badRestoreStatus() {
    this.model.unrestoringRoundModal$.next(true);
  }
}
