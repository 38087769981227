import { Assets } from 'pixi.js';
import {BehaviorSubject} from "rxjs";

export class GameLoader {
    constructor() {
        this.progress$ = new BehaviorSubject(0);
    }

    async initManifest() {
        await Assets.init({ manifest: "../assets/manifest.json" });
    }

    async loadPreloadResources() {
        this.progress$.next(0);
        return await Assets.loadBundle('preloader', (progress) => this.progress$.next(progress));
    }

    async loadMainResources() {
        this.progress$.next(0);
        return await Assets.loadBundle('main', (progress) => this.progress$.next(progress));
    }
}
