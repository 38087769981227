import * as PIXI from "pixi.js";
import ConnectionService from "../services/ConnectionService";

export class MainBgScene extends PIXI.Container {
    /**
     * @param resizeManager {ResizeManager}
     * @param server {Server}
     * @param model {Model}
     */
    constructor(server, model, resizeManager) {
        super();

        this.canvasWidth = 1080;
        this.canvasHeight = 1920;
        this.server = server;
        this.model = model;
        this.resizeManager = resizeManager;

        this.addBg();
        this.addLogo();
        this.addMonitoring();
    }

    addMonitoring() {
      this.eventMode = 'none';
      this.on('pointertap', this.onClick, this);

      this.model.monitoringPlayerTokenMove$
        .subscribe((value) => {
          this.eventMode = value ? 'static' : 'none';
      });
    }

    onClick(event) {
      if (!this.server.clientIdGA) return;

      if (this.model.gdh.urlParams.get('debug')) {
        console.log(event.global.x, event.global.y, this.eventMode);
      }
      ConnectionService._tryToSendAction({
        action: "click",
        clientId : this.server.clientIdGA,
        data: {
          mouseX: event.global.x,
          mouseY: event.global.y,
        }
      });
    }

    addBg() {
        const mainBg = this.addChild(PIXI.Sprite.from('mainBg'));
        this.originalWidth = mainBg.width;
        this.originalHight = mainBg.height;

        mainBg.scale.set(this.canvasWidth / this.originalWidth, this.canvasHeight / this.originalHight);

        this.resizeManager.resize$
            .subscribe(({ scale, shiftY }) => {
                mainBg.y = -(shiftY / scale);

                const dW = Math.round(this.canvasWidth / scale);
                const dH = Math.round(this.canvasHeight / scale);

                const scaleX = dW / this.originalWidth;
                const scaleY = dH / this.originalHight;

                mainBg.scale.set(scaleX, scaleY);
            });
    }

    addLogo() {
        const logo = this.addChild(PIXI.Sprite.from('topLogo'));
        logo.pivot.set(logo.width / 2, logo.height / 2);
        this.resizeManager.resize$.next(this.resizeManager.resize$.value);

        logo.position.set(this.canvasWidth / 2, 150);

        this.resizeManager.resize$
            .subscribe(({ scale, shiftY }) => {
              logo.y = -((shiftY / scale) / 2) + 100;
              if (scale < 0.71) {
                logo.scale.set(scale * shiftY / 600);
              } else if (scale < 1) {
                logo.scale.set((1 - scale) + 0.4);
              } else {
                logo.scale.set(0.4);
              }
            });

      this.model.showLogo$
        .subscribe(value => {
          logo.visible = Boolean(value);
        });
    }
}
