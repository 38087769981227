import * as PIXI from "pixi.js";
import {BehaviorSubject} from "rxjs";
import {MenuSettings} from "../menus/MenuSettings";
import {YourPreferences} from "../menus/YourPreferences";
import {MenuRules} from "../menus/MenuRules";
import {EnumLayouts} from "../helpers/constants/EnumLayouts";
import {distinctUntilChanged} from "rxjs/operators";

const Screen = {
    SOUND: 'SOUND',
    RULES: 'RULES',
    YOUR_PREFERENCES: 'YOUR_PREFERENCES'
}

export class MenuScene extends PIXI.Container {
    /**
     * @param sceneManager {SceneManager}
     * @param model {Model}
     * @param server {Server}
     * @param soundManager {SoundManager}
     * @param resizeManager {ResizeManager}
     */
    constructor(sceneManager, model, server, soundManager, resizeManager) {
        super();

        this.originalWidth = 1080;
        this.originalHeight = 1920;

        this.sceneManager = sceneManager;
        this.model = model;
        this.soundManager = soundManager;
        this.resizeManager = resizeManager;

        this.currentTab$ = new BehaviorSubject(Screen.RULES);

        this.addBg();
        this.addBottomBar();
        this.addDecoCutters();

        this.model.openMenu$
            .subscribe(type => {
                this.model.automoveModal$.next(false);
                if (type) {
                    this.currentTab$.next(type);
                }

                if (this.model.gameIsStarted$.getValue() === true && this.model.activeMatchMaking$.value === false) {
                    this.sceneManager.hide(EnumLayouts.GAME);
                    this.sceneManager.hide(EnumLayouts.UI);
                } else if (this.model.gameIsStarted$.getValue() === true && this.model.activeMatchMaking$.value === true) {
                    this.sceneManager.hide(EnumLayouts.LOBBY_BG);
                    this.sceneManager.hide(EnumLayouts.MATCH_MAKING);
                } else {
                    this.sceneManager.hide(EnumLayouts.LOBBY_BG);
                    this.sceneManager.show(EnumLayouts.MAIN_BG);
                    this.sceneManager.hide(EnumLayouts.START);
                    this.sceneManager.hide(EnumLayouts.START_MODE);
                }

                this.sceneManager.show(EnumLayouts.MENU);
                this.model.showLogo$.next(false);
                this.model.closeMenu$.next(undefined);
            });

        this.model.closeMenu$
            .subscribe(value => {
                if (!value) return;

                this.currentTab$.next(Screen.SOUND);

                if (this.model.gameIsStarted$.getValue() === true && this.model.activeMatchMaking$.value === false) {
                    this.sceneManager.show(EnumLayouts.GAME);
                    this.sceneManager.show(EnumLayouts.UI);
                } else if (this.model.gameIsStarted$.getValue() === true && this.model.activeMatchMaking$.value === true) {
                    this.sceneManager.show(EnumLayouts.LOBBY_BG);
                    this.sceneManager.show(EnumLayouts.MATCH_MAKING);
                } else {
                    this.sceneManager.show(EnumLayouts.LOBBY_BG);
                    this.sceneManager.hide(EnumLayouts.MAIN_BG);
                    this.sceneManager.show(EnumLayouts.START);
                    this.sceneManager.show(EnumLayouts.START_MODE);
                }

                this.sceneManager.hide(EnumLayouts.MENU);
                this.model.showLogo$.next(true);
            });

        const rulesPage = this.addChild(new MenuRules(model, server, soundManager));
        const settingsPage = this.addChild(new MenuSettings(model, server, soundManager));
        const yourPreferencesPage = this.addChild(new YourPreferences(model, server, soundManager, resizeManager));

        const openTab = (page) => {
            page.visible = true;
            if (typeof page.onOpen === 'function') {
                page.onOpen();
            }
        };

        const closeTab = (page) => {
            page.visible = false;
            if (typeof page.onClose === 'function') {
                page.onClose();
            }
        };

        this.currentTab$
            .pipe(distinctUntilChanged())
            .subscribe(tab => {
                switch (tab) {
                    case Screen.SOUND:
                        openTab(settingsPage);
                        closeTab(rulesPage);
                        closeTab(yourPreferencesPage);
                        break;

                    case Screen.YOUR_PREFERENCES:
                        openTab(yourPreferencesPage);
                        closeTab(settingsPage);
                        closeTab(rulesPage);
                        break;

                    case Screen.RULES:
                    default:
                        openTab(rulesPage);
                        closeTab(settingsPage);
                        closeTab(yourPreferencesPage);
                        break;
                }
            })
    }

    addBg() {
        const bg = new PIXI.Graphics();
        bg.beginFill(0x000000, 0.5);
        bg.drawRect(0,0, this.originalWidth, this.originalHeight);
        bg.endFill();
        this.addChild(bg);

        this.resizeManager.resize$
            .subscribe(({ shiftY, scale }) => {
                bg.y = -(shiftY / scale);
                bg.scale.y = 1 / scale;
            });
    }

    addBottomBar() {
        const canvas = document.createElement('canvas');
        canvas.width  = this.originalWidth;
        canvas.height = 10;
        const ctx = canvas.getContext('2d');
        const gradient = ctx.createLinearGradient(0, 0, this.originalWidth, 10);
        gradient.addColorStop(0, "#392364");
        // gradient.addColorStop(0.3, "#391549");
        // gradient.addColorStop(0.6, "#392364");
        gradient.addColorStop(1, "#391549");
        ctx.fillStyle = gradient;
        ctx.fillStyle = gradient;
        ctx.fillRect(0, 0, this.originalWidth, 10);

        const bottomBg = new PIXI.Graphics();
        bottomBg.beginTextureFill({ texture: PIXI.Texture.from(canvas)})
        bottomBg.drawRect(0, 0, this.originalWidth, 160);
        bottomBg.endFill();

        bottomBg.position.set(0, 1764);

        this.addChild(bottomBg);

        const exitButton = this.addTab(['menuExitButton', 'menuExitButton'], "EXIT", () => {
            this.model.closeMenu$.next(true);
        });
        exitButton.position.set(226, 1846);

        const rulesButton = this.addTab(['rulesButton', 'rulesButtonActive'], Screen.RULES, () => {
            this.currentTab$.next(Screen.RULES);
        });
        rulesButton.position.set(445, 1845);

        const soundButton = this.addTab(['settingsButton', 'settingsButtonActive'], Screen.SOUND, () => {
            this.currentTab$.next(Screen.SOUND);
        });
        soundButton.position.set(659, 1846);

        const yourPrefButton = this.addTab(['yourPrefButton', 'yourPrefButtonActive'], Screen.YOUR_PREFERENCES, () => {
            this.currentTab$.next(Screen.YOUR_PREFERENCES);
        });
        yourPrefButton.position.set(865, 1846);
    }

    addDecoCutters() {
        const topLine = this.addChild(PIXI.Sprite.from('decoCutter'));
        topLine.scale.y = -1;
        topLine.position.set(152, 266);

        const bottomLine = this.addChild(PIXI.Sprite.from('decoCutter'));
        bottomLine.position.set(154, 1655);
    }

    addTab(textures = [], tabName = Screen.RULES, clickFn = () => {}) {
        const button = this.addChild(new PIXI.AnimatedSprite(textures.map(e => PIXI.Texture.from(e))));
        button.pivot.set(button.width / 2, button.height / 2);
        button.gotoAndStop(0);

        button.hitArea = new PIXI.Rectangle(-20, -20, button.width + 40, button.height + 40);
        button.eventMode = 'static';
        button.buttonMode = true;

        button.on('pointerup', () => {
            button.gotoAndStop(1);
            clickFn();
        });
        button.on('pointerdown', () => {
            this.soundManager.playSound('click');
        });

        this.currentTab$
            .subscribe(e => {
                button.gotoAndStop(e === tabName ? 1 : 0);
            });

        return button;
    }
}
