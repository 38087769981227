/**
 *
 * @param { String } name - name of the parameter which you want to get
 * @param { String } [url] - url from which you want to get the query param
 *
 * @returns { String } - If there is parameter in the url, it returns value (if empty sends the ''). If there is not, it sends the null.
 */
export const getParameterByName = (name, url = window.location.href) => {
    const paramName = name.replace(/[[\]]/g, "\\$&");
    const regex = new RegExp(`[?&]${paramName}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
};

export const formatCurrency = (value) => {
    return value.toLocaleString('en-US', {
        style: 'currency',
        currency: window.data.currency,
        maximumSignificantDigits: 4
    });
}

export const getRandomValueBetween = (min = 1, max = 6) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
};

export const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
};

export const round = (value, multiplier) => {
    return Math.round(value * multiplier) / multiplier;
}

export const hasUnknownValue = (roundedBet) => {
    if (typeof roundedBet !== 'number') return true;
    const roundedBetString = roundedBet.toString();
    const arr = roundedBetString.split('.');
    if (arr.length === 2) {
        const decimal = arr[1];
        if (decimal % 2 !== 0) {
            return true;
        }
    }
    return false;
}

export const validURL = (str) => {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}


export default {};
