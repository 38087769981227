import * as PIXI from 'pixi.js';
import i18next from 'i18next';
import {BehaviorSubject, distinctUntilChanged} from "rxjs";
import {EnumTopType, TopContainer} from "../objects/TopContainer";
import {SpriteButton} from "../objects/SpriteButton";
import {onButtonPressedAnimation} from "../helpers/buttonHelper";

export class StartModeScene extends PIXI.Container {
    /**
     * @param server {Server}
     * @param model {Model}
     * @param soundManager {SoundManager}
     */
    constructor(server, model, soundManager) {
        super();

        this.server = server;
        this.model = model;
        this.soundManager = soundManager;

        this.topContainer = this.addChild(new TopContainer(EnumTopType.MODE, model, soundManager, server));
        this.topContainer.position.set(0, 160);
        this.buttons = [];

        // y = 853 -> for 4 mode btns
        const multiTypeBtn = this.addChild(
            this.addModeBtn(
                176,
                1000,
                "online",
                this.model.gdh.gameSettings.types.includes('online'),
                () => this.model.mode$.next("multi")
            )
        );
        const singleTypeBtn = this.addChild(
            this.addModeBtn(
                591,
                1000,
                'single',
                this.model.gdh.gameSettings.types.includes('computer'),
                () => this.model.mode$.next('single')
            )
        );

        for (let i = 0; i < this.buttons.length; i++) {
            this.buttons[i].x = 1.15 * this.width * (i + 1) / (this.buttons.length + 1) - 75;
        }
        // this.addChild(this.addModeBtn(176, 1279, 'friends', false));
        // this.addChild(this.addModeBtn(591, 1279, 'p2p', false));

        this.createHomeBtn();
        this.createMenuBtn();

        this.model.gameTypes$.pipe(distinctUntilChanged()).subscribe((value) => {
            singleTypeBtn.toggleActivity(value.includes("computer"));
            multiTypeBtn.toggleActivity(value.includes("online"));
        });
    }

    addModeBtn(x, y, type, active = true, onClick = () => {}) {
        const container = new PIXI.Container();
        container.position.set(-70, y);

        const defaultBg = container.addChild(PIXI.Sprite.from('mode_btn_default'));
        const activeBg = container.addChild(PIXI.Sprite.from('mode_btn_active'));
        activeBg.position.set(-10, -10);

        container.pivot.set(container.width / 2, 0);

        /**
         * @type {BehaviorSubject<0 | 1>} where 0 - default, 1 - active
         */
        const btnState$ = new BehaviorSubject(0);

        if (active) {
            container.visible = true;
            container.isActive = true;
            container.eventMode = 'static';
            container.buttonMode = true;
            this.buttons.push(container);

            container.on(
                'pointerup',
                () => {
                    btnState$.next(1);
                    onClick();
                    this.model.startScene$.next('start');
                },
                this
            );
            container.on('pointerover', () => btnState$.next(1), this);
            container.on('pointerout', () => btnState$.next(0), this);
            container.on(
                'pointerdown',
                () => {
                    this.soundManager.playSound('click');
                    btnState$.next(1);
                },
                this
            );
        }

        btnState$.subscribe((state) => {
            defaultBg.visible = state === 0;
            activeBg.visible = state === 1;
        });

        const icon = container.addChild(PIXI.Sprite.from(`mode_icon_${type}`));
        icon.pivot.set(icon.width / 2, icon.height / 2);
        icon.position.set(159, 108);

        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        const fontWeight = isSafari ? "normal" : "bold";

        const text = container.addChild(
            new PIXI.Text(i18next.t(`mode_scene.${type}`).toUpperCase(), {
                fontFamily: 'Mybahnschrift',
                fontSize: '38px',
                fontWeight: fontWeight,
                fill: '#FFD809',
                align: 'center',
                stroke: '#2E2F73',
                strokeThickness: 4,
                dropShadow: true,
                dropShadowColor: '#000000',
                dropShadowDistance: 5,
                dropShadowAngle: 0.7,
            })
        );
        text.anchor.set(0.5);
        text.position.set(160, 260);

        if (!active) {
            container.visible = false;
            //const topClosedBg = container.addChild(PIXI.Sprite.from('mode_btn_top_closed'));
            //topClosedBg.position.set(-10, -10);
        }

        container.toggleActivity = (value) => {
            if (value === container.isActive) return;

            if (value && !container.isActive) {
                container.isActive = true;
                container.eventMode = "static";
                container.buttonMode = true;

                container.on(
                    "pointerup",
                    () => {
                        btnState$.next(1);
                        onClick();
                        this.model.startScene$.next("start");
                    },
                    this
                );
                container.on("pointerover", () => btnState$.next(1), this);
                container.on("pointerout", () => btnState$.next(0), this);
                container.on(
                    "pointerdown",
                    () => {
                        this.soundManager.playSound("click");
                        btnState$.next(1);
                    },
                    this
                );
            }

            if (!value && container.isActive) {
                container.isActive = false;
                container.eventMode = "none";
                const topClosedBg = container.addChild(PIXI.Sprite.from("mode_btn_top_closed"));
                topClosedBg.position.set(-10, -10);
            }
        }

        return container;
    }

    createHomeBtn() {
        const lobbyUrl = this.model.gdh.urlParams.get('lobby_url');
        if (!lobbyUrl || lobbyUrl.trim().length === 0) {
            return;
        }

        this.homeButton = this.addChild(
            new SpriteButton({
                default: 'homeButton',
                over: 'homeHoverButton',
                pressed: 'homePressedButton',
            })
        );
        this.homeButton.pivot.set(
            this.homeButton.width / 2,
            this.homeButton.height / 2
        );
        this.homeButton.position.set(119, 1804);

        this.homeButton.click$.subscribe((_) => (window.location.href = lobbyUrl));
        this.homeButton.pressed$.subscribe((_) => {
            this.soundManager.playSound('click');
            onButtonPressedAnimation(this.homeButton);
        });
    }

    createMenuBtn() {
        this.menuButton = this.addChild(
            new SpriteButton({
                default: 'menuButton',
                over: 'menuButtonHover',
                pressed: 'menuButtonPressed',
            })
        );
        this.menuButton.pivot.set(
            this.menuButton.width / 2,
            this.menuButton.height / 2
        );
        this.menuButton.position.set(963, 1804);
        this.menuButton.pressed$.subscribe((_) => {
            this.soundManager.playSound('click');
            onButtonPressedAnimation(this.menuButton);
        });
        this.menuButton.click$.subscribe((_) => {
            this.model.openMenu$.next('RULES');
        });
    }
}
