import * as PIXI from "pixi.js";
import {gsap} from "gsap";
import {Server} from "./Server";
import {GameLoader} from "./GameLoader";
import {SceneManager} from "../scenes/SceneManager";
import {BgScene} from "../scenes/BgScene";
import {PreloaderScene} from "../scenes/PreloaderScene";
import {EnumLayouts} from "../helpers/constants/EnumLayouts";
import {Model} from "./Model";
import {StartScene} from "../scenes/StartScene";
import {SoundManager} from "./SoundManager";
import {FadeLobbyGameScene} from "../scenes/FadeLobbyGameScene";
import {ModalsScene} from "../scenes/ModalsScene";
import {UiScene} from "../scenes/UiScene";
import {MainScene} from "../scenes/MainScene";
import {MainBgScene} from "../scenes/MainBgScene";
import {MenuScene} from "../scenes/MenuScene";
import {ResizeManager} from "./ResizeManager";
import {MatchMaking} from "../scenes/MatchMaking";
import {StartModeScene} from "../scenes/StartModeScene";
import {distinctUntilChanged} from "rxjs/operators";
import "../helpers/hiddenTicker";
import { install } from 'ga-gtag';
import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';
import Hotjar from "@hotjar/browser";

gsap.registerPlugin();
gsap.ticker.lagSmoothing(false);

export class GameManager {
    constructor() {
        this.application = undefined;
        this.model = new Model();
        this.server = new Server(this.model);
        this.loader = new GameLoader();
        this.sceneManager = new SceneManager();
        this.soundManager = new SoundManager();
        this.resizeManager = new ResizeManager();
    }

    async init() {
        this.initAnalytics();
        this.initRender();
        this.initSceneScaling();
        await this.initLoader();
        this.initSceneManager();
        this.sceneManager.show(EnumLayouts.LOBBY_BG);
        this.sceneManager.show(EnumLayouts.LOADER);
        await this.loader.loadMainResources();
        await this.soundManager.init();
        this.initModals();
        await this.initGameServer();
        this.initMainScenes();
        this.sceneManager.hide(EnumLayouts.LOADER);
        this.sceneManager.show(EnumLayouts.START_MODE);
        this.sceneManager.show(EnumLayouts.START);
        this.resizeManager.resize$.next(this.resizeManager.resize$.value);
        this.initStartModeListener();
    }

    initAnalytics() {
        //this.initGA();
        this.initGTM();
        //this.initHotjar();
    }

    initRender() {
        /**
         * @type {PIXI.Application}
         */
        this.application = new PIXI.Application({
            // type: PIXI.WEBGL,
            // view: document.getElementById('game-container')
            backgroundAlpha: 0,
            transparent: true,
            resolution: 1, // window.devicePixelRatio || 1,
            width: 1080,
            height: 1920,
            antialias: true,
            // autoDensity: true,
        });

        gsap.ticker.add(() => this.application.render());

        // for PIXI devtools
        if (process.env.MODE === 'development') globalThis.__PIXI_APP__ = this.application;

        document.getElementById('game-container').appendChild(this.application.view);
    }

    initSceneManager() {
        this.application.stage.addChild(this.sceneManager);

        this.sceneManager.addScene(EnumLayouts.LOBBY_BG, new BgScene(this.sceneManager, this.model, this.resizeManager), false);
        this.sceneManager.addScene(EnumLayouts.LOADER, new PreloaderScene(this.loader), false);
    }

    initModals() {
        this.modalScene = new ModalsScene(this.sceneManager, this.model, this.soundManager, this.resizeManager, this.server);
        this.sceneManager.addScene(EnumLayouts.MODALS, this.modalScene);
    }

    initMainScenes() {
        this.sceneManager.addScene(EnumLayouts.MAIN_BG, new MainBgScene(this.server, this.model, this.resizeManager));
        this.sceneManager.addScene(EnumLayouts.START_MODE, new StartModeScene(this.server, this.model, this.soundManager));
        this.sceneManager.addScene(EnumLayouts.START, new StartScene(this.server, this.model, this.soundManager));
        this.sceneManager.addScene(EnumLayouts.MATCH_MAKING, new MatchMaking(this.model, this.soundManager, this.server));
        this.sceneManager.addScene(EnumLayouts.UI, new UiScene(this.model, this.server, this.soundManager));
        this.sceneManager.addScene(EnumLayouts.GAME, new MainScene(this.server, this.model, this.soundManager));
        this.sceneManager.addScene(EnumLayouts.MENU, new MenuScene(this.sceneManager, this.model, this.server, this.soundManager, this.resizeManager));
        this.sceneManager.addScene(EnumLayouts.FADE_IN_OUT, new FadeLobbyGameScene(this.application, this.sceneManager, this.model, this.resizeManager));
        this.sceneManager.setChildIndex(this.modalScene, this.sceneManager.children.length - 1);
    }

    async initLoader() {
        await this.loader.initManifest();
        await this.loader.loadPreloadResources();
    }

    async initGameServer() {
        this.model.gdh.parseUrlParams();

        await this.server.lobby();
    }

    initSceneScaling() {
        this.resizeManager.resize$
            .subscribe(({ scale, shiftY}) => {
                this.sceneManager.scale.set(scale);
                this.sceneManager.y = shiftY;
            });
    }

    initStartModeListener() {
        const startModePage = this.sceneManager.getContext(EnumLayouts.START_MODE);
        const startPage = this.sceneManager.getContext(EnumLayouts.START);

        this.model.startScene$
            .pipe(distinctUntilChanged())
            .subscribe(mode => {
                if (mode === EnumLayouts.START_MODE) {
                    gsap.to(startModePage, {
                        x: 0,
                        duration: 0.3
                    });
                    gsap.to(startPage, {
                        x: 1080,
                        duration: 0.3
                    });
                } else {
                    gsap.to(startModePage, {
                        x: -1080,
                        duration: 0.3
                    });
                    gsap.to(startPage, {
                        x: 0,
                        duration: 0.3
                    });
                }
            })
    }

    initGA() {
        install(process.env.GA_ID);
    }

    initGTM() {
        const analytics = Analytics({
            app: 'Game ludo',
            plugins: [
                googleTagManager({
                    containerId: process.env.GTM_ID
                })
            ]
        })

        /* Track a page view */
        analytics.page();
    }

    initHotjar() {
        const siteId = process.env.HJ_ID;
        const hotjarVersion = 6;

        Hotjar.init(siteId, hotjarVersion);
    }
}
