import * as PIXI from 'pixi.js';

export class AvatarWithName extends PIXI.Container {
  constructor(model, soundManager) {
    super();

    this.model = model;
    this.soundManager = soundManager;

    this.setup();
    this.addEventListeners();
  }

  setup() {
    this.avatarBg = this.addChild(
      new PIXI.AnimatedSprite([
        PIXI.Texture.from('avatarEmpty'),
        PIXI.Texture.from('avatarRed'),
        PIXI.Texture.from('avatarBlue'),
        PIXI.Texture.from('avatarGreen'),
        PIXI.Texture.from('avatarYellow'),
      ])
    );
    this.avatarBg.gotoAndStop(0);
    this.avatarBg.anchor.set(0.5);
    this.avatarBg.position.set(0, 0);

    this.avatarImg = this.addChild(
      new PIXI.AnimatedSprite([
        PIXI.Texture.from('yourPrefAvatar1'),
        PIXI.Texture.from('yourPrefAvatar2'),
        PIXI.Texture.from('yourPrefAvatar3'),
        PIXI.Texture.from('yourPrefAvatar4'),
        PIXI.Texture.from('yourPrefAvatar5'),
        PIXI.Texture.from('yourPrefAvatar6'),
        PIXI.Texture.from('yourPrefAvatar0'),
      ])
    );
    this.avatarImg.gotoAndStop(6);
    this.avatarImg.anchor.set(0.5);
    this.avatarImg.scale.set(0.94);

    this.nameBg = this.addChild(PIXI.Sprite.from('bgAvatar'));
    this.nameBg.anchor.set(0.5);
    this.nameBg.position.set(0, 90);

    const textStyleUsername = {
      fill: '#FFD809',
      fontFamily: 'Mybahnschrift',
      fontSize: 28,
      stroke: '#2E2F73',
      strokeThickness: 4,
      lineJoin: 'round',
      dropShadow: true,
      dropShadowColor: '#000000',
      dropShadowDistance: 2,
    };
    const username = this.model.gdh.getFormattedName();
    this.username = this.addChild(new PIXI.Text(username, textStyleUsername));
    this.username.anchor.set(0.5);
    this.username.position.set(0, 90);
  }

  pointerUp() {
    this.model.openMenu$.next('YOUR_PREFERENCES');
  }

  pointerDown() {
    this.soundManager.playSound('click');
  }

  addEventListeners() {
    this.eventMode = 'static';

    this.on('pointerdown', this.pointerDown);
    this.on('pointerup', this.pointerUp);
  }

  removeEventListeners() {
    this.off('pointerup', this.pointerUp);
    this.off('pointerdown', this.pointerDown);
  }

  setColor(color) {
    if (color === 'red') return this.avatarBg.gotoAndStop(1);
    if (color === 'blue') return this.avatarBg.gotoAndStop(2);
    if (color === 'green') return this.avatarBg.gotoAndStop(3);
    if (color === 'yellow') return this.avatarBg.gotoAndStop(4);
    this.avatarBg.gotoAndStop(0);
  }

  setUsername(name) {
    this.username.text = name;
  }

  setAvatar(id) {
    if (typeof id === 'number') {
      this.avatarImg.gotoAndStop(id);
    }
  }
}
