import * as PIXI from "pixi.js";
import i18next from 'i18next';
import {BaseModal} from "./BaseModal";

export class NotEnoughMoneyModal extends BaseModal {
    /**
     * @param rootContainer {SceneManager}
     * @param model {Model}
     * @param resizeManager {ResizeManager}
     */
    constructor(rootContainer, model, resizeManager, soundManager) {
        super(rootContainer, model, resizeManager, soundManager);
    }

    createModal() {
        const container = new PIXI.Container();
        const bg = container.addChild(PIXI.Sprite.from('notEnoughMoneyModal'));
        bg.pivot.set(bg.width / 2, bg.height / 2);
        bg.position.set(541, 857);

        const text = container.addChild(new PIXI.Text(i18next.t('modals.not_enough_money'), {
            fontFamily: "Akshar-Medium",
            fontSize: "64px",
            fill: "#ffdb15",
            align: "center",
            stroke: "#490000",
            strokeThickness: 10,
            wordWrapWidth: 900,
            wordWrap: true,
        }));
        text.anchor.set(0.5);
        text.position.set(545, 1059);

        this.btn = this.createBtn(container, 'modals.deposit', () => this.onExit());
        this.btn.position.set(this.width / 2, 1206);

        this.addAnimationItems([bg, text, this.btn]);

        return container;
    }

    async onExit() {
        this.btn.disabled = true;

        await this.hide();
        this.model.clear();
        this.model.leaveGameAfterCrush$.next(undefined);

        // set in url deposit url
        const depositUrl = this.model.gdh.urlParams.get('deposit_url') || process.env.DEPOSIT_GAME_URL;
        if (depositUrl) {
            window.location.href = depositUrl;
        }
    }

    show() {
        super.show();
        this.btn.disabled = false;
    }
}
