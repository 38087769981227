import * as PIXI from 'pixi.js';
import i18next from 'i18next';
import { BaseModal } from './BaseModal';
import {capitalizeFirstLetter} from '../helpers/Utils';
import {getColorByName} from '../helpers/colorsHelper';

export class GetOfferToGiveUpModal extends BaseModal {
  constructor(rootContainer, model, resizeManager, soundManager, server) {
    super(rootContainer, model, resizeManager, soundManager);

    this.model = model;
    this.server = server;
  }

  createModal() {
    this.container = new PIXI.Container();

    // add modal bg
    const bg = this.container.addChild(
      new PIXI.NineSlicePlane(
        PIXI.Texture.from('surrenderModalBg'),
        100,
        100,
        100,
        100
      )
    );
    bg.height = 670;
    bg.position.set((this.originWidth - bg.width) / 2, 710);

    // add modal text
    this.message_1 = this.container.addChild(
      new PIXI.Text('The player', {
        fontFamily: 'Akshar-Regular',
        fontSize: '70px',
        fill: 0xffffff,
        align: 'center',
        wordWrap: true,
        wordWrapWidth: 420,
        lineHeight: 110,
      })
    );
    this.message_1.position.set(0, 0);

    this.senderName = this.container.addChild(
      new PIXI.Text("Green", {
        fontFamily: "Akshar-Regular",
        fontSize: "70px",
        fill: 0xffffff,
        align: "center",
        wordWrap: true,
        wordWrapWidth: 420,
        lineHeight: 110,
      })
    );
    this.senderName.position.set(this.message_1.width + 25, 0);

    this.topMessageContainer = new PIXI.Container();
    this.topMessageContainer.addChild(this.message_1, this.senderName);
    this.container.addChild(this.topMessageContainer);

    this.message_2 = this.container.addChild(
      new PIXI.Text("ask you to surrender", {
        fontFamily: "Akshar-Regular",
        fontSize: "70px",
        fill: 0xffffff,
        align: "center",
        wordWrap: true,
        wordWrapWidth: 420,
        lineHeight: 110,
      })
    );
    this.message_2.anchor.set(0.5);
    this.message_2.position.set(this.width / 2, 1000);

    // add SURRENDER button
    this.surrenderBtn = this.createBtn(
      this.container,
      i18next.t('modals.surrender'),
      () => this.onSurrender(),
      '50px'
    );

    // add DECLINE button
    this.declineBtn = this.createBtn(
      this.container,
      i18next.t('modals.decline'),
      () => {
        this.onDecline();
      },
      '50px'
    );

    this.surrenderBtn.position.set(320, bg.y + bg.height - 150);
    this.declineBtn.position.set(740, bg.y + bg.height - 150);

    this.addAnimationItems([bg, this.message_1, this.senderName, this.message_2, this.surrenderBtn, this.declineBtn]);

    return this.container;
  }

  async show() {
    this.senderId = this.model.incomesSurrenderPropositions$.value[0];
    const opponents = await this.server.getRoomPlayers();

    const sender = opponents.find(item => item.sessionId === this.senderId);
    this.senderName.text = capitalizeFirstLetter(sender.color);
    this.senderName.style.fill = getColorByName(sender.color);

    this.topMessageContainer.position.set(this.width / 2 - this.topMessageContainer.width / 2, 780);

    super.show();

    this.declineBtn.disabled = false;
    this.surrenderBtn.disabled = false;
  }

  async hide() {
    await super.hide();
  }

  async onSurrender() {
    this.surrenderBtn.disabled = true;
    this.sendSurrenderAnswer(true);
    await this.hide();
    this.model.getOfferToGiveUpModal$.next(false);
    this.model.serverGameEnded$.next({});
  }

  async onDecline() {
    this.declineBtn.disabled = true;
    this.sendSurrenderAnswer(false);
    await super.hide();
    this.model.getOfferToGiveUpModal$.next(false);
  }

  sendSurrenderAnswer(value) {
    this.server.sendSurrenderAnswer({
      opponentId: this.senderId,
      answer: value,
    })
  }
}
