import * as PIXI from "pixi.js";
import {SpriteButton} from "./SpriteButton";
import {Subject} from "rxjs";

export const EnumToggleState = {
    ON: 0,
    OFF: 1,
}

export class SpriteButtonSwitcher extends PIXI.Sprite {
    constructor(texturesOn = {}, texturesOff = {}, soundManager) {
        super();
        this.soundManager = soundManager;
        this.buttonOn = this.addChild(new SpriteButton(texturesOn));
        this.buttonOn.anchor = 0.5;
        this.buttonOff = this.addChild(new SpriteButton(texturesOff));
        this.buttonOff.anchor = 0.5;
        this.buttonOff.visible = false;

        this._disabled = false;
        this.toggleState = EnumToggleState.ON;
        this.eventMode = 'static';
        this.buttonMode = true;

        this.on('pointerdown', this.onDown, this);
        this.pressed$ = new Subject(true);
    }


    get toggleState() {
        return this._toggleState;
    }

    /**
     * @param value {number}
     */
    set toggleState(value) {
        this._toggleState = value;
        this.buttonOn.visible = value === EnumToggleState.ON;
        this.buttonOff.visible = value === EnumToggleState.OFF;
    }

    /**
     * @return {boolean}
     */
    get disabled() {
        return this._disabled;
    }

    /**
     * @param disabled {boolean}
     */
    set disabled(disabled) {
        this._disabled = disabled;
        this.buttonMode = !disabled;
        this.eventMode = disabled ? 'none' : 'static';
        this.toggleState = EnumToggleState.ON;
        this.alpha = disabled ? 0.7 : 1;
    }

    onDown(event) {
        this.soundManager.playSound('click');
        this.toggleState = this.toggleState === EnumToggleState.ON ? EnumToggleState.OFF : EnumToggleState.ON;
        this.pressed$.next(this.toggleState === EnumToggleState.ON);
    }

    destroy(options) {
        this.off('pointerdown', this.onDown, this);
        super.destroy(options);
        this.removeAllListeners();
    }
}
