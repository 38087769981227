
import {onButtonPressedAnimation} from "../helpers/buttonHelper";
import {SpriteButton} from "./SpriteButton";

export class MenuButton extends SpriteButton {
  constructor(model, soundManager) {
    super({
      default: 'menuButton',
      over: 'menuButtonHover',
      pressed: 'menuButtonPressed'
    });
    this.pivot.set(this.width / 2, this.height / 2);
    this.position.set(963, 1804);
    this.pressed$.subscribe(_ => {
      soundManager.playSound('click');
      onButtonPressedAnimation(this);
    });
    this.click$.subscribe(_ => model.openMenu$.next("RULES"));
  }

}
