export const getColorByName = (color) => {
    switch (color) {
        case 'green':
            return 0x3cea84;
        case 'red':
            return 0xff5172;
        case 'yellow':
            return 0xe4ca04;
        case 'blue':
            return 0x5d94f7;
        default:
            return 0xffffff;
    }
}