import * as PIXI from "pixi.js";
import {filter} from "rxjs";
import {SpriteButton} from "../objects/SpriteButton";
import {onButtonPressedAnimation} from "../helpers/buttonHelper";
import {ReactiveRollButton} from "../objects/ReactiveRollButton";
import {distinctUntilChanged, pairwise, skip} from "rxjs/operators";
import { EnumGameModes } from '../helpers/constants/GameModes';
import {ReactiveCashoutButton} from "../objects/ReactiveCashoutButton";
import GameUtils from "../utils/GameUtils";
import {EnumToggleState, SpriteButtonSwitcher} from "../objects/SpriteButtonSwitcher";
import Constants from "../helpers/constants/Constants";
import {MenuButton} from "../objects/MenuButton";

// TODO: transfer all to MainScene
export class UiScene extends PIXI.Container {
    /**
     * @param model {Model}
     * @param server {Server}
     * @param soundManager {SoundManager}
     */
    constructor(model, server, soundManager) {
        super();

        this.canvasWidth = 1080;
        this.canvasHeight = 1920;
        this.tutorialMode = false;
        this.TUTORIAL_TIME = 2;

        this.model = model;
        this.server = server;
        this.soundManager = soundManager;
        this.gameMode$ = model.mode$;

        this.addExitBtn();
        this.addDiceBg();
        this.addRollBtn();
        this.addAutoRollSwitcher()
        this.addMenuBtn();
        this.addCashoutInfoBtn();
        this.addCashoutBtn();

        this.model.gameIsStarted$
            .pipe(distinctUntilChanged(), pairwise())
            .subscribe(([prevActive, isActive]) => {
                if (prevActive === isActive) { return; }

                // this.rollButton.autoPlayEnabled = false;
                // this.rollButton.disabled = false;

                this.rollButton.autoPlayEnabled = true;
                this.rollButton.disabled = true;
            })

        this.model.serverGameStarted$
            .pipe(filter(e => !!e))
            .subscribe(_ => {
                this.cookieValue = +GameUtils.getCookie(Constants.Cookies.COOKIE_CASHOUT_TOOLTIP);
                this.cookieValue = !this.cookieValue ? 0 : this.cookieValue;
                if (this.cookieValue < this.TUTORIAL_TIME && this.model.mode$.value === EnumGameModes.SINGLE) {
                    this.tutorialMode = true;
                    this.model.cashoutInfoModal$.next(true);
                    this.tooltipTimer = setTimeout(this.closeTooltip.bind(this), 6000);
                }

                this.rollButton.autoPlayEnabled = true;//!this.tutorialMode;
                this.autoRollSwitcher.toggleState = EnumToggleState.ON;

                if (!this.model.enableRollButton$.value) {
                    this.disabledRollButton();
                }
                if (this.model.gdh.isCashoutBlockUser) {
                    this.model.cashoutInfoModal$.next(false);
                    this.removeChild(this.cashout);
                    this.removeChild(this.cashoutInfoBtn);
                }
            });

        this.model.enableRollButton$
            .pipe(skip(1))
            .subscribe(active => {
                if (active) {
                    this.enableRollButton()
                } else {
                    this.disabledRollButton();
                }
            });
    }

    addExitBtn() {
        const exit = this.addChild(new SpriteButton({
            default: 'exitButton',
            over: 'exitButtonHover',
            pressed: 'exitButtonPressed',
        }));
        exit.pivot.set(exit.width / 2, exit.height / 2);
        exit.position.set(121, 1804);
        exit.pressed$.subscribe(_ => {
            this.soundManager.playSound('click');
            onButtonPressedAnimation(exit);
        });
        exit.click$.subscribe(_ => this.onExit());
        return exit;
    }

    onExit() {
        this.model.leaveGameModal$.next({
            exit: () => {
                //this.soundManager.playSound('click');
            },
            close: () => {
                //this.soundManager.playSound('click');
            }
        })
    }

    addDiceBg() {
        const container = this.addChild(new PIXI.Sprite());
        let currentColor = this.model.gdh.selectedColor;
        this.model.currentPlayerColor$
          .subscribe(color => {
              if (color === currentColor) return;

              container.removeChildren();
              const diceBg = container.addChild(PIXI.Sprite.from(color + 'DiceBg'))
              diceBg.anchor.set(0.5, 0.5);
              currentColor = color;
          });

        this.model.currentPlayerColor$.next(currentColor);
        container.position.set(540, 1804);
    }

    addRollBtn() {
        this.rollButton = this.addChild(new ReactiveRollButton(this.model));
        this.rollButton.pivot.set(this.rollButton.width / 2, this.rollButton.height / 2);
        this.rollButton.position.set(464, 1816);

        this.rollButton.click$
            .pipe(filter(_ => this.rollButton.autoPlayEnabled === false && this.rollButton.disabled === false))
            .subscribe(_ => {
                this.rollButton.disabled = true;
                if (this.model.enableRollButton$.value === true) {
                    this.model.rollButtonClicked$.next(undefined);
                }
            });

        this.rollButton.pressed$.subscribe(_ => this.soundManager.playSound('click'));

        this.model.autoPlayEnabled$
                .pipe(skip(2))
                .subscribe(active => {
                    this.server.autoPlayEnabled(active);
                    if (active) {
                        this.model.gdh.isAutomaticRollActive = true;
                        this.model.rollButtonClicked$.next(undefined)
                    } else {
                        this.model.gdh.isAutomaticRollActive = false;
                    }
                });
    }

    enableRollButton() {
        // ** here enable roll button
        if (this.model.gdh.isAutomaticRollActive) {
            this.model.rollButtonClicked$.next(undefined)
        } else {
            this.rollButton.disabled = false;
        }
    }

    disabledRollButton() {
        if (!this.model.gdh.isAutomaticRollActive) {
            this.rollButton.disabled = true;
        }
    }

    addMenuBtn() {
        this.addChild(new MenuButton(this.model, this.soundManager));
    }

    addCashoutInfoBtn() {
        this.cashoutInfoBtn = this.addChild(new SpriteButton({
            default: 'infoBtnNormal',
            over: 'infoBtnOver',
            pressed: 'infoBtnPressed'
        }));
        this.cashoutInfoBtn.pivot.set(this.cashoutInfoBtn.width / 2, this.cashoutInfoBtn.height / 2);
        this.cashoutInfoBtn.position.set(404, 1600);
        this.cashoutInfoBtn.pressed$.subscribe(_ => this.soundManager.playSound('click'));
        this.cashoutInfoBtn.click$.subscribe(() => {
            this.model.cashoutInfoModal$.next(true);
        });

        this.gameMode$
            .pipe(distinctUntilChanged())
            .subscribe(value => {
                this.cashoutInfoBtn.visible = value === EnumGameModes.SINGLE;
            });
    }

    addAutoRollSwitcher() {
        this.autoRollSwitcher = this.addChild(
            new SpriteButtonSwitcher(
                {
                default: 'autorollOnBtnNormal',
                over: 'autorollOnBtnHover',
                pressed: 'autorollOnBtnPressed',
            },
        {
                default: 'autorollOffBtnNormal',
                over: 'autorollOffBtnHover',
                pressed: 'autorollOffBtnPressed',
            },
            this.soundManager
        ));
        this.autoRollSwitcher.pivot.set(this.autoRollSwitcher.width / 2, this.autoRollSwitcher.height / 2);
        this.autoRollSwitcher.pressed$.subscribe(val => this.model.autoPlayEnabled$.next(val));

        this.gameMode$
            .pipe(distinctUntilChanged())
            .subscribe(_ => {
                this.autoRollSwitcher.position.set(this.rollButton.x + 298, 1804);
            });
    }

    addCashoutBtn() {
        this.cashout = this.addChild(new ReactiveCashoutButton(this.model));
        this.cashout.pivot.set(this.cashout.width / 2, this.cashout.height / 2);
        this.cashout.position.set(this.cashoutInfoBtn.x + this.cashout.width / 2 + 10, 1570);
        this.cashout.pressed$.subscribe(_ => {
            this.soundManager.playSound('click');
            onButtonPressedAnimation(this.cashout);
        });
        this.cashout.click$.subscribe(_ => this.onCashout());

        this.model.cashoutInfoModal$
            .pipe(distinctUntilChanged())
            .subscribe(value => {
                if (this.tutorialMode && !value) this.closeTooltip();
            });

        this.gameMode$
            .pipe(distinctUntilChanged())
            .subscribe(value => {
                this.cashout.visible = value === EnumGameModes.SINGLE;
            });
    }

    onCashout() {
        this.model.cashoutModal$.next(true);
    }

    closeTooltip() {
        GameUtils.setCookie(Constants.Cookies.COOKIE_CASHOUT_TOOLTIP, (this.cookieValue + 1).toString(), {secure: true, 'max-age': 31536000});

        if (this.tooltipTimer) clearTimeout(this.tooltipTimer);
        if (this.model.mode$.value === EnumGameModes.SINGLE) this.rollButton.autoPlayEnabled = true;
        this.tutorialMode = false;
        this.model.cashoutInfoModal$.next(false);
    }
}
