import {getParameterByName} from "../helpers/Utils";

export default {
    getCookie(name) {
        const matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([.$?*|{}()\[\]\\\/+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    },

    setCookie(name, value, options = null) {
        options = {
            path: '/',
            // add other values on default if its needed
            ...options
        };

        if (options.expires instanceof Date) {
            options.expires = options.expires.toUTCString();
        }

        let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

        for (let optionKey in options) {
            updatedCookie += "; " + optionKey;
            let optionValue = options[optionKey];
            if (optionValue !== true) {
                updatedCookie += "=" + optionValue;
            }
        }

        document.cookie = updatedCookie;
    },
    setGoogleAnalitics() {
        const url = getParameterByName("http_url") || process.env.HTTP_URL ;

        (function (i, s, o, g, r, a, m) {
            window['GoogleAnalyticsObject'] = 'ga';
            window['ga'] = window['ga'] || function () {
                (window['ga'].q = window['ga'].q || []).push(arguments)
            }, window['ga'].l = 1 * new Date();
            a = document.createElement('script'),
              m = document.getElementsByTagName('script')[0];
            a.async = 1;
            a.src = '//www.google-analytics.com/analytics.js';
            m.parentNode.insertBefore(a, m)
        })(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');

        ga('create', process.env.GA_ID, url);
        ga('send', 'pageview');
        return ga;
    }
}

//export default new GameUtils();
