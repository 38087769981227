import * as PIXI from 'pixi.js';
import { merge } from 'rxjs';
import { distinctUntilChanged, map, pairwise } from 'rxjs/operators';
import { EnumLayouts } from '../helpers/constants/EnumLayouts';
import {
  AutomoveModal,
  CashoutInfoModal,
  BalanceErrorModal,
  WinModal,
  EndGameModal,
  LeaveGameModal,
  MaintenanceModal,
  CriticalModal,
  SessionInterruptedModal,
  SessionLostModal,
  StartWarningModal,
  InsufficientFundsModal,
  NotEnoughMoneyModal,
  GetOfferToGiveUpModal,
  SendOfferToGiveUpModalSm,
  SendOfferToGiveUpModalLg,
  CashoutModal,
  CurrencyNotSupportedModal,
} from "../modals";
import {RestoreModal} from "../modals/RestoreModal";
import {UnrestoringRoundModal} from "../modals/UnrestoringRoundModal";

//TODO: need refactor
export class ModalsScene extends PIXI.Container {
  /**
   * @param sceneManager {SceneManager}
   * @param model {Model}
   * @param soundManager {SoundManager}
   * @param resizeManager {ResizeManager}
   * @param server {Server}
   */
  constructor(sceneManager, model, soundManager, resizeManager, server) {
    super();

    const modalsStr = [
      model.serverGameEnded$,
      model.leaveGameModal$,
      model.notEnoughMoney$,
      model.insufficientFunds$,
      model.startWarning$,
      model.sessionLost$,
      model.reconnecting$,
      model.criticalModal$,
      model.balanceErrorModal$,
      model.maintenanceModal$,
      model.cashoutInfoModal$,
      model.cashoutModal$,
      model.sendOfferToGiveUpModalSm$,
      model.sendOfferToGiveUpModalLg$,
      model.getOfferToGiveUpModal$,
      model.currecyNotSupportedModal$,
      model.automoveModal$,
      model.restoreModal$,
      model.unrestoringRoundModal$,
    ];

    const gameModals = [];

    const hidePrevModals = (actualModal) => {
      gameModals.filter(item => item !== actualModal).forEach(item => item.hide());
    }

    model.hideAllModals$
      .subscribe(() => {
        hidePrevModals();
      });

    merge(...modalsStr).subscribe((value) => {
      if (!sceneManager.has(EnumLayouts.MODALS)) {
        return;
      }

      if (!!value) {
        sceneManager.show(EnumLayouts.MODALS);

        if (sceneManager.has(EnumLayouts.MENU) && value !== EnumModals.DONT_CLOSE_MENU) {
          model.closeMenu$.next(true);
        }
      } else {
        const hasActiveModal = modalsStr.some(item$ => !!item$.value);
        if (hasActiveModal) return;

        sceneManager.hide(EnumLayouts.MODALS);
      }
    });

    const automoveModal = this.addChild(
      new AutomoveModal(sceneManager, model, resizeManager, soundManager)
    );
    gameModals.push(automoveModal);
    model.automoveModal$
      .subscribe((isActive) => {
        if (!model.closeMenu$.getValue()) {
          return;
        }
        isActive ? automoveModal.show() : automoveModal.hide();
      });


    const leaveGameModal = this.addChild(
      new LeaveGameModal(
        sceneManager,
        model,
        resizeManager,
        soundManager,
        server
      )
    );
    gameModals.push(leaveGameModal);
    model.leaveGameModal$
      .pipe(distinctUntilChanged(), pairwise())
      .subscribe(([prevActive, isActive]) => {
        if (prevActive === isActive) {
          return;
        }
        hidePrevModals(leaveGameModal);
        isActive ? leaveGameModal.show() : leaveGameModal.hide();
      });



    const winGame = this.addChild(
      new WinModal(sceneManager, model, soundManager, resizeManager, server)
    );
    gameModals.push(winGame);

    const loseGame = this.addChild(
      new EndGameModal(sceneManager, model, soundManager, resizeManager, server)
    );
    gameModals.push(loseGame);

    model.serverGameEnded$
      .pipe(distinctUntilChanged(), pairwise())
      .subscribe(([prevActive, isActive]) => {
        if (
          prevActive === isActive ||
          JSON.stringify(prevActive) === JSON.stringify(isActive)
        ) {
          return;
        }
        if (!!isActive) {
          if (isActive.playerWin) {
            hidePrevModals(winGame);
            winGame.show();
          } else {
            hidePrevModals(loseGame);
            loseGame.show();
          }
        } else {
          winGame.hide();
          loseGame.hide();
        }
      });

    const notMoneyModal = this.addChild(
      new NotEnoughMoneyModal(sceneManager, model, resizeManager, soundManager)
    );
    gameModals.push(notMoneyModal);
    model.notEnoughMoney$
      .pipe(distinctUntilChanged(), pairwise())
      .subscribe(([prevActive, isActive]) => {
        if (prevActive === isActive) {
          return;
        }
        hidePrevModals(notMoneyModal);
        isActive ? notMoneyModal.show() : notMoneyModal.hide();
      });


    const insufficientFundsModal = this.addChild(
      new InsufficientFundsModal(
        sceneManager,
        model,
        resizeManager,
        soundManager
      )
    );
    gameModals.push(insufficientFundsModal);
    model.insufficientFunds$
      .pipe(distinctUntilChanged(), pairwise())
      .subscribe(([prevActive, isActive]) => {
        if (prevActive === isActive) {
          return;
        }
        hidePrevModals(insufficientFundsModal);
        isActive ? insufficientFundsModal.show() : insufficientFundsModal.hide();
      });


    const startWarningModal = this.addChild(
      new StartWarningModal(sceneManager, model, resizeManager, soundManager)
    );
    gameModals.push(startWarningModal);
    model.startWarning$
      .pipe(distinctUntilChanged(), pairwise())
      .subscribe(([prevActive, isActive]) => {
        if (prevActive === isActive) {
          return;
        }
        hidePrevModals(startWarningModal);
        isActive ? startWarningModal.show() : startWarningModal.hide();
      });


    const sessionLostModal = this.addChild(
      new SessionLostModal(sceneManager, model, resizeManager, soundManager)
    );
    model.sessionLost$
      .pipe(distinctUntilChanged(), pairwise())
      .subscribe(([prevActive, isActive]) => {
        if (prevActive === isActive) {
          return;
        }
        isActive ? sessionLostModal.show() : sessionLostModal.hide();
      });


    const sessionInterruptedModal = this.addChild(
      new SessionInterruptedModal(
        sceneManager,
        model,
        resizeManager,
        soundManager
      )
    );
    merge(model.sessionLost$, model.reconnecting$)
      .pipe(
        map(
          (_) =>
            model.reconnecting$.getValue() && !model.sessionLost$.getValue()
        )
      )
      .pipe(distinctUntilChanged(), pairwise())
      .subscribe(([prevActive, isActive]) => {
        if (prevActive === isActive) {
          return;
        }
        isActive
          ? sessionInterruptedModal.show()
          : sessionInterruptedModal.hide();
      });


    const criticalModal = this.addChild(
      new CriticalModal(sceneManager, model, resizeManager, soundManager)
    );
    model.criticalModal$
      .pipe(distinctUntilChanged(), pairwise())
      .subscribe(([prevActive, isActive]) => {
        if (prevActive === isActive) {
          return;
        }
        isActive ? criticalModal.show() : criticalModal.hide();
      });


    const balanceErrorModal = this.addChild(
      new BalanceErrorModal(sceneManager, model, resizeManager, soundManager)
    );
    gameModals.push(balanceErrorModal);
    model.balanceErrorModal$
      .pipe(distinctUntilChanged(), pairwise())
      .subscribe(([prevActive, isActive]) => {
        if (prevActive === isActive) {
          return;
        }
        hidePrevModals(balanceErrorModal);
        isActive ? balanceErrorModal.show() : balanceErrorModal.hide();
      });


    const maintenanceModal = this.addChild(
      new MaintenanceModal(sceneManager, model, resizeManager, soundManager)
    );
    model.maintenanceModal$
      .pipe(distinctUntilChanged(), pairwise())
      .subscribe(([prevActive, isActive]) => {
        if (prevActive === isActive) {
          return;
        }
        isActive ? maintenanceModal.show() : maintenanceModal.hide();
      });


    const cashoutInfoModal = this.addChild(
      new CashoutInfoModal(sceneManager, model, resizeManager, soundManager)
    );
    gameModals.push(cashoutInfoModal);
    model.cashoutInfoModal$
      .pipe(distinctUntilChanged(), pairwise())
      .subscribe(([prevActive, isActive]) => {
        if (prevActive === isActive) {
          return;
        }
        hidePrevModals(cashoutInfoModal);
        isActive ? cashoutInfoModal.show() : cashoutInfoModal.hide();
      });


    const sendOfferToGiveUpModalSm = this.addChild(
      new SendOfferToGiveUpModalSm(
        sceneManager,
        model,
        resizeManager,
        soundManager,
        server
      )
    );
    gameModals.push(sendOfferToGiveUpModalSm);
    model.sendOfferToGiveUpModalSm$
      .pipe(distinctUntilChanged(), pairwise())
      .subscribe(([prevActive, isActive]) => {
        if (prevActive === isActive) {
          return;
        }
        hidePrevModals(sendOfferToGiveUpModalSm);
        isActive
          ? sendOfferToGiveUpModalSm.show()
          : sendOfferToGiveUpModalSm.hide();
      });


    const sendOfferToGiveUpModalLg = this.addChild(
      new SendOfferToGiveUpModalLg(
        sceneManager,
        model,
        resizeManager,
        soundManager,
        server
      )
    );
    gameModals.push(sendOfferToGiveUpModalLg);
    model.sendOfferToGiveUpModalLg$
      .pipe(distinctUntilChanged(), pairwise())
      .subscribe(([prevActive, isActive]) => {
        if (prevActive === isActive) {
          return;
        }
        hidePrevModals(sendOfferToGiveUpModalLg);
        isActive ? sendOfferToGiveUpModalLg.show() : sendOfferToGiveUpModalLg.hide();
    });


    const getOfferToGiveUpModal = this.addChild(
      new GetOfferToGiveUpModal(
        sceneManager,
        model,
        resizeManager,
        soundManager,
        server
      )
    );
    gameModals.push(getOfferToGiveUpModal);
    model.getOfferToGiveUpModal$
      .pipe(distinctUntilChanged(), pairwise())
      .subscribe(([prevActive, isActive]) => {
        if (prevActive === isActive) {
          return;
        }
        hidePrevModals(getOfferToGiveUpModal);
        isActive ? getOfferToGiveUpModal.show() : getOfferToGiveUpModal.hide();
      });


    if (model.maintenanceModal$.value === true) {
      maintenanceModal.show();
    }

    const cashoutModal = this.addChild(new CashoutModal(sceneManager, model, resizeManager, soundManager, server));
    gameModals.push(cashoutModal);
    model.cashoutModal$
      .pipe(distinctUntilChanged(), pairwise())
      .subscribe(([prevActive, isActive]) => {
        if (prevActive === isActive) { return; }
        hidePrevModals(cashoutModal);
        isActive ? cashoutModal.show() : cashoutModal.hide();
      });

    const currencyNotSupportedModal = this.addChild(new CurrencyNotSupportedModal(sceneManager, model, resizeManager, soundManager));
    gameModals.push(currencyNotSupportedModal);
    model.currecyNotSupportedModal$
      .pipe(distinctUntilChanged(), pairwise())
      .subscribe(([prevActive, isActive]) => {
        if (prevActive === isActive) { return; }
        hidePrevModals(currencyNotSupportedModal);
        isActive ? currencyNotSupportedModal.show() : currencyNotSupportedModal.hide();
      });

    const restoreModal = this.addChild(new RestoreModal(sceneManager, model, resizeManager, soundManager, server));
    gameModals.push(restoreModal);
    model.restoreModal$
      .pipe(distinctUntilChanged(), pairwise())
      .subscribe(([prevActive, isActive]) => {
        if (prevActive === isActive || !model.restoreModal$.getValue()) {
          return;
        }
        hidePrevModals(restoreModal);
        isActive ? restoreModal.show() : restoreModal.hide();
      });

    const unrestoringModal = this.addChild(new UnrestoringRoundModal(sceneManager, model, resizeManager, soundManager, server));
    gameModals.push(unrestoringModal);
    model.unrestoringRoundModal$
      .pipe(distinctUntilChanged(), pairwise())
      .subscribe(([prevActive, isActive]) => {
        if (prevActive === isActive || !model.unrestoringRoundModal$.getValue()) {
          return;
        }
        hidePrevModals(unrestoringModal);
        isActive ? unrestoringModal.show() : unrestoringModal.hide();
      });
  }
}

export const EnumModals = {
  DONT_CLOSE_MENU: 1,
}
