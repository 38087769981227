import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import locales from './locales';
import {GameManager} from "./services/GameManager";
import "./assets/css/style.css";

const game = new GameManager();
game.init();

game.model.gdh.parseUrlParams();

if (game.model.gdh.urlParams.get('debug')) {
    window.setCheat = function (diceRoles) {
        if (diceRoles === undefined) {
            console.warn('Cheats: setCheat([3, 2, 1, 2, 3, 4, 5, 6]) - step by step all dice values\nsetCheat([3, 2, [1, 1], 2, [3, 1], 4, 5, 6]) - it is additional control [3 - dice value, 1 - select token] ');
            return;
        }

        game.server.setCheat(Array.isArray(diceRoles) ? diceRoles : [diceRoles]);
    }

    window.showPopup = function (type) {
        switch (type) {
            case "reconnecting":
            case "sessionLost":
            case "notEnoughMoney":
            case "insufficientFunds":
            case "criticalModal":
            case "balanceErrorModal":
            case "maintenanceModal":
            case "startWarning":
            case "leaveGameModal":
                game.model[`${type}$`].next(true);
                break;

            case "winModal":
                game.model.serverGameEnded$.next({ playerWin: 1000 });
                break;

            case "loseModal":
                game.model.serverGameEnded$.next({  });
                break;

            default:
                console.warn('Popups: "reconnecting", "sessionLost", "notEnoughMoney", "insufficientFunds", "criticalModal", "maintenanceModal", "startWarning", "leaveGameModal", "winModal", "loseModal"');
                break;
        }
    }
}

window.data = {
    stake: 0,
    currency: "USD",
    playersCount: 0,
};


i18next.use(LanguageDetector)
    .init({
        resources: {
            ...locales,
        },
        fallbackLng: ['en'],
        detection: {
            lookupQuerystring: 'lang',
        },
    });

// before page reload
window.onbeforeunload = (event) => {
    game.server.leaveGame();
    if (game.server.model.gameIsStarted$.value === true) {
        return i18next.t("modals.leave_game_prompt");
    }
}
