import * as PIXI from "pixi.js";
import i18next from 'i18next';
import {BaseModal} from "./BaseModal";

export class MaintenanceModal extends BaseModal {
    constructor(rootContainer, model, resizeManager, soundManager) {
        super(rootContainer, model, resizeManager, soundManager);
    }

    createModal() {
        const container = new PIXI.Container();
        const bg = container.addChild(PIXI.Sprite.from('maintenanceModal'));
        bg.pivot.set(bg.width / 2, bg.height / 2);
        bg.position.set(541, 970);

        const text = container.addChild(new PIXI.Text(i18next.t('modals.maintenance'), {
            fontFamily: "Akshar-Medium",
            fontSize: "60px",
            fill: "#FFDB15",
            align: "center",
            stroke: "#490000",
            strokeThickness: 13,
            wordWrapWidth: 730,
            wordWrap: true,
        }));
        text.anchor.set(0.5);
        text.position.set(545, 1117);

        this.btn = this.createBtn(container, 'modals.refresh', () => this.onRefresh());
        this.btn.position.set(this.width / 2, 1311);

        this.addAnimationItems([bg, text, this.btn]);

        return container;
    }

    async onRefresh() {
        this.btn.disabled = true;
        await this.hide();

        window.location.reload();
    }

    show() {
        super.show();
        this.btn.disabled = false;
    }
}
