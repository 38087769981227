import * as PIXI from "pixi.js";
import i18next from 'i18next';
import { gsap } from "gsap";
import {BaseModal} from "./BaseModal";

export class LeaveGameModal extends BaseModal {
    constructor(rootContainer, model, resizeManager, soundManager, server) {
        super(rootContainer, model, resizeManager, soundManager);

        this.server = server;
    }

    createModal() {
        const container = new PIXI.Container();
        const bg = container.addChild(PIXI.Sprite.from('exitModal'));
        bg.pivot.set(bg.width / 2, bg.height / 2);
        bg.position.set(541, 915);

        this.text = container.addChild(new PIXI.Text(`${i18next.t('modals.if_you_leave')} ${this.model.gdh.formatCurrency(this.model.gdh.currentBet)}`, {
            fontFamily: "Akshar-Medium",
            fontSize: "60px",
            fill: ["#FFDB15", "#FFA800"],
            align: "center",
            stroke: "#490000",
            strokeThickness: 13,
            wordWrapWidth: 900,
            wordWrap: true,
            dropShadow: true,
            dropShadowColor: "#000000",
            dropShadowDistance: 5,
            dropShadowAngle: 0.5
        }));
        this.text.anchor.set(0.5);
        this.text.position.set(545, 1081);

        this.confirmButton = this.createBtn(container, 'modals.exit', () => this.onExit());
        this.confirmButton.position.set(291, 1317);

        this.cancelButton = this.createBtn(container, 'modals.cancel', () => this.onCancel());
        this.cancelButton.position.set(754, 1317);

        this.addAnimationItems([bg, this.text, this.confirmButton, this.cancelButton]);

        return container;
    }

    async onExit() {
        this.confirmButton.disabled = true;
        await this.hide();

        const value = this.model.leaveGameModal$.getValue();
        if (value && typeof value.exit === 'function') {
            value.exit();
        }

        this.model.serverGameStarted$.next(false);
        this.model.gameIsStarted$.next(false);
        this.model.clear();
        this.model.leaveGame$.next({isLeaved: true, isCashout: false});
        this.model.leaveGameModal$.next(false);

        if (this.model.mode$.value === 'multi') {
            await new Promise(resolve => {
                gsap.delayedCall(1, resolve);
            });

            await this.server.join();
        }
    }

    async onCancel() {
        this.cancelButton.disabled = true;
        await this.hide();

        const value = this.model.leaveGameModal$.getValue();
        if (value && typeof value.close === 'function') {
            value.close();
        }

        this.model.gameIsStarted$.next(true);
        this.model.leaveGameModal$.next(false)
    }

    show() {
        this.text.text = `${i18next.t('modals.if_you_leave')} ${this.model.gdh.formatCurrency(this.model.gdh.currentBet)}`;
        super.show();
        this.confirmButton.disabled = false;
        this.cancelButton.disabled = false;
    }
}
