import axios from 'axios';
import {getParameterByName} from "../helpers/Utils";

export default {

  async send(model, params) {
    return this._tryToSendRequest(model, params);
  },

  async _tryToSendRequest(
    model,
    body,
    reconnecting = 5,
  ) {
    try {
      const url = getParameterByName("http_url") || process.env.HTTP_URL ;
      const res = await axios.post(url + '/game/init', body, {
        timeout: 30 * 1000,
      });
      if (res.data === undefined) {
        console.log(`Server error!, res.data = ${res.data}`);
        model.criticalModal$.next(true);
        return Promise.reject(Error("Empty game response"));
      }

      return res.data;
    } catch (e) {
      if (reconnecting <= 0) {
        console.log(`Server error! ${e.message}`);
        model.criticalModal$.next(true);
        return {
          status: {
            message: e.message,
            type: 'SESSION_CRASH',
            code: 500,
          },
        };
      }

      return await this._tryToSendRequest(model, body, --reconnecting);
    }
  },

  async _checkRound(
    model,
    supplierUser,
    reconnecting = 5,
  ) {
    try {
      const url = getParameterByName("http_url") || process.env.HTTP_URL ;
      const res = await axios.get(url + '/game/round/active/' + supplierUser);
      if (res.data === undefined) {
        console.log(`Server error!, res.data = ${res.data}`);
        return Promise.reject(Error("Empty game response"));
      }

      return res.data;
    } catch (e) {
      if (reconnecting <= 0) {
        console.log(`Server error! ${e.message}`);
        return {
          status: {
            message: e.message,
            type: 'SESSION_CRASH',
            code: 500,
          },
        };
      }

      return await this._checkRound(model, supplierUser, --reconnecting);
    }
  },

  async _connectRound(
    model,
    round,
    reconnecting = 5,
  ) {
    try {
      const url = getParameterByName("http_url") || process.env.HTTP_URL ;
      const res = await axios.get(url + '/game/round/connect/' + round);
      if (res.data === undefined) {
        console.log(`Server error!, res.data = ${res.data}`);
        model.criticalModal$.next(true);
        return Promise.reject(Error("Empty game response"));
      }

      return res.data;
    } catch (e) {
      if (reconnecting <= 0) {
        console.log(`Server error! ${e.message}`);
        model.criticalModal$.next(true);
        return {
          status: {
            message: e.message,
            type: 'SESSION_CRASH',
            code: 500,
          },
        };
      }

      return await this._connectRound(model, round, --reconnecting);
    }
  },

  async _tryToSendPatchRequest(
    model,
    supplierUser,
    body,
    reconnecting = 5,
  ) {
    try {
      if (model.gdh.urlParams.get('debug')) {
        console.log("username = " + body.username);
      }
      const url = getParameterByName("http_url") || process.env.HTTP_URL ;
      const res = await axios.patch(url + `/user/${supplierUser}`, body, {
        timeout: 30 * 1000,
      });
      if (res.data === undefined) {
        console.log(`Server error!, res.data = ${res.data}`);
        model.criticalModal$.next(true);
        return Promise.reject(Error("Empty game response"));
      }

      return res.data;
    } catch (e) {
      if (reconnecting <= 0) {
        console.log(`Server error! ${e.message}`)
        model.criticalModal$.next(true);
        return {
          status: {
            message: e.message,
            type: 'SESSION_CRASH',
            code: 500,
          },
        };
      }

      return await this._tryToSendPatchRequest(model, supplierUser, body, --reconnecting);
    }
  },
  async _tryToSendAction(
    body,
    reconnecting = 5,
  ) {
    try {
      const url = getParameterByName("http_url") || process.env.HTTP_URL ;
      const res = await axios.post(url + '/monitoring', body, {
        timeout: 30 * 1000,
      });
      if (res.data === undefined) {
        console.log(`Server error!, res.data = ${res.data}`);
        return Promise.reject(Error("Empty game response"));
      }

      return res.data;
    } catch (e) {
      if (reconnecting <= 0) {
        console.log(`Server error! ${e.message}`);
        return {
          status: {
            message: e.message,
            type: 'SESSION_CRASH',
            code: 500,
          },
        };
      }

      return await this._tryToSendAction(body, --reconnecting);
    }
  },
  async _closeRound(
    model,
    round,
    reconnecting = 5,
  ) {
    try {
      const url = getParameterByName("http_url") || process.env.HTTP_URL ;
      const res = await axios.delete(url + '/game/round/close/' + round);
      if (res.data === undefined) {
        console.log(`Server error!, res.data = ${res.data}`);
        model.criticalModal$.next(true);
        return Promise.reject(Error("Empty game response"));
      }

      return res.data;
    } catch (e) {
      if (reconnecting <= 0) {
        console.log(`Server error! ${e.message}`);
        model.criticalModal$.next(true);
        return {
          status: {
            message: e.message,
            type: 'SESSION_CRASH',
            code: 500,
          },
        };
      }

      return await this._closeRound(model, round, --reconnecting);
    }
  },

}
