import {BehaviorSubject, Subject, throttleTime} from "rxjs";

export class ResizeManager {
    constructor() {
        /**
         * @type {BehaviorSubject<{width: number, scale: number, height: number, shiftY: number}>}
         */
        this.resize$ = new BehaviorSubject(this.getCanvasSize());

        this.canvasWidth = 1080;
        this.canvasHeight = 1920;

        const localResize$ = new Subject();

        window.addEventListener('resize', () => {
            localResize$.next(this.getCanvasSize());

            this.getCanvasSize();
        });

        localResize$
            .pipe(throttleTime(50, undefined, { leading: true, trailing: true }))
            .subscribe(data => this.resize$.next(data));

        this.resize$.next(this.getCanvasSize());
    }

    getWindowSize() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        }
    }

    getCanvasSize() {
        const window = this.getWindowSize();

        // min width for canvas be normal
        const minWidth = window.height / (this.canvasHeight / this.canvasWidth);
        if (minWidth < window.width) {
            return {
                width: this.canvasWidth,
                height: this.canvasHeight,
                scale: 1,
                shiftY: 0
            }
        }

        const width = Math.round(this.canvasHeight / (window.height / window.width));
        const scale = width / this.canvasWidth;
        const sceneHeight = width / (this.canvasWidth / this.canvasHeight);
        const shiftY = this.canvasHeight - sceneHeight;

        return {
            width,
            height: this.canvasHeight,
            scale,
            shiftY
        }
    }

}
