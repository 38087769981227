import * as PIXI from "pixi.js";
import i18next from "i18next";
import {VerticalScroll} from "../objects/VerticalScroll";

export class MenuRules extends PIXI.Container {

    ADDITIONAL_MARGIN_HEADER = 40;
    MARGIN_BETWEEN_IMG = 30;
    MARGIN_BETWEEN_TEXT = 15;
    MARGIN_BETWEEN_BLOCKS = 45;

    RULES_BLOCK_LIST = [
        [
            "menu.rules_text.header_1",
            "menu.rules_text.ul_1",
        ],
        [
            "menu.rules_text.header_2",
            "menu.rules_text.ul_2",
            "menu.rules_text.ul_3",
            "menu.rules_text.ul_4",
            "menu.rules_text.ul_5",
            "menu.rules_text.ul_6",
            "menu.rules_text.ul_7",
        ],
        [
            "img_rules_board",
        ],
        [
            "menu.rules_text.ul_9",
        ],
        [
            "menu.rules_text.ul_10",
            "menu.rules_text.ul_11",
            "menu.rules_text.ul_12",
        ],
        [
            "menu.rules_text.header_3",
            "menu.rules_text.ul_13",
            "menu.rules_text.ul_14",
        ],
        [
            "menu.rules_text.ul_15",
            "menu.rules_text.ul_16",
        ],
        [
            "menu.rules_text.ul_17",
            "menu.rules_text.ul_18",
        ],
        [
            "menu.rules_text.ul_19",
            "menu.rules_text.ul_20",
        ],
        [
            "menu.rules_text.ul_21",
            "menu.rules_text.ul_22",
        ],
        [
            "menu.rules_text.ul_23",
            "menu.rules_text.ul_24",
        ],
        [
            "menu.rules_text.header_4",
            "menu.rules_text.ul_25",
            "menu.rules_text.ul_26",
        ],
        [
            "menu.rules_text.ul_27",
            "menu.rules_text.ul_28",
        ],
        [
            "menu.rules_text.ul_29",
            "menu.rules_text.ul_30",
        ],
        [
            "menu.rules_text.ul_31",
            "menu.rules_text.ul_32",
        ],
        [
            "menu.rules_text.ul_33",
            "menu.rules_text.ul_34",
        ],
        [
            "menu.rules_text.ul_35",
            "menu.rules_text.ul_36",
        ],
        [
            "menu.rules_text.ul_37",
            "menu.rules_text.ul_38",
        ],
        [
            "menu.rules_text.ul_39",
            "menu.rules_text.ul_40",
        ],
        [
            "menu.rules_text.ul_41",
            "menu.rules_text.ul_42",
            "menu.rules_text.ul_43",
        ],
        [
            "menu.rules_text.ul_44",
            "menu.rules_text.ul_45",
        ],
        [
            "menu.rules_text.ul_46",
            "menu.rules_text.ul_47",
            "menu.rules_text.ul_48",
        ],
        [
            "menu.rules_text.ul_49",
            "menu.rules_text.ul_50",
        ],
        [
            "menu.rules_text.header_5",
            "menu.rules_text.ul_51",
            "menu.rules_text.ul_52",
        ],
        [
            "menu.rules_text.ul_53",
            "menu.rules_text.ul_54",
        ],
    ];

    /**
     * @param model {Model}
     * @param server {Server}
     * @param soundManager {SoundManager}
     */
    constructor(model, server, soundManager) {
        super();

        this.model = model;
        this.server = server;
        this.soundManager = soundManager;

        this.addTopBar();
        this.textContainer = new PIXI.Container();
        this.addScrollBox();
        this.addText();
    }

    onOpen() {
        this.scroll.onActivate();
    }

    onClose() {
        this.scroll.onDisactivate();
    }

    addTopBar() {
        const topBarText = this.addChild(new PIXI.Text(i18next.t('menu.rules'), {
            fontFamily: "Mybahnschrift",
            fontSize: "64px",
            fill: "#ffffff",
            align: "center",
        }));
        topBarText.anchor.set(0.5);
        topBarText.position.set(538, 76);
    }

    addScrollBox() {
        const container = this.addChild(new PIXI.Container());
        container.position.set(100, 321);

        this.scroll = container.addChild(new VerticalScroll(900, 1300));
        this.scroll.content.addChild(this.textContainer);
    }

    addText() {
        this.textContainer.removeChildren();

        const styleHeader = {
            fontFamily: "Bahnschrift-Bold",
            fontSize: "50px",
            fontWeight: 800,
            fill: "#ffffff",
            align: "center",
            letterSpacing: 7,
            wordWrapWidth: 800,
        };

        const styleUl = {
            fontFamily: "Mybahnschrift",
            fontSize: "40px",
            wordWrap: true,
            fill: "#ffffff",
            letterSpacing: 4,
            wordWrapWidth: 800,
            padding: 10,
            lineSpacing: 10,
        }

        let yPosPointer = 0;

        this.RULES_BLOCK_LIST.forEach(blockElements => {
            blockElements.forEach(elementId => {
                const isImg = /img_/.test(elementId);

                if (isImg) {
                    const imgName = elementId.split("img_")[1];
                    if(imgName) {
                        const sprite = this.textContainer.addChild(
                            new PIXI.Sprite(PIXI.Texture.from(imgName))
                        );
                        sprite.anchor.set(0.5, 0);
                        sprite.position.set(440, yPosPointer + this.MARGIN_BETWEEN_IMG);
                        yPosPointer += sprite.height + this.MARGIN_BETWEEN_IMG * 2;
                    }
                } else {
                    const isHeader = /header/.test(elementId);
                    const text = this.textContainer.addChild(new PIXI.Text(i18next.t(elementId), isHeader ? styleHeader : styleUl));
                    
                    if (isHeader) {
                        text.anchor.set(0.5, 0);
                        text.position.set(440, yPosPointer + this.MARGIN_BETWEEN_TEXT);
                    } else {
                        text.position.set(50, yPosPointer + (isHeader ? this.MARGIN_BETWEEN_TEXT : 0));
                    }
    
                    if (text.text.indexOf("{hint_") >= 0) {
                        const str =  text.text.split("{hint_")[1];
                        const arr = str.split("}");
                        const icon = this.getHint(arr[0]);
                        icon.x = 100;
                        icon.y = yPosPointer + 26;
                        text.text = arr[1];
                        text.x += icon.width;
                    }
        
                    const textBounds = text.getBounds();
                    yPosPointer += textBounds.height + this.MARGIN_BETWEEN_TEXT + (isHeader ? this.ADDITIONAL_MARGIN_HEADER : 0);
                }
            });

            yPosPointer += this.MARGIN_BETWEEN_BLOCKS;
        });

        this.scroll.updateScroll();
    }

    getHint(name) {
        const hint = this.textContainer.addChild(
          new PIXI.Container()
        );
        const hintBg = hint.addChild(
          new PIXI.Sprite(
            PIXI.Texture.from('hintEmpty')
          )
        );
        hintBg.anchor.set(0.5);

        const image = hint.addChild(
          new PIXI.Sprite(
            PIXI.Texture.from(name)
          )
        );
        image.anchor.set(0.5);
        image.position.set(0, -18);
        hint.scale.set(0.55);
        return hint;
    }
}
