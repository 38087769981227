import * as PIXI from "pixi.js";
import {Subscription} from "rxjs";
import {distinctUntilChanged} from "rxjs/operators";
import {gsap, Quad} from "gsap";
import GameProperties, {PlayersPositions} from "../data/GameProperties";
import {relativeFieldIndexToFindField, relativeToAbsoluteIndex} from "../helpers/TokenHelper";
import {ActivityHint} from "./ActivityHint";

export class TokenImage extends PIXI.Container {
    constructor(config, model, soundManager) {
        super();

        /**
         * @type {{
         * name: string,
         * x: number,
         * y: number,
         * index: number,
         * startingField: string,
         * finishField: string,
         * team: 'bottom-left' | 'top-left' | 'top-right' | 'bottom-right',
         * stream$: Subscription<string>,
         * hint$: Subscription<string>,
         * scale$: Subscription<string>,
         * availableTokens$: Subscription<string>,
         * updateForce$: Subscription<boolean>,
         * syncServer$: Subscription<boolean>,
         * isQuickMode: () => boolean,
         * onTop: () => boolean,
         * moveEnd: () => boolean,
         * }}
         */
        this.config = config;
        this.model = model;
        this.soundManager = soundManager;

        this.originalWidth = 65;
        this.originalHeight = 74;

        this.tokenWidth = 132;
        this.tokenHeight = 191;

        this.hintWidth = 386;
        this.hintHeight = 322;

        this.hintFrame = -1;
        this.prevField = null;

        this.setup();

        if (this.config.team === PlayersPositions.BL)
            this.addActivityHint();

        this._forceUpdate = false;
        this.setAbsolutePosition(-1);

        this.visible = true;

        // return;
        this.config.updateForce$
            .pipe(distinctUntilChanged())
            .subscribe(force => this._forceUpdate = force);

        this.config.stream$
            .pipe(distinctUntilChanged())
            .subscribe(async ({prevPosition = -1, position = -1, killed = false}) => {
                if (prevPosition === position) {
                    if (this._forceUpdate) {
                        this.setAbsolutePosition(relativeToAbsoluteIndex(position, this.config.team));
                        this.fitTokensOnField(1, 0);
                        const tokenConfig = this._findFieldByAbsoluteIndex(this.toAbsolutePosition);

                        this.x = tokenConfig.x;
                        this.y = tokenConfig.y;

                        this.config.moveEnd();
                    }

                    gsap.delayedCall(0.1, () => this.config.syncServer$.next());
                    return;
                }

                if (prevPosition > -1 && position === -1) {
                    await this.actionEat(prevPosition);
                    gsap.delayedCall(0.1, () => this.config.syncServer$.next());
                    return;
                }

                this.config.onTop();
                await this.actionMove(prevPosition, position, killed);
                gsap.delayedCall(0.1, () => this.config.syncServer$.next());
            });

        this.config.availableTokens$
            .pipe(distinctUntilChanged())
            .subscribe(availableTokens => {
                if (availableTokens.includes(this.config.index)) {
                    this.jumpAnim();
                    this.model.enableCashoutButton$.next({enabled:true});
                    /*if (this.isAllEqualTokens(availableTokens)) {
                        if (this.equalTokens[this.config.index]) {
                            gsap.killTweensOf(this.autoSelectTokenTimeout);
                            const delay = this.model.gdh.cashout > 0 ? 5 : 0;
                            this.autoSelectTokenTimeout = gsap.delayedCall(delay, this.onSelectToken.bind(this));
                        }
                    }*/
                } else {
                    this.clearJumpTween();
                    if (this.config.team === PlayersPositions.BL) this.hint.setVisible(false);
                }
            });

        this.config.scale$
            .pipe(distinctUntilChanged())
            .subscribe(({ length, position }) => {
                if (this.toAbsolutePosition === -1) {
                    this.fitTokensOnField(1, 0);
                } else {
                    this.fitTokensOnField(length, position);
                }
            });

        this.config.hint$
            .subscribe(({stateHint}) => {
                if (!stateHint || (this.config.team !== PlayersPositions.BL)) return;

                switch (stateHint) {
                    case HintStates.NONE:
                        this.hintFrame = 0;
                        break;
                    case HintStates.SAFE:
                        this.hintFrame = 1;
                        break;
                    case HintStates.KILL:
                        this.hintFrame = 2;
                        break;
                    case HintStates.WIN:
                        this.hintFrame = 3;
                        break;
                    default:
                        this.hintFrame = -1;
                        break;
                }
            });
    }

    isQuickMode() {
        return this.config.isQuickMode;
    }

    setup() {
        this.clickSurface = this.addChild(new PIXI.Container());
        // const gr = new PIXI.Graphics();
        // gr.beginFill(0x000000, 0.5);
        // gr.drawRect(-this.tokenWidth / 2 + 20, -this.tokenHeight / 2 + 50, this.tokenWidth + 20, this.tokenHeight + 20 );
        // gr.endFill();
        // this.clickSurface.addChild(gr);
        this.clickSurface.hitArea = new PIXI.Rectangle(-this.tokenWidth / 2 + 20, -this.tokenHeight / 2 + 50, this.tokenWidth + 20, this.tokenHeight + 20 );
        this.clickSurface.on('pointerdown', this.onSelectToken, this);

        this.tokenContainer = this.addChild(new PIXI.Container());

        this.token = this.tokenContainer.addChild(new PIXI.AnimatedSprite([
            PIXI.Texture.from('token1'), // blue
            PIXI.Texture.from('token2'), // red
            PIXI.Texture.from('token3'), // green
            PIXI.Texture.from('token4'), // yellow
        ]));
        this.token.gotoAndStop(0);
        this.token.pivot.set(this.token.width / 2, this.token.height / 2);
        this.tokenContainer.pivot.set(this.token.width / 2, this.token.height / 2);
        this.token.position.set(this.originalWidth / 2, this.originalHeight / 2);

        /*const text = this.tokenContainer.addChild(
            new PIXI.Text(this.config.index, {
                fontSize: "60px",
                align: "center",
            })
        );
        text.anchor.set(0.5);
        text.position.set(30, 0);*/
    }

    addActivityHint() {
        this.hint = this.addChild(new ActivityHint(this.model));
        this.hint.scale.set(2);
        this.hint.position.set(30, -200);
        this.hint.setVisible(false);
    }

    async actionMove(fromPosition, toPosition, killed = false) {
        //if (toPosition === this.lastPosition) return;

        //this.lastPosition = toPosition;
        if (fromPosition === -1) {
            this.setAbsolutePosition(relativeToAbsoluteIndex(toPosition, this.config.team));
            await this._actionMove([GameProperties.fields[this.toAbsolutePosition]], fromPosition, toPosition, killed);
            return;
        }

        const path = this._getPath(fromPosition, toPosition);
        this.setAbsolutePosition(relativeToAbsoluteIndex(toPosition, this.config.team));

        await this._actionMove(path, fromPosition, toPosition, killed);
    }

    async _actionMove(path = [], fromPosition = -1, toPosition = -1, killed = false) {
        this.clearAllAnimation();
        await this.moveToken(path, fromPosition === -1);

        this.config.moveEnd();
    }

    async actionEat(fromPosition) {
        this.setAbsolutePosition(relativeToAbsoluteIndex(-1, this.config.team));

        const pathToHouse = this._calcPathToReturnToHouse(fromPosition, this.config.team);
        await this.eaten(pathToHouse);

        this.config.moveEnd();
    }

    clearAllAnimation() {
        gsap.getTweensOf(this.token).forEach(tween => {
            tween.pause(1);
            tween.kill();
        });

        // this.toAbsolutePosition = -1;
    }

    onSelectToken() {
        //gsap.killTweensOf(this.autoSelectTokenTimeout);

        if (this.readyToMove && this.config.availableTokens$.value.length > 0) {
            this.config.availableTokens$.next([]);
            this.readyToMove = false;
            this.clearAllAnimation();
            this.clearJumpTween();
            this.model.selectedToken$.next(this.config.index);
        } else if (this.readyToMove) {
            this.model.enableCashoutButton$.next({enabled:false});
        }
    }

    jumpAnim() {
        this.clearJumpTween();
        gsap.to(this.tokenContainer, {
            y: this.token.height / 2 - 40,
            yoyo: true,
            repeat: -1,
            duration: this._forceUpdate ? 0 : 0.3,
            delay: this._forceUpdate ? 0 : 0.15 + (0.05 * parseInt(this.config.name[this.config.name.length - 1])),
            ease: Quad.easeOut
        });

        this.readyToMove = true;
        this.clickSurface.eventMode = 'static';
        this.clickSurface.buttonMode = true;

        if (this.hintFrame >= 0) {
            this.hint.setVisible(true);
            this.hint.setHalfTransparent(false);
            this.hint.setHint(this.hintFrame);
            if (this.hintFrame > 0) this.checkCollideHint(this.hint);
        }
    }

    checkCollideHint() {
        Object.entries(this.model.tokens$).forEach(this.checkTokensPositions.bind(this));
    }

    checkTokensPositions(entry) {
        const team = entry[0];
        const tokens = entry[1];

        if (this.model.gdh.numberOfPlayers === 2) {
            if (team === PlayersPositions.BR || team === PlayersPositions.TL) return;
        }

        for (let i = 0; i < tokens.length; i++) {
            if (team !== this.config.team || i !== this.config.index) {
                const position = tokens[i].value.position;
                if (position >= 0) {
                    const field = GameProperties.fields[relativeToAbsoluteIndex(position, team)];
                    if (field) {
                        const tokenX = field.x;
                        const tokenY = field.y;
                        if (this.isHintCollide(tokenX, tokenY, i)) {
                            this.hint.setHalfTransparent(true);
                            //console.log("isCollide", i, this.config.index);
                            break;
                        }
                    }
                }
            }
        }
    }

    isHintCollide(tokenX, tokenY, i) {
        tokenX -= 10;
        tokenY -= 10;

        const tokenWidth = this.tokenWidth * this.scale.x;
        const tokenHeight = this.tokenHeight * this.scale.x;

        const hintX = this.x - 53;
        const hintY = this.y - 108;

        const hintWidth = this.hintWidth * this.scale.x;
        const hintHeight = this.hintHeight * this.scale.x;

        const isHintCollide = tokenX < hintX + hintWidth
            && tokenX + tokenWidth > hintX
            && tokenY < hintY + hintHeight
            && tokenY + tokenHeight > hintY;

            //console.log(tokenX < hintX + hintWidth, tokenX + tokenWidth > hintX,  tokenY < hintY + hintHeight, tokenY + tokenHeight > hintY, i, this.config.index);

            /*if (this.graphics1) this.graphics1.clear();
            if (this.graphics2) this.graphics2.clear();

            this.graphics1 = this.parent.addChild(new PIXI.Graphics());
            this.graphics1.beginFill(0x080F3D, 0.2);
            this.graphics1.drawRect(tokenX, tokenY, tokenWidth, tokenHeight);
            this.graphics1.endFill();

            this.graphics2 = this.parent.addChild(new PIXI.Graphics());
            this.graphics2.beginFill(0x080F3D, 0.2);
            this.graphics2.drawRect(hintX, hintY, hintWidth, hintHeight);
            this.graphics2.endFill();*/

        return isHintCollide;
    }

    clearJumpTween() {
        gsap.getTweensOf(this.tokenContainer).forEach(tween => {
            tween.pause(1);
            tween.kill();
        });
        // '+ 5' - fix token position after animation end
        this.tokenContainer.y = this.token.height / 2 + 5;
        this.readyToMove = false;
        this.clickSurface.eventMode = 'none';
        this.clickSurface.buttonMode = false;
    }

    async moveToken(path, fromStartField = false) {
        this.currentField = null;
        if (fromStartField) {
            return this.moveFromHouse(path);
        } else {
            return this.moveTo(path);
        }
    }

    moveTo(path, duration = window.speedToken || 150) {
        path.forEach((field, i) => {
            gsap.delayedCall(((duration + 50) * (i + 1)) / 1000, () => {
                this.moveTokenOneField(field, duration);
            })
        });

        return new Promise((resolve) => {
            gsap.delayedCall(((duration + 50) * (path.length + 1)) / 1000, () => {
                const endMoveField = path[path.length - 1];

                if (endMoveField.safe && this.prevField) this.soundManager.playSound('safeField');
                if (endMoveField.finish) this.soundManager.playSound('tokenFinish');

                this.prevField = endMoveField;
                this.updateCurrentField(endMoveField);
                resolve();
            });
        });
    }

    moveFromHouse(path) {
        this.soundManager.playSound('tokenMove');

        return new Promise((resolve) => {
            const field = path[0];

            gsap.to(this, {
                x: field.x,
                y: field.y,
                duration: this._forceUpdate ? 0 : 0.2,
                onComplete: () => {
                    this.updateCurrentField(path[path.length - 1]);
                    resolve();
                }
            });
        });
    }

    moveTokenOneField(field, duration) {
        if (!field) return;
        if (this.config.team === PlayersPositions.BL) {
            this.hint.setVisible(false);
        }
        this.soundManager.playSound('tokenMove');

        gsap.to(this, {
            x: field.x,
            y: field.y,
            duration: this._forceUpdate ? 0 : duration / 1000,
            onComplete: () => {
                this.position.set(field.x, field.y);
            }
        });
    }

    async eaten(path) {
        const timeToReturnToHouseByOne = 50;
        this.soundManager.playSound('moveBack');
        await this.moveTo(path, this._forceUpdate ? 0 : timeToReturnToHouseByOne);
        await this.moveTokenToHouse();
        return path.length * timeToReturnToHouseByOne + 200;
    }

    // Token returns to the initial position in house, after it gets to its starting position
    moveTokenToHouse() {
        this.currentField = null;

        gsap.to(this, {
            x: this.config.x,
            y: this.config.y,
            duration: this._forceUpdate ? 0 : 0.2,
        })

        return new Promise((resolve) => {
            gsap.delayedCall(this._forceUpdate ? 0 : 0.2, () => {
                resolve();
            });
        });
    }

    updateCurrentField(field) {
        this.currentField = field;
    }

    destroy() {
        this.clickSurface.off('pointerdown', this.onSelectToken, this);
        this.token.destroy(true);
        this.tokenContainer.destroy(true);
        this.clickSurface.destroy(true);
        super.destroy();
    }

    fitTokensOnField(tokensLength = 1, tokenPosition = 0) {
        const scaleList = [1, 1, 0.75, 0.5, 0.4, 0.4, 0.4, 0.4, 0.3, 0.3, 0.3, 0.3, 0.3, 0.3, 0.3, 0.3, 0.3];

        const dX = [0, 50, 80, 110, 110, 110];
        const dY = [0, -10, 150, 150, 150];

        const rows = Math.ceil(tokensLength / 4);
        const row = Math.floor(tokenPosition / 4);

        const columnsValue = Math.ceil(tokensLength - (4 * row));
        const columns = (columnsValue > 4 ? 4 : columnsValue) - 1;
        const column = tokenPosition % 4;

        const findX = () => {
            if (columns === 0) { return 0; }
            return ((dX[columns] / columns) * column) - (dX[columns] / 2);
        };

        const findY = () => {
            if (rows === 0) { return 0; }
            return (dY[rows] / rows) * row - (dY[rows] / 2);
        };

        gsap.getTweensOf(this.tokenContainer).forEach(tween => {
            tween.pause(1);
            tween.kill();
        });

        /*gsap.getTweensOf(this.tokenContainer).forEach(tween => {
            tween.pause(1);
            tween.kill();
        });*/

        gsap.to(this.tokenContainer, {
            x: findX() + this.token.width / 2,
            y: findY() + this.token.height / 2,
            width: this.tokenWidth * scaleList[tokensLength],
            height: this.tokenHeight * scaleList[tokensLength],
            duration: 0.2,
        });
    }

    _getPath(fromIndex, toIndex) {
        const path = [];
        const max = 52;

        if (fromIndex > toIndex) {
            const length = max - fromIndex + toIndex + 1;
            for(let i = 1; i < length; i++) {
                const index = (fromIndex + i) % 52;

                const field = relativeFieldIndexToFindField(index, this.config.team);
                path.push(field);
            }

            return path;
        }

        for (let i = fromIndex + 1; i <= toIndex; i += 1) {
            if (i === 51 && this.isQuickMode && toIndex > 51) {
                continue;
            }

            const field = relativeFieldIndexToFindField(i, this.config.team);
            path.push(field);
        }
        return path;
    }

    _calcPathToReturnToHouse(lastRelativeIndex) {
        const path = [];
        for (let i = lastRelativeIndex - 1; i >= 0; i -= 1) {
            const field = relativeFieldIndexToFindField(i, this.config.team);
            path.push(field);
        }
        return path;
    }

    _findFieldByAbsoluteIndex(absoluteIndex) {
        if (absoluteIndex === -1) {
            return GameProperties.players.find(e => e.team === this.config.team && e.index === this.config.index);
        }

        if (typeof absoluteIndex === 'string') {
            return GameProperties.finish[absoluteIndex];
        }
        return GameProperties.fields[absoluteIndex];
    }

    setAbsolutePosition(val) {
        this.toAbsolutePosition = val;
        if (this.config.team === PlayersPositions.BL)
            this.model.absolutePositions$[this.config.index] = val;
    }

    isAllEqualTokens(availableTokens) {
        this.equalTokens = [];
        availableTokens.forEach(id => this.equalTokens[id] = this.model.absolutePositions$[id]);

        const positiveElement = this.equalTokens.find(n => n >= 0);
        return this.equalTokens.every((val) => val === positiveElement);
    }
}

export const HintStates = {
    NONE: 'NONE',
    KILL: 'KILL',
    SAFE: 'SAFE',
    WIN: 'WIN'
}
