import {Subject} from "rxjs";
import {Container, Sprite} from "pixi.js";

export const EnumSpriteButtonState = {
    DEFAULT: 0,
    OVER: 1,
    PRESSED: 2,
    DISABLED: 3,
}

export class SpriteButton extends Container {
    click$ = new Subject();
    pressed$ = new Subject();
    over$ = new Subject();
    out$ = new Subject();
    dblclick$ = new Subject();

    get width() {
        if (this.sprites.length === 0) {
            return 0;
        }
        return this.sprites[0].width;
    }

    get height() {
        if (this.sprites.length === 0) {
            return 0;
        }
        return this.sprites[0].height;
    }

    get anchor() {
        if (this.sprites.length === 0) {
            return null;
        }
        return this.sprites[0].anchor;
    }

    set anchor(value) {
        this.sprites.forEach(item => {
            item.anchor.set(value);
        })
    }

    set width(value) {
        this.sprites[0].width = value;
    }

    set height(value) {
        this.sprites[0].height = value;
    }

    constructor(textures) {
        super();
        if (!textures.disabled) textures.disabled = textures.default;
        this.initSprites(textures);
        this.addChild(...this.sprites);

        for (let i = 0; i < this.sprites.length; i++) {
            this.sprites[i].visible = i === 0;
        }

        this.eventMode = 'static';

        this.on('pointerdown', this.onDown, this);
        this.on('pointerover', this.onOver, this);
        this.on('pointerout', this.onOut, this);
        this.on('pointertap', this.onClick, this);
        window.addEventListener('pointerup', this.onUp.bind(this));

        this.cursor = 'pointer';
    }

    initSprites(textures) {
        this.sprites = [
            Sprite.from(textures.default), // 0
            Sprite.from(textures.over), // 1
            Sprite.from(textures.pressed), // 2
            Sprite.from(textures.disabled), // 3
        ];
    }

    get state() {
        return this._state;
    }

    set state(value) {
        this._state = value;
        this.gotoAndStop(value);
    }

    get disabled() {
        return this._disabled;
    }

    set disabled(disabled) {
        this._disabled = disabled;

        this.eventMode = disabled ? 'none' : 'static';

        this.state = disabled
          ? EnumSpriteButtonState.DISABLED
          : EnumSpriteButtonState.DEFAULT;
        this.gotoAndStop(
          disabled ? EnumSpriteButtonState.DISABLED : EnumSpriteButtonState.DEFAULT,
        );
    }

    gotoAndStop(index) {
        for (let i = 0; i < this.sprites.length; i++) {
            this.sprites[i].visible = i === index;
        }
    }

    onDown(event) {
        this.state = EnumSpriteButtonState.PRESSED;
        this.pressed$.next(event);
    }

    onUp(event) {
        if (this.state !== EnumSpriteButtonState.PRESSED) return;

        this.state = EnumSpriteButtonState.DEFAULT;
        this.click$.next(event);
    }

    onOver(event) {
        this.state = EnumSpriteButtonState.OVER;
        this.over$.next(event);
    }

    onOut(event) {
        this.state = EnumSpriteButtonState.DEFAULT;
        this.out$.next(event);
    }

    onClick(event) {
        switch (event.detail) {
            case 2:
                this.dblclick$.next(event);
                break;
        }
    }

    destroy(options) {
        this.off('pointerdown', this.onDown, this);
        this.off('pointerup', this.onUp, this);
        this.off('pointerover', this.onOver, this);
        this.off('pointerout', this.onOut, this);

        super.destroy(options);

        this.removeAllListeners();
    }
}
