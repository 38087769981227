import i18next from 'i18next';
import * as PIXI from 'pixi.js';
import { distinctUntilChanged } from "rxjs";
import { BaseModal } from './BaseModal';
import { capitalizeFirstLetter } from '../helpers/Utils';
import { getColorByName } from '../helpers/colorsHelper';


export class SendOfferToGiveUpModalSm extends BaseModal {
  constructor(rootContainer, model, resizeManager, soundManager, server) {
    super(rootContainer, model, resizeManager, soundManager);

    this.numberOfPlayers = model.gdh.numberOfPlayers;
    this.model = model;
    this.server = server;
  }

  createModal() {
    this.container = new PIXI.Container();

    // add surrender btn
    this.spinnerBg = this.container.addChild(
      PIXI.Sprite.from('surrenderBtnNormal')
    );
    this.spinnerBg.anchor.set(0.5, 0.5);
    this.spinnerBg.position.set(this.width / 2, 210);

    // add bg rectangle
    this.bg = this.container.addChild(
      new PIXI.NineSlicePlane(
        PIXI.Texture.from('surrenderModalBg'),
        100,
        100,
        100,
        100
      )
    );
    this.bg.autoResize = true;
    this.bg.height = 955; // 1220
    this.bg.position.set((this.originWidth - this.bg.width) / 2, 550);

    // add bg triangle
    const bgTriangle = this.container.addChild(
      PIXI.Sprite.from('surrenderModalBgTriangle')
    );
    bgTriangle.anchor.set(0.5, 1);
    bgTriangle.position.set(this.originWidth / 2, this.bg.y + 1);

    // add message
    this.message = new PIXI.Text('Send an offer to the player to surrender?', {
      fontFamily: 'Akshar-Medium',
      fontSize: '64px',
      fill: 0xffffff,
      wordWrap: true,
      wordWrapWidth: 700,
      align: 'center',
      lineHeight: 250,
      letterSpacing: 3,
    });
    this.message.anchor.set(0.5, 0);
    this.message.position.set(this.width / 2, 560);
    this.container.addChild(this.message);

    // add opponent name
    this.opponentName = new PIXI.Text('Player', {
      fontFamily: 'Akshar-Bold',
      fontSize: '75px',
      fill: 'green',
    });
    this.opponentName.anchor.set(0.5, 0);
    this.opponentName.position.set(this.width / 2, 760);
    this.container.addChild(this.opponentName);

    // add attempts text
    this.attemptsText = new PIXI.Text(
      `You have ${this.model.surrenderPropositionCount$.value} attempts available`,
      {
        fontFamily: "Akshar-Medium",
        fontSize: "55px",
        fill: 0xff6f6f,
        wordWrap: true,
        wordWrapWidth: 500,
        align: "center",
        lineHeight: 100,
        letterSpacing: 1,
      }
    );
    this.attemptsText.anchor.set(0.5, 1);
    this.attemptsText.position.set(540, 1260);
    this.container.addChild(this.attemptsText);

    this.model.surrenderPropositionCount$
      .pipe(distinctUntilChanged())
      .subscribe(value => {
      this.attemptsText.text = `You have ${value} attempts available`;
    });

    // add SEND button
    this.sendBtn = this.createBtn(
      this.container,
      i18next.t('modals.send'),
      () => this.onSend()
    );
    this.sendBtn.position.set(320, this.bg.y + this.bg.height - 150);

    // add CANCEL button
    this.cancelBtn = this.createBtn(
      this.container,
      i18next.t('modals.cancel'),
      () => {
        this.onCancel();
      }
    );
    this.cancelBtn.position.set(740, this.bg.y + this.bg.height - 150);

    this.addAnimationItems([
      this.spinnerBg,
      this.bg,
      bgTriangle,
      this.attemptsText,
      this.message,
      this.sendBtn,
      this.cancelBtn,
    ]);

    return this.container;
  }

  async onSend() {
    const opponents = await this.server.getRoomPlayers();
    const opponentsIds = opponents.map(item => item.sessionId);
    this.server.sendOfferToGiveUp$.next(opponentsIds);
    await this.onCancel();
  }

  async onCancel() {
    this.opponentName.visible = false;
    this.sendBtn.disabled = true;
    this.cancelBtn.disabled = true;
    await super.hide();
    this.model.sendOfferToGiveUpModalSm$.next(false);
  }

  async show() {
    const opponents = await this.server.getRoomPlayers();
    const opponentColor = opponents[0].color;
    this.opponentName.visible = true;
    this.opponentName.text = `${capitalizeFirstLetter(opponentColor)}`;
    this.opponentName.style.fill = getColorByName(opponentColor);

    super.show();

    this.sendBtn.disabled = false;
    this.cancelBtn.disabled = false;
  }

  hide() {
    super.hide();
    this.model.sendOfferToGiveUpModalSm$.next(false);
  }
}
