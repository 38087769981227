import * as PIXI from "pixi.js";

export class SceneManager extends PIXI.Container {
    constructor() {
        super();

        this.canvasWidth = 1080;

        this.pivot.set(this.canvasWidth / 2, 0);
        this.position.set(this.canvasWidth / 2, 0)

        this.map = new Map();
    }

    addScene(name, containerScene, active = false) {
        this.map.set(name, containerScene);
        this.addChild(containerScene);

        if (!active) {
            this.hide(name);
        }
    }

    has(name) {
        return this.map.has(name);
    }

    show(name) {
        if (!this.map.has(name)) {
            console.warn(`Show scene with name "${name}" not found!`);
            return;
        }

        const scene = this.map.get(name);
        scene.visible = true;
        scene.renderable = true;

        if (scene.hasOwnProperty('onShowScene')) {
            scene.onShowScene();
        }
    }

    hide(name) {
        if (!this.map.has(name)) {
            console.warn(`Hide scene with name "${name}" not found!`);
            return;
        }

        const scene = this.map.get(name);
        scene.visible = false;
        scene.renderable = false;

        if (scene.hasOwnProperty('onHideScene')) {
            scene.onHideScene();
        }
    }

    getContext(name) {
        if (!this.map.has(name)) {
            console.warn(`Get context scene with name "${name}" not found!`);
            return;
        }

        return this.map.get(name);
    }
}
