import * as PIXI from "pixi.js";
import i18next from 'i18next';
import {BaseModal} from "./BaseModal";

export class StartWarningModal extends BaseModal {
    constructor(rootContainer, model, resizeManager, soundManager) {
        super(rootContainer, model, resizeManager, soundManager);
        this.soundManager = soundManager;
    }

    createModal() {
        const textStyle = {
            fontFamily: "Akshar-Medium",
            fontSize: "50px",
            fill: "#FFFFFF",
            align: "center",
            stroke: "#481E71",
            strokeThickness: 12,
            wordWrapWidth: 900,
            wordWrap: true,
            letterSpacing: 5,
        }
        const container = new PIXI.Container();
        const bg = container.addChild(PIXI.Sprite.from('startWarningModal'));
        bg.pivot.set(bg.width / 2, bg.height / 2);
        bg.position.set(541, 969);

        const text = container.addChild(new PIXI.Text(`${i18next.t('modals.start_warning')}`, textStyle));
        text.anchor.set(0.5);
        text.position.set(540, 960);

        this.betValueText = container.addChild(new PIXI.Text(this.model.gdh.formatCurrency(this.model.gdh.currentBet), {
            ...textStyle,
            fontSize: '66px',
            fontFamily: "Akshar-Bold"
        }));
        this.betValueText.anchor.set(0.5);
        this.betValueText.position.set(540, 1080);
        this.betValueText.isNotScalable = true;

        const checkbox = container.addChild(this.addSkipCheckbox());
        checkbox.position.set(200, 1150);


        this.confirmButton = this.createBtn(container, 'modals.start_btn', () => this.onConfirm());
        this.confirmButton.position.set(530, 1370);

        // this.cancelButton = this.createBtn(container, 'modals.cancel', () => this.onExit());
        // this.cancelButton.position.set(519 + 200, 1475);

        const cancelText = container.addChild(new PIXI.Text(i18next.t('modals.start_warning_cancel'), {
            fontFamily: "Akshar-Medium",
            fontSize: "34px",
            fill: "#FFFFFF",
        }));
        cancelText.anchor.set(0.5);
        cancelText.position.set(545, 1550);
        cancelText.interactive = true;
        cancelText.on('pointerdown', () => {
            this.soundManager.playSound('click');
        });
        cancelText.on('pointerup', () => {
            this.onExit();
        });

        const underline = new PIXI.Graphics();
        container.addChild(underline);
        underline.lineStyle(2, 0xffffff)
            .moveTo(cancelText.x - cancelText.width/2, cancelText.y + cancelText.height/2 + 1)
            .lineTo(cancelText.x + cancelText.width/2, cancelText.y + cancelText.height/2 + 1);


        this.addAnimationItems([bg, text, checkbox, this.confirmButton, this.betValueText, cancelText, underline]);

        return container;
    }

    addSkipCheckbox() {
        const container = new PIXI.Container();
        container.eventMode = 'static';
        container.buttonMode = true;
        container.hitArea = new PIXI.Rectangle(0, 0, 700, 100);

        const circle = container.addChild(new PIXI.AnimatedSprite([
            PIXI.Texture.from('circle'),
            PIXI.Texture.from('circleSelected')
        ]))
        circle.gotoAndStop(this.model.skipStartMessage ? 1 : 0);
        circle.scale.set(0.7);
        circle.position.set(17, 4);

        const label = container.addChild(new PIXI.Text(i18next.t('modals.start_warning_label'), {
            fontFamily: "Akshar-Medium",
            fontSize: "45px",
            fill: "#FFFFFF",
            align: "left",
        }));
        label.anchor.set(0.5);
        label.position.set(356, 50);

        this.model.startWarningSkipValue$
            .subscribe(_ => {
                circle.gotoAndStop(this.model.skipStartMessage ? 1 : 0);
            });

        container.on('pointerdown', e => {
            this.soundManager.playSound('click');
            this.model.skipStartMessage = !this.model.skipStartMessage;
        });

        return container;
    }

    async onConfirm() {
        this.confirmButton.disabled = true;
        await this.hide();

        const object = this.model.startWarning$.getValue();
        if (object && typeof object.start === 'function') {
            object.start();
        }

        this.model.startWarning$.next(false);
    }

    async onExit() {
        // this.cancelButton.disabled = true;
        this.confirmButton.disabled = true;

        const object = this.model.startWarning$.getValue();
        if (object && typeof object.close === 'function') {
            object.close();
        }

        await this.hide();

        this.model.startWarning$.next(false);
    }

    show() {
        this.betValueText.text = `${this.model.gdh.formatCurrency(this.model.gdh.currentBet)}`;
        super.show();
        this.confirmButton.disabled = false;
        // this.cancelButton.disabled = false;
    }
}
