import * as PIXI from "pixi.js";
import {interval, take} from "rxjs";
import i18next from 'i18next';
import Constants from "../helpers/constants/Constants";
import {round} from "../../server/src/frameworks/colyseus/helpers/Utils";
import {EnumTopType, TopContainer} from "../objects/TopContainer";
import {SpriteButton} from "../objects/SpriteButton";
import {onButtonPressedAnimation} from "../helpers/buttonHelper";
import { EnumGameModes } from "../helpers/constants/GameModes";
import ConnectionService from "../services/ConnectionService";
import { LoadingCircle } from "../objects/LoadingCircle";

export class StartScene extends PIXI.Container {
    /**
     * @param server {Server}
     * @param model {Model}
     * @param soundManager {SoundManager}
     */
    constructor(server, model, soundManager) {
        super();

        // animation shift before select game mode
        this.position.x = 1080;

        this.server = server;
        this.model = model;
        this.soundManager = soundManager;

        this.model.gdh.currentGameState = Constants.GameState.START;
        let defaultGameMode = Object.keys(this.model.gdh.gameSettings.modes).find((key) => this.model.gdh.gameSettings.modes[key].default);
        if (!defaultGameMode) {
            defaultGameMode = Object.keys(this.model.gdh.gameSettings.modes)[0];
        }

        this.model.gdh.mode = defaultGameMode;
        this.model.gdh.multiplier = this.model.gdh.gameSettings.multiplier;
        this.model.selectedMode$.next(defaultGameMode);
        this.soundManager.musicOn = this.model.gdh.music;
        this.soundManager.playMusic();
        this.topContainer = this.addChild(new TopContainer(EnumTopType.START, model, soundManager, server));
        this.topContainer.position.set(0, 160);

        this.createSelectColor();

            /*this.selectDefaultMode = this.addChild(
                this.createModeBtn(
                    i18next.t("start_scene.mode_default"),
                    "classic",
                    this.model.gdh.gameSettings.modes.classic.active || false,
                    this.model.gdh.gameSettings.modes.classic.default,
                )
            );
            this.selectDefaultMode.position.set(
              this.model.gdh.gameSettings.modes.quick.default ? 117 : 340,
              867
            );

            this.selectQuickMode = this.addChild(
                this.createModeBtn(
                    i18next.t("start_scene.mode_quick"),
                     "quick",
                     this.model.gdh.gameSettings.modes.quick.active || false,
                     this.model.gdh.gameSettings.modes.quick.default
                    )
                );
            this.selectQuickMode.position.set(this.model.gdh.gameSettings.modes.classic.default ? 563 : 340, 867);

        this.model.gameModes$.subscribe(modes => {
            this.selectDefaultMode.visible = modes.classic.default;
            this.selectDefaultMode.toggleActivity(modes.classic.active);
            this.selectDefaultMode.position.set(
              modes.quick.default ? 117 : 340,
              867
            );
            this.selectQuickMode.visible = modes.quick.default;
            this.selectQuickMode.toggleActivity(modes.quick.active);
            this.selectQuickMode.position.set(
        modes.classic.default ? 563 : 340,
        867
            );
        })*/

        this.selectPlayersText = this.addChild(new PIXI.Text(i18next.t('start_scene.select_players'), {
            fontFamily: 'Mybahnschrift',
            fontSize: '40px',
            fontWeight: '700',
            fill: "#E6C300",
            align: "center",
        }));
        this.selectPlayersText.position.set(540, 1041);
        this.selectPlayersText.anchor.set(0.5);

        this.twoPlayersSelect = this.addChild(this.createSelectPlayers(2, this.model.gdh.gameSettings.players.includes(2)));
        this.twoPlayersSelect.position.set(239, 1081);

        this.fourPlayersSelect = this.addChild(
          this.createSelectPlayers(
            4,
            this.model.gdh.gameSettings.players.includes(4)
          )
        );
        this.fourPlayersSelect.position.set(239, 1195);

        this.model.availablePlayers$.subscribe(value => {
            this.twoPlayersSelect.toggleActivity(value.includes(2));
            this.fourPlayersSelect.toggleActivity(value.includes(4));

            if (this.model.gdh.numberOfPlayers === 2) {
                this.twoPlayersSelect.select();
                this.fourPlayersSelect.unselect();
            }

            if (this.model.gdh.numberOfPlayers === 4) {
                this.twoPlayersSelect.unselect();
                this.fourPlayersSelect.select();
            }
        });

        this.betPanel = this.addChild(this.createBetPanel());
        this.betPanel.position.set(272, 1367);

        this.createBackStartModeBtn();
        this.createStartBtn();
        this.createMenuBtn();

        this.decideSelected();
        this.updateBetText();
    }

    decideSelected() {
        const {numberOfPlayers, mode, selectedColor} = this.model.gdh;


        this.checkMarks.forEach((check) => {
            check.unselect();
            check.buttonMode = true;
            check.eventMode = 'static';
        });
        this.checkMarks.find((check) => {
            if (check.currentColor === selectedColor) {
                check.select();
                return true;
            }
        });

        this.model.selectedColor$.next(selectedColor);

        /*if (mode === 'classic') {
            if (this.selectDefaultMode) this.selectDefaultMode.select();
            if (this.selectQuickMode) this.selectQuickMode.unselect();
        } else {
            if (this.selectDefaultMode) this.selectDefaultMode.unselect();
            if (this.selectQuickMode) this.selectQuickMode.select();
        }*/

        // if (numberOfPlayers === 2) {
        //     this.twoPlayersSelect.select();
        //     this.fourPlayersSelect.unselect();
        // } else {
        //     this.twoPlayersSelect.unselect();
        //     this.fourPlayersSelect.select();
        // }
        if (numberOfPlayers === 2) {
          this.twoPlayersSelect.select();
          this.fourPlayersSelect.unselect();
        }
        if (numberOfPlayers === 4) {
          this.twoPlayersSelect.unselect();
          this.fourPlayersSelect.select();
        }
    }

    createSelectColor() {
        const container = this.addChild(new PIXI.Container());
        container.position.set(492, 740 + 50);

        const title = container.addChild(new PIXI.Text(i18next.t('start_scene.select_color'), {
            fontFamily: 'Mybahnschrift',
            fontSize: '40px',
            fontWeight: '700',
            fill: "#E6C300",
            align: "center",
        }));
        title.position.set(48, -105);
        title.anchor.set(0.5);

        const distanceX = 170;
        const positionsX = [distanceX * -1.53, distanceX * -0.51, distanceX * 0.51, distanceX * 1.53];

        const blueToken = container.addChild(PIXI.Sprite.from('token1'));
        blueToken.position.set(positionsX[0], -60);
        blueToken.scale.set(0.7);

        const redToken = container.addChild(PIXI.Sprite.from('token2'));
        redToken.position.set(positionsX[1], -60);
        redToken.scale.set(0.7);

        const yellowToken = container.addChild(PIXI.Sprite.from('token4'));
        yellowToken.position.set(positionsX[2], -60);
        yellowToken.scale.set(0.7);

        const greenToken = container.addChild(PIXI.Sprite.from('token3'));
        greenToken.position.set(positionsX[3], -60);
        greenToken.scale.set(0.7);

        const checkY = 80;
        const blueCheck = container.addChild(this.createRectMark(positionsX[0], checkY, 'blue'));
        const redCheck = container.addChild(this.createRectMark(positionsX[1], checkY, 'red'));
        const yellowCheck = container.addChild(this.createRectMark(positionsX[2], checkY, 'yellow'));
        const greenCheck = container.addChild(this.createRectMark(positionsX[3], checkY, 'green'));

        this.checkMarks = [
            blueCheck.check, redCheck.check, yellowCheck.check, greenCheck.check
        ];

        this.checkMarks.forEach((check) => {
            check.unselect();
        });

        this.checkMarks[0].select();
        this.model.gdh.selectedColor = this.checkMarks[0].currentColor;
        this.model.selectedColor$.next(this.model.gdh.selectedColor);
        this.currentCheckColor = this.model.gdh.selectedColor;
    }

    createRectMark(x, y, color) {
        const container = new PIXI.Container();
        container.position.set(x, y);

        const colorFirstUpper = color.charAt(0).toUpperCase() + color.slice(1);

        const checkBg = container.addChild(new PIXI.AnimatedSprite([
            PIXI.Texture.from('checkMarkEmptyBg'),
            PIXI.Texture.from(`checkMark${colorFirstUpper}Bg`),
        ]));
        checkBg.position.set(3, 28);
        checkBg.gotoAndStop(0);

        const check = container.addChild(PIXI.Sprite.from(`checkMark${colorFirstUpper}`));
        check.position.set(17, 52);

        checkBg.currentColor = color;
        checkBg.customColor = this.getColorHex(color);
        checkBg.eventMode = 'static';
        checkBg.buttonMode = true;

        checkBg.on('pointerdown', e => this.onCheckClick(checkBg));

        checkBg.select = () => {
            checkBg.gotoAndStop(1);
            check.visible = true;
        };

        checkBg.unselect = () => {
            checkBg.gotoAndStop(0);
            check.visible = false;
        }

        container.check = checkBg;
        return container;
    }

    onCheckClick(currentCheck) {
        this.checkMarks.forEach((check) => {
            check.unselect();
        });
        this.soundManager.playSound('click');
        currentCheck.select();
        this.currentCheckColor = currentCheck.currentColor;
        this.model.gdh.selectedColor = currentCheck.currentColor;

        this.model.selectedColor$.next(this.model.gdh.selectedColor);
    }

    getColorHex(color) {
        switch (color) {
            case 'red': return 0xFF4165;
            case 'blue': return 0x3C8CEA;
            case 'yellow': return 0xE4CA04;
            case 'green': return 0x3CEA84;
            default: return '';
        }
    }

    createModeBtn(textValue, mode, active = true, visible) {
        const container = new PIXI.Container();

        const bg = container.addChild(PIXI.Sprite.from(active ? 'modeSelectBg' : 'modeSelectBgDisabled'));
        bg.position.set(0, 0);

        const text = container.addChild(new PIXI.Text(textValue, {
            fontFamily: 'Akshar-Bold',
            fontSize: '45px',
            fill: active ? "#FFE600" : '#BBB',
            align: "center",
        }));
        text.position.set(234, 84);
        text.anchor.set(0.5);

        const circle = container.addChild(new PIXI.AnimatedSprite([
            PIXI.Texture.from('circleDisabled'),
            PIXI.Texture.from('circle'),
            PIXI.Texture.from('circleSelected')
        ]));
        circle.position.set(48, 42);
        circle.scale.set(0.7);

        circle.gotoAndStop(active ? 1 : 0);

        container.mode = mode;
        container.text = text;
        container.isActive = active;
        container.visible = visible;

        container.select = () => {
            circle.gotoAndStop(2);
            this.updateMinAndMaxText();
            this.updateBetText();
        }

        container.unselect = () => {
            circle.gotoAndStop(active ? 1 : 0);
        }

        if (active) {
            container.eventMode = 'static';
            container.buttonMode = true;

            container.on('pointerdown', e => this.onSelectMode(container), this);
        }

        container.toggleActivity = (value) => {
            if (value === container.isActive) return;

            if (value && !container.isActive) {
                container.isActive = true;
                container.eventMode = 'static';
                container.on(
                  "pointerdown",
                  (e) => this.onSelectMode(container),
                  this
                );
            }

            if (!value && container.isActive) {
                container.isActive = false;
                container.eventMode = 'none';

            }
        };

        return container;
    }

    onSelectMode(selectMode) {
        if (selectMode.mode === 'classic') {
            this.model.gdh.mode = 'classic';
            this.soundManager.playSound('click');
            this.model.selectedMode$.next('classic');
            this.decideSelected();
        } else if (selectMode.mode === 'quick') {
            this.model.gdh.mode = 'quick';
            this.soundManager.playSound('click');
            this.model.selectedMode$.next('quick');
            this.decideSelected();
        }

        this.server.model.clear();
    }

    createSelectPlayers(howMany = 2, isActive = false) {
        const container = new PIXI.Container();

        const text = container.addChild(new PIXI.Text(`${howMany} ${i18next.t('start_scene.players')}`, {
            fontFamily: 'Akshar-Bold',
            fontSize: '75px',
            fill: "#FFE600",
            align: "center",
        }));
        text.position.set(398, 45);
        text.anchor.set(0.5);

        const circle = container.addChild(new PIXI.AnimatedSprite([
            PIXI.Texture.from('circle'),
            PIXI.Texture.from('circleSelected'),
        ]));
        circle.gotoAndStop(0);
        circle.position.set(35, 2);
        circle.scale.set(0.7);

        container.numberOfPlayers = howMany;
        container.text = text;
        container.isActive = isActive;

        container.select = () => {
            circle.gotoAndStop(1);
            this.updateBetText();
        }

        container.unselect = () => {
            circle.gotoAndStop(0);
        }

        if (isActive) {
            container.eventMode = "static";
            container.buttonMode = true;
            container.alpha = 1;
            container.hitArea = new PIXI.Rectangle(0, 0, 600, 100);
            container.on("pointerdown", (e) => this.onPickNumberOfPlayers(container));
        } else {
            container.eventMode = 'none';
            container.alpha = 0.8;
        }

        container.toggleActivity = (value) => {
          if (value === container.isActive) return;

          if (value && !container.isActive) {
            container.isActive = true;
            container.eventMode = "static";
            container.alpha = 1;
            container.hitArea = new PIXI.Rectangle(0, 0, 600, 100);
            container.on("pointerdown", (e) =>
                this.onPickNumberOfPlayers(container)
            );
          }

          if (!value && container.isActive) {
            container.isActive = false;
            container.eventMode = "none";
            container.alpha = 0.8;
          }
        };


        return container;
    }

    onPickNumberOfPlayers(selectPlayers) {
        if (selectPlayers.numberOfPlayers === 2) {
          this.model.gdh.numberOfPlayers = 2;
          this.soundManager.playSound("click");
          this.decideSelected();
        } else if (selectPlayers.numberOfPlayers === 4) {
          this.model.gdh.numberOfPlayers = 4;
          this.soundManager.playSound("click");
          this.decideSelected();
        }
    }

    createBetPanel() {
        const container = new PIXI.Container();
        const bg = container.addChild(PIXI.Sprite.from('betPanel'));
        bg.position.set(-200, 10);

        const plus = container.addChild(new SpriteButton({
            default: 'betPlus',
            over: 'betPlusHover',
            pressed: 'betPlusPressed',
        }));
        plus.position.set(637, 145);
        plus.pivot.set(plus.width / 2, plus.height / 2);
        plus.click$.subscribe(_ => this.onChangeBet(plus, true));
        plus.pressed$.subscribe(_ => {
            this.soundManager.playSound('bitUp');
            onButtonPressedAnimation(plus);
        });

        const minus = container.addChild(new SpriteButton({
            default: 'betMinus',
            over: 'betMinusHover',
            pressed: 'betMinusPressed',
        }));
        minus.position.set(-100, 145);
        minus.pivot.set(plus.width / 2, plus.height / 2);
        minus.click$.subscribe(_ => this.onChangeBet(minus, false));
        minus.pressed$.subscribe(_ => {
            this.soundManager.playSound('bitDown');
            onButtonPressedAnimation(minus);
        });

        const winTitle = container.addChild(new PIXI.Text(i18next.t('start_scene.win'), {
            fontFamily: 'Mybahnschrift',
            fontSize: '60px',
            fill: "#E6C300",
            align: "center",
            fontWeight: "500",
        }));
        winTitle.anchor.set(0.5);
        winTitle.position.set(310, 60);

        const coins = container.addChild(PIXI.Sprite.from('coins'));
        coins.anchor.set(0.5);
        coins.position.set(195, 60);

        const textBetAmount = container.addChild(new PIXI.Text('', {
            fontFamily: 'Mybahnschrift',
            fontSize: '46px',
            fill: "#FFF27B",
            align: "center",
        }));
        textBetAmount.position.set(bg.width / 2 + bg.x, 250);
        textBetAmount.anchor.set(0.5);

        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        const fontWeight = isSafari ? "normal" : "bold";

        const textBetPossibleWin = container.addChild(new PIXI.Text('', {
            fontFamily: 'Mybahnschrift',
            fontSize: '48px',
            fill: "#FFD809",
            align: "center",
            fontWeight: fontWeight,
        }));
        textBetPossibleWin.position.set(bg.width / 2 + bg.x, 162);
        textBetPossibleWin.anchor.set(0.5);

        const textMaxBetAmount = container.addChild(new PIXI.Text('', {
            fontFamily: 'Mybahnschrift',
            fontSize: '30px',
            fill: "#FFF27B",
            align: "center",
        }));
        textMaxBetAmount.position.set(plus.x + plus.width / 2, textBetAmount.y);
        textMaxBetAmount.anchor.set(1, 0.5);

        const textMinBetAmount = container.addChild(new PIXI.Text('', {
            fontFamily: 'Mybahnschrift',
            fontSize: '30px',
            fill: "#FFF27B",
            align: "center",
        }));
        textMinBetAmount.position.set(minus.x - minus.width / 2, textBetAmount.y);
        textMinBetAmount.anchor.set(0, 0.5);

        container.plus = plus;
        container.minus = minus;
        container.textBetAmount = textBetAmount;
        container.textBetPossibleWin = textBetPossibleWin;
        container.textMaxBetAmount = textMaxBetAmount;
        container.textMinBetAmount = textMinBetAmount;

        return container;
    }

    onChangeBet(betBtn, isInc = true) {
        const canUpdate = isInc ? this.model.gdh.plusBet() : this.model.gdh.minusBet();
        if (!canUpdate) {
            betBtn.disabled = true;
            return;
        }

        this.updateBetText();

        if (this.server.clientIdGA) {
            ConnectionService._tryToSendAction({
                action: isInc ? "plusBet" : "minusBet",
                clientId : this.server.clientIdGA,
                data: {
                    currentBet: this.model.gdh.currentBet
                }
            });
        }
    }

    updateBetText() {
        this.betPanel.textBetAmount.text = `${i18next.t('start_scene.entry')}: ${this.model.gdh.formatCurrency(this.model.gdh.currentBet, this.model.gdh.currency)}`
        this.model.gdh.updatePossibleWin();
        this.betPanel.textBetPossibleWin.text = this.model.gdh.formatCurrency(this.model.gdh.possibleWin, this.model.gdh.currency);

        this.betPanel.plus.disabled = !this.model.gdh.canPlusBet();
        this.betPanel.plus.alpha = this.model.gdh.canPlusBet() ? 1 : 0.7;
        this.betPanel.minus.disabled = !this.model.gdh.canMinusBet();
        this.betPanel.minus.alpha = this.model.gdh.canMinusBet() ? 1 : 0.7;
    }

    updateMinAndMaxText() {
        this.betPanel.textMinBetAmount.text = `${i18next.t('start_scene.min')}: ${this.model.gdh.formatCurrency(this.model.gdh.bets[0], this.model.gdh.currency)}`
        this.betPanel.textMaxBetAmount.text = `${i18next.t('start_scene.max')}: ${this.model.gdh.formatCurrency(this.model.gdh.bets[this.model.gdh.bets.length - 1], this.model.gdh.currency)}`
    }

    createBackStartModeBtn() {
        this.backButton = this.addChild(new SpriteButton({
            default: 'back_btn',
            over: 'back_btn_hover',
            pressed: 'back_btn_pressed'
        }));
        this.backButton.pivot.set(this.backButton.width / 2, this.backButton.height / 2);
        this.backButton.position.set(119, 1804);

        this.backButton.click$.subscribe(_ => this.model.startScene$.next("start_mode"));

        this.backButton.pressed$.subscribe(_ => {
            this.soundManager.playSound('click');
            onButtonPressedAnimation(this.backButton);
        });
    }

    createStartBtn() {
        this.playButton = this.addChild(new SpriteButton({
            default: 'playButton',
            over: 'playButtonHover',
            pressed: 'playButtonPressed'
        }))
        this.playButton.pivot.set(this.playButton.width / 2, this.playButton.height / 2);
        this.playButton.position.set(540, 1805);

        this.playButton.click$.subscribe(_ => {
            if (this.model.activeMatchMaking$.value === false) {
                this.playButton.disabled = true;
                this.onClickPlay();
            }
        });
        this.playButton.pressed$.subscribe(_ => {
            this.soundManager.playSound('click');
            onButtonPressedAnimation(this.playButton)
        });

        this.model.activeMatchMaking$.subscribe((value) => {
            if (value === false) {
                this.playButton.disabled = false;
            } else {
                this.playButton.disabled = true;
            }
        });

        const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        const fontWeight = isSafari ? "normal" : "800";

        const text = this.playButton.addChild(new PIXI.Text(i18next.t('start_scene.play_button'), {
            fontFamily: 'Mybahnschrift',
            fontSize: '64px',
            fontWeight: fontWeight,
            fill: "#FFD809",
            align: "center",
            dropShadow: true,
            dropShadowColor: '#000000',
            dropShadowAngle: 0.6,
            dropShadowDistance: 7,
        }));
        text.position.set(102, 51);

        this.checkRoundLoading = this.addChild(new LoadingCircle(200));
        this.checkRoundLoading.scale.set(0.5);
        this.checkRoundLoading.position.set(539, 1810);
    }

    async onClickPlay() {
        if (this.model.gdh.gameSettings.maintenance) {
            this.server.model.maintenanceModal$.next(true);
            return;
        }

        this.checkRoundLoading.toggleLoading(true);
        await ConnectionService._checkRound(this.model, this.model.gdh.urlParams.get("user")).then(res => {
            if (!res.data) {
                this.model.sessionLost$.next(true);
                this.playButton.disabled = false;
                return;
            }

            this.checkRoundLoading.toggleLoading(false);
            const round = res.data.round;
            if (round) {
                this.model.restoreModal$.next({
                    start: () => {
                        ConnectionService._connectRound(this.model, round.roundId).then(res => {
                            this.playButton.disabled = false;
                            if (!res || !res.data || !res.data.room) return;

                            const room = res.data.room;
                            this.server.restoreRound(room, round.roundId);
                            this.onNextClickPlay(true);
                        });
                    },
                    close: () => {
                        ConnectionService._closeRound(this.model, round.roundId).then(() => {
                            this.onClickPlay();
                            //this.server.clearRound();
                            //this.play();
                            this.playButton.disabled = false;
                        });
                    }
                });
            } else {
                this.server.clearRound();
                this.play();
            }
        });
    }

    play() {
        if (round(this.model.gdh.balance - this.model.gdh.currentBet, 10) < 0) {
            this.model.insufficientFunds$.next({
                close: this.playButton.disabled = false
            });
            return;
        }

        if (this.model.skipStartMessage) {
            this.onNextClickPlay();
            this.playButton.disabled = false;
            return;
        }

        this.model.startWarning$.next({
            start: () => this.onNextClickPlay(),
            close: () => this.playButton.disabled = false
        });
    }

    async onNextClickPlay(isRestore = false) {
        if (this.model.gameRoomStarted$.getValue()) return;

        if (!isRestore) this.model.gdh.selectedColor = this.currentCheckColor;

        this.model.gameIsStarted$.next(true);
        this.model.clear();

        interval(1000)
            .pipe(take(1))
            .subscribe(_ => {
                this.playButton.disabled = false;
            })

        if (this.model.mode$.value === EnumGameModes.MULTI) {
            this.model.activeMatchMaking$.next(true);
            this.model.gameRoomStarted$.next(true);
            this.server.destroyRoom();
            await this.server.joinToMultiRoom();
            this.model.serverGameStarted$.next(true)
            return;
        }

        await this.server.startPlay(isRestore);
        this.model.gameRoomStarted$.next(true);

        /*if (this.model.gameRoomStarted$.value === true) {
            this.model.playAgain$.next({toEnd: true});
        } else {
            this.server.startPlay(isRestore);
            this.model.gameRoomStarted$.next(true);
        }*/
    }

    createMenuBtn() {
        this.menuButton = this.addChild(new SpriteButton({
            default: 'menuButton',
            over: 'menuButtonHover',
            pressed: 'menuButtonPressed'
        }));
        this.menuButton.pivot.set(this.menuButton.width / 2, this.menuButton.height / 2);
        this.menuButton.position.set(963, 1804);
        this.menuButton.pressed$.subscribe(_ => {
            this.soundManager.playSound('click');
            onButtonPressedAnimation(this.menuButton)
        });
        this.menuButton.click$.subscribe(_ => this.model.openMenu$.next('RULES'));
    }
}
