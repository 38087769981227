import i18next from 'i18next';
import { round } from './Utils';
import GameUtils from "../utils/GameUtils";
import Constants from "./constants/Constants";
import { BehaviorSubject } from 'rxjs';

const state = {
    urlParams: null,
    bets: null,
    currentBetIndex: -1,
    currency: 'DEFAULT',
    numberOfPlayers: 2,
    sound: false,
    music: false,
    hints: false,
    possibleWin: 0,
    selectedColor: null,
    avatarIndex: null,
    usernameHidden: false,
    team: null,
    username: 'Your name',
    currentGameState: null,
    isAutomaticRollActive: false,
    balance$: new BehaviorSubject(0),
    roomId: null,
    sessionId: null,
    mode: 'quick',
    multiplier: 0,
    gameTimerValue: 0,
    isCashoutBlockUser: false,
    cashout: 0,
    gameSettings: null
};

export class GameDataHandler {
    constructor() {

    }

    get gameSettings() {
        return state.gameSettings;
    }

    set gameSettings(settings) {
        state.gameSettings = settings;
    }

    get roomId() {
        return state.roomId;
    }

    set roomId(id) {
        state.roomId = id;
    }

    get sessionId() {
        return state.sessionId;
    }

    set sessionId(id) {
        state.sessionId = id;
    }

    get cashout() {
        return state.cashout;
    }

    set cashout(value) {
        state.cashout = value;
    }

    get numberOfPlayers() {
        return state.numberOfPlayers;
    }

    set numberOfPlayers(n) {
        state.numberOfPlayers = n;
    }

    get bets() {
        return state.bets;
    }

    set bets(bets) {
        state.bets = bets ? bets : Constants.Values.DEFAULT_BETS;
        if (state.currentBetIndex < 0) state.currentBetIndex = Math.floor(state.bets.length / 2);
    }

    get currentBet() {
        if (!state.bets) return 0;
        return state.bets[state.currentBetIndex];
    }

    set currentBet(curentBet) {
        state.currentBetIndex = state.bets.indexOf(curentBet);
        if (state.currentBetIndex === -1) state.currentBetIndex = 0;
    }

    get currentBetIndex() {
        return state.currentBetIndex;
    }

    set currentBetIndex(currentBetIndex) {
        state.currentBetIndex = currentBetIndex;
    }

    get currency() {
        return state.currency;
    }

    set currency(currency) {
        state.currency = currency ? currency : Constants.Values.DEFAULT_CURRENCY;
    }

    set possibleWin(pw) {
        state.possibleWin = pw;
    }

    get possibleWin() {
        return state.possibleWin;
    }

    get balance() {
        return state.balance$.value;
    }

    set balance(balance) {
        state.balance$.next(balance);

        // set possible bet for player's balance
        if (this.balance < this.bets[state.currentBetIndex]) {
            for (let i = state.currentBetIndex - 1; i >= 0; i--) {
                state.currentBetIndex = i;
                if (this.balance >= this.bets[i]) break;
            }
        }
    }

    get balanceObs$() {
        return state.balance$;
    }

    get mode() {
        return state.mode;
    }

    set mode (mode) {
        state.mode = mode;
    }

    get multiplier() {
        return state.multiplier;
    }

    set multiplier(multiplier) {
        state.multiplier = multiplier;
    }

    updatePossibleWin() {
        this.possibleWin = round(this.currentBet * this.multiplier * this.numberOfPlayers, 10);
    }

    canPlusBet() {
        return !(this.currentBetIndex >= this.bets.length - 1);
    }

    plusBet() {
        if (this.currentBetIndex >= this.bets.length - 1) {
            return false;
        } else {
            this.currentBetIndex += 1;
        }
        this.updatePossibleWin();
        return true;
    }

    canMinusBet() {
        return !(this.currentBetIndex <= 0);
    }

    minusBet() {
        if (this.currentBetIndex <= 0) {
            return false;
        } else {
            this.currentBetIndex -= 1;
        }
        this.updatePossibleWin();
        return true;
    }

    get urlParams() {
        return state.urlParams;
    }

    set urlParams(params) {
        state.urlParams = params;
    }

    parseUrlParams() {
        this.urlParams = new URLSearchParams(window.location.search);
    }

    set sound(s) {
        state.sound = s;
    }

    get sound() {
        return state.sound;
    }

    set music(s) {
        state.music = s;
    }

    get music() {
        return state.music;
    }

    set hints(s) {
        state.hints = s;
        GameUtils.setCookie(Constants.Cookies.COOKIE_HINTS_VISIBLE, (s ? 1 : 0).toString(), {secure: true, 'max-age': 31536000});
    }

    get hints() {
        return state.hints;
    }

    set selectedColor(color) {
        state.selectedColor = color;
    }

    get selectedColor() {
        return state.selectedColor;
    }

    formatCurrency(amount, currencyCode) {
        currencyCode = currencyCode || this.currency;

        const toFixed = Number(amount) > Math.floor(Number(amount)) ? 2 : 0;

        try {
            amount = (Number(amount) || 0).toFixed(toFixed);
        } catch (e) {
            amount = (0).toFixed(toFixed);
        }

        switch (currencyCode) {
            case 'EUR':
                return `€${this.numberWithCommas(amount)}`;
            case 'INR':
                return `₹${this.numberWithCommas(amount)}`;
            case 'TMT':
                return `${this.numberWithCommas(amount)}m`;
            case 'TRY':
                return `₺${this.numberWithCommas(amount)}`;
            case 'MXN':
                return `$${this.numberWithCommas(amount)}`;
            case 'THB':
                return `฿${this.numberWithCommas(amount)}`;
            case 'BTN':
                return `Nu.${this.numberWithCommas(amount)}`;
            case 'KZT':
                return `${this.numberWithCommas(amount)}₸`;
            case 'CLP':
            case 'XXX':
                return `$${this.numberWithCommas(amount)}`;
            case 'ILS':
                return `₪${this.numberWithCommas(amount)}`;
            case 'BRL':
                return `${this.numberWithCommas(amount)}R$`;
            default:
                return `${this.numberWithCommas(amount)} ${currencyCode}`;
        }
    }

    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    getFormattedName(username = state.username, usernameHidden = state.usernameHidden) {
        if (usernameHidden) return i18next.t('common.hidden');
        if (username.length > 0) {
            return username.length > 10 ? `${username.slice(0, 10)}...` : username;
        }
        return i18next.t('common.default_name');
    }

    get username() {
        return state.username;
    }

    set username(value) {
        state.username = value;
        if (this.urlParams.get('debug')) {
            console.log("set username = " + value);
        }
    }

    get avatarIndex() {
        return state.avatarIndex;
    }

    set avatarIndex(value) {
        state.avatarIndex = value;
    }

    get usernameHidden() {
        return state.usernameHidden;
    }

    set usernameHidden(value) {
        state.usernameHidden = value;
    }

    get team() {
        return state.team;
    }

    set team(value) {
        state.team = value;
    }

    set currentGameState(value) {
        state.currentGameState = value;
    }

    get currentGameState() {
        return state.currentGameState;
    }

    set isAutomaticRollActive(value) {
        state.isAutomaticRollActive = value;
    }

    get isAutomaticRollActive() {
        return state.isAutomaticRollActive;
    }

    get gameTimerValue() {
        return state.gameTimerValue;
    }

    set gameTimerValue(value) {
        state.gameTimerValue = value;
    }

    get isCashoutBlockUser() {
        return state.isCashoutBlockUser;
    }

    set isCashoutBlockUser(value) {
        state.isCashoutBlockUser = value;
    }
}
