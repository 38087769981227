import { Assets } from 'pixi.js';
import {Howl} from 'howler';

export class SoundManager {
    constructor() {
        this._sound = true;
        this._music = true;

        this._loopMusicName = 'bgMusic';

        this._sounds = new Map();
    }

    async init() {
        const audioList = Assets.get("../assets/manifest.json").bundles.find(e => e.name === 'audio').assets;
        await Promise.all(audioList.map(({ name, srcs }) => this.loadSound(name, srcs)));
        this.soundList = Array.from(this._sounds.keys()).filter(e => e !== this._loopMusicName);
    }

    loadSound(name, src) {
        return new Promise((resolve, reject) => {
            const sound = new Howl({
                src,
                autoplay: false,
                preload: true,
                volume: 1,
                loop: name === this._loopMusicName,
                onload: () => {
                    resolve();
                },
                onloaderror: (id, error) => {
                    reject(error);
                }
            });

            this._sounds.set(name, sound);
        });
    }

    get soundOn() {
        return this._sound;
    }

    set soundOn(value) {
        this._sound = value;

        this.soundList.forEach( name =>  {
            const item = this._sounds.get(name);
            if (!item) { return;}
            item.mute(!value);
        });
    }

    get musicOn() {
        return this._music;
    }

    set musicOn(value) {
        this._music = value;

        const item = this._sounds.get(this._loopMusicName);
        if (!item) { return;}
        item.mute(!value);
    }

    playSound(sound) {
        const item = this._sounds.get(sound);
        if (item) {
            item.play();
        }
    }

    stopAllSounds() {
        this.soundList.forEach( name =>  {
            const item = this._sounds.get(name);
            if (!item) { return;}
            item.stop();
        });
    }

    playMusic() {
        const item = this._sounds.get(this._loopMusicName);
        if (item) {
            item.loop = true;
            item.volume(0.5);
            item.play();
        }
    }

    stopMusic() {
        const item = this._sounds.get(this._loopMusicName);
        if (item) {
            item.stop();
        }
    }

    toggleSound() {
        this.soundOn = !this._sound
    }

    toggleMusic() {
        this.musicOn = !this._music;
    }
}
