import * as PIXI from "pixi.js";
import i18next from 'i18next';
import {BaseModal} from "./BaseModal";

export class CriticalModal extends BaseModal {
    /**
     * @param rootContainer {SceneManager}
     * @param model {Model}
     * @param resizeManager {ResizeManager}
     * @param soundManager {SoundManager}
     */
    constructor(rootContainer, model, resizeManager, soundManager) {
        super(rootContainer, model, resizeManager, soundManager);
    }

    createModal() {
        const container = new PIXI.Container();
        const bg = container.addChild(PIXI.Sprite.from('criticalModal'));
        bg.pivot.set(bg.width / 2, bg.height / 2);
        bg.position.set(541, 970);

        const text = container.addChild(new PIXI.Text(i18next.t('modals.critical_error'), {
            fontFamily: "Akshar-Medium",
            fontSize: "60px",
            fill: "#FFDB15",
            align: "center",
            stroke: "#490000",
            strokeThickness: 13,
            wordWrapWidth: 900,
            wordWrap: true,
        }));
        text.anchor.set(0.5);
        text.position.set(545, 1130);

        this.btn = this.createBtn(container, 'modals.close', () => this.onExit());
        this.btn.position.set(this.width / 2, 1361);

        this.addAnimationItems([bg, text, this.btn]);

        return container;
    }

    async onExit() {
        this.btn.disabled = true;
        await this.hide();

        this.model.clear();
        this.model.leaveGameAfterCrush$.next(undefined);
        this.model.hideAllModals$.next(true);
        this.model.criticalModal$.next(false);
    }

    show() {
        super.show();
        this.btn.disabled = false;
    }
}
