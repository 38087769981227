import { NineSlicePlane, Texture } from 'pixi.js';
import {SpriteButton } from './SpriteButton';

export class SpriteButtonNineSlice extends SpriteButton {
  initTitle(title) {
    this.title = title;
  }

  initSprites(textures) {
    const sprite_default = new NineSlicePlane(
      textures.default ? Texture.from(textures.default) : Texture.EMPTY,
      23,
      23,
      23,
      23,
    );
    const sprite_over = new NineSlicePlane(
      textures.over ? Texture.from(textures.over) : Texture.EMPTY,
      23,
      23,
      23,
      23,
    );

    const sprite_pressed = new NineSlicePlane(
      textures.pressed ? Texture.from(textures.pressed) : Texture.EMPTY,
      23,
      23,
      23,
      23,
    );
    const sprite_disabled = new NineSlicePlane(
      textures.disabled ? Texture.from(textures.disabled) : Texture.EMPTY,
      23,
      23,
      23,
      23,
    );

    this.sprites = [
      sprite_default, // 0
      sprite_over, // 1
      sprite_pressed, // 2
      sprite_disabled, // 3
    ];
  }

  initWidth(width) {
    this.sprites.forEach((sprite) => {
      sprite.width = width;
      sprite.pivot.set(0, 0);
    });

    if (this.title) {
      this.title.x = this.width / 2;
    }
  }
}

