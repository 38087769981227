import {BaseModal} from "./BaseModal";
import {gsap} from "gsap";
import {EnumGameModes} from "../helpers/constants/GameModes";

export class BaseEndGameModal extends BaseModal {
  async onPlayAgainDo() {
    this.model.serverGameEnded$.next(undefined);

    gsap.delayedCall(0.32, () => {
      this.model.clear(true);

      switch (this.model.mode$.value) {
        case EnumGameModes.SINGLE:
          this.model.playAgain$.next({toEnd: false});
          break;

        case EnumGameModes.MULTI:
          (async () => {
            // TODO: Need to refactor rejoining room for multiplier
            this.model.playAgain$.next({toEnd: false});
            this.model.activeMatchMaking$.next(true);
            this.server.destroyRoom();
            await this.server.joinToMultiRoom();
            this.model.gameRoomStarted$.next(true);
            this.model.serverGameStarted$.next(true)
          })();
          break;
      }
    });
  }
}
