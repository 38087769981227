import * as PIXI from "pixi.js";
import i18next from 'i18next';
import {interval} from "rxjs";
import {map} from "rxjs/operators";
import {gsap} from "gsap";
import {BaseModal} from "./BaseModal";

export class SessionInterruptedModal extends BaseModal {
    constructor(rootContainer, model, resizeManager, soundManager) {
        super(rootContainer, model, resizeManager, soundManager);

        this.timer$ = undefined;
    }

    createModal() {
        const container = new PIXI.Container();
        const bg = container.addChild(PIXI.Sprite.from('sessionInterruptedModalBg'));
        bg.pivot.set(bg.width / 2, bg.height / 2);
        bg.position.set(543, 1016);

        const text = container.addChild(new PIXI.Text(i18next.t('modals.connecting'), {
            fontFamily: "Akshar-Medium",
            fontSize: "68px",
            fill: "#FFDB15",
            align: "center",
            wordWrapWidth: 900,
            wordWrap: true,
            stroke: "#490000",
            strokeThickness: 8
        }));
        text.anchor.set(0.5);
        text.position.set(545, 1082);

        const loadingContainer = container.addChild(new PIXI.Container());
        loadingContainer.position.set(546, 925);

        this.loading = [
            this.addLoadingItem(loadingContainer, -120, 290),
            this.addLoadingItem(loadingContainer, -70, 290),
            this.addLoadingItem(loadingContainer, -20, 290),
            this.addLoadingItem(loadingContainer, 30, 290),
            this.addLoadingItem(loadingContainer, 80, 290),
        ];

        this.btn = this.createBtn(container, 'modals.exit', () => this.onExit());
        this.btn.position.set(519, 1393);

        this.addAnimationItems([bg, text, this.btn]);

        return container;
    }

    async onExit() {
        this.btn.disabled = true;
        await this.hide();

        this.model.clear();
        this.model.leaveGameAfterCrush$.next(undefined);
        this.model.destroyRoom$.next(true);
    }

    addLoadingItem(container, x, y) {
        const image = container.addChild(PIXI.Sprite.from('sessionInterruptedLoading'));
        image.position.set(x, y);
        image.alpha = 0;
        return image;
    }

    show() {
        super.show();
        this.btn.disabled = false;

        this.timer$ = interval(1000)
            .pipe(map(e => e % 6))
            .subscribe(value => {
                if (value === 5) {
                    this.loading.map(e => e.alpha = 0);
                    return;
                }

                this.loading
                    .filter((e, i) => i <= value && e && e.alpha === 0)
                    .map(e => {
                        gsap.getTweensOf(e).forEach(tween => {
                            tween.pause(1);
                            tween.kill();
                        });

                        gsap.to(e, {
                            alpha: 1,
                            duration: 0.1,
                        });
                    });
            });
    }

    hide() {
        this.timer$.unsubscribe();
        this.model.reconnecting$.next(false);
        return super.hide();
    }
}
