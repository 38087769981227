import * as PIXI from "pixi.js";
import { gsap } from "gsap";
import { BehaviorSubject, distinctUntilChanged, map, switchMap, timer } from "rxjs";

export class LoadingCircle extends PIXI.Container {
    
    /**
     * Loading circle object
     * @param {number} delayBeforeLoading in milliseconds
     */
    constructor(delayBeforeLoading) {
        super();

        this._loadingShow$ = new BehaviorSubject(false);

        this._loadingImage = this.addChild(PIXI.Sprite.from('loaderSimple'));
        this._loadingImage.pivot.set(this._loadingImage.width / 2, this._loadingImage.height / 2);

        this._lastLoopTween = null;

        this._loadingShow$.pipe(
            distinctUntilChanged(),
            switchMap((value) => {
                // Emit the event after a delay of delayBeforeLoading ms
                return timer(value ? delayBeforeLoading : 0).pipe(map(() => value));
            })
        ).subscribe((value) => {
            this.visible = value;
  
            if (!value) {
                this._stopAnimation();
                return;
            }
  
            if (this._lastLoopTween) { return; }
            this._lastLoopTween = gsap.fromTo(this._loadingImage, {rotation: 0}, { rotation: Math.PI * 2, duration: 2, repeat: -1, ease: 'none'});
        });

        this.visible = false;
    }

    _stopAnimation() {
        this._lastLoopTween?.kill(this._loadingImage);
        this._lastLoopTween = null;
    }

    toggleLoading(visibility) {
        this._loadingShow$.next(visibility);
    }

    destroy(options) {
        this._stopAnimation();
        super.destroy(options);
    }
}