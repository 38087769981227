import * as PIXI from "pixi.js";
import {EnumLayouts} from "../helpers/constants/EnumLayouts";

export class BgScene extends PIXI.Container {
    /**
     * @param model {Model}
     * @param resizeManager {ResizeManager}
     * @param sceneManager {SceneManager}
     */
    constructor(sceneManager, model, resizeManager) {
        super();

        this.model = model;
        this.sceneManager = sceneManager;
        this.resizeManager = resizeManager;
        this.canvasWidth = 1080;
        this.canvasHeight = 1920;

        this.addBg();
        this.addLogo();
    }

    addBg() {
        this.bg = this.addChild(PIXI.Sprite.from('bgPreloader1'));
        this.originalWidth = this.bg.width;
        this.originalHight = this.bg.height;

        this.bg.scale.set(this.canvasWidth / this.originalWidth, this.canvasHeight / this.originalHight);

        this.resizeManager.resize$
            .subscribe(({ scale, shiftY }) => {
                this.bg.y = -(shiftY / scale);

                const dW = Math.round(this.canvasWidth / scale);
                const dH = Math.round(this.canvasHeight / scale);

                const scaleX = dW / this.originalWidth;
                const scaleY = dH / this.originalHight;

                this.bg.scale.set(scaleX, scaleY);
            });
    }

    addLogo() {
        const logo = this.addChild(PIXI.Sprite.from('topLogo'));
        logo.pivot.set(logo.width / 2, logo.height / 2);
        logo.visible = false;

        logo.position.set(this.canvasWidth / 2, 200);

        this.resizeManager.resize$
            .subscribe(({ scale, shiftY }) => {
                if (!this.sceneManager.getContext(EnumLayouts.LOADER) || this.sceneManager.getContext(EnumLayouts.LOADER).visible) return;
                logo.visible = true;
                //this.logo.visible = scale < 0.95;
                logo.y = -((shiftY / scale) / 2) + 150;
                if (scale < 0.71) {
                    logo.scale.set(scale * shiftY / 600);
                } else if (scale < 0.8) {
                    logo.scale.set(2.6 * (1 - scale));
                } else if (scale > 0.8) {
                    logo.scale.set(0.5);
                }
            });
    }
}
