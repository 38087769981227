import en from './en.json';

export default {
    en: {
        translation: {
            ...en,
        },
    }
};

// import i18next from 'i18next';
// i18next.t('modals.ok'),