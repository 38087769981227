import i18next from "i18next";
import * as PIXI from 'pixi.js';
import { BaseModal } from "./BaseModal";


export class BalanceErrorModal extends BaseModal {
  constructor(rootContainer, model, resizeManager, soundManager) {
    super(rootContainer, model, resizeManager, soundManager);
  }

  createModal() {
    const container = new PIXI.Container();
    const bg = container.addChild(PIXI.Sprite.from("criticalModal"));
    bg.pivot.set(bg.width / 2, bg.height / 2);
    bg.position.set(541, 970);

    const text = container.addChild(
      new PIXI.Text(i18next.t("modals.balance_error"), {
        fontFamily: "Akshar-Medium",
        fontSize: "58px",
        fill: "#FFDB15",
        align: "center",
        stroke: "#490000",
        strokeThickness: 13,
        wordWrapWidth: 900,
        wordWrap: true,
      })
    );
    text.anchor.set(0.5);
    text.position.set(545, 1140);

    this.btn = this.createBtn(container, "modals.close", () => this.onExit());
    this.btn.position.set(519, 1355);

    this.addAnimationItems([bg, text, this.btn]);

    return container;
  }

  async onExit() {
    this.btn.disabled = true;
    await this.hide();

    this.model.clear();
    this.model.leaveGameAfterCrush$.next(undefined);
    this.model.balanceErrorModal$.next(false);
  }

  show() {
    super.show();
    this.btn.disabled = false;
  }
}