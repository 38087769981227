import i18next from "i18next";
import * as PIXI from "pixi.js";
import { distinctUntilChanged } from "rxjs";
import { BaseModal } from "./BaseModal";
import { getColorByName } from "../helpers/colorsHelper";
import { capitalizeFirstLetter } from '../helpers/Utils';


export class SendOfferToGiveUpModalLg extends BaseModal {
  constructor(rootContainer, model, resizeManager, soundManager, server) {
    super(rootContainer, model, resizeManager, soundManager);

    this.model = model;
    this.server = server;
    this.opponentsList = [];
    this.checkedOpponents = [];
  }

  createModal() {
    this.container = new PIXI.Container();

    // add surrender btn
    this.spinnerBg = this.container.addChild(
      PIXI.Sprite.from("surrenderBtnNormal")
    );
    this.spinnerBg.anchor.set(0.5, 0.5);
    this.spinnerBg.position.set(this.width / 2, 210);

    // add bg rectangle
    this.bg = this.container.addChild(
      new PIXI.NineSlicePlane(
        PIXI.Texture.from("surrenderModalBg"),
        100,
        100,
        100,
        100
      )
    );
    this.bg.autoResize = true;
    this.bg.height = 1220;
    this.bg.position.set((this.originWidth - this.bg.width) / 2, 550);

    // add bg triangle
    const bgTriangle = this.container.addChild(
      PIXI.Sprite.from("surrenderModalBgTriangle")
    );
    bgTriangle.anchor.set(0.5, 1);
    bgTriangle.position.set(this.originWidth / 2, this.bg.y + 1);

    // add message
    this.message = new PIXI.Text("Choose players name to offer to surrender:", {
      fontFamily: "Akshar-Medium",
      fontSize: "64px",
      fill: 0xffffff,
      wordWrap: true,
      wordWrapWidth: 960,
      align: "center",
      lineHeight: 100,
      letterSpacing: 3,
    });
    this.message.anchor.set(0.5);
    this.message.position.set(540, 700);
    this.container.addChild(this.message);

    // add opponents bg
    this.opponentsBg = this.container.addChild(
      PIXI.Sprite.from("surrenderListBg")
    );
    this.opponentsBg.anchor.set(0.5, 0);
    this.opponentsBg.position.set(540, 900);
    this.container.addChild(this.opponentsBg);

    // add attempts text
    this.attemptsText = new PIXI.Text(`You have ${this.model.surrenderPropositionCount$.value} attempts available`, {
      fontFamily: "Akshar-Medium",
      fontSize: "55px",
      fill: 0xff6f6f,
      wordWrap: true,
      wordWrapWidth: 500,
      align: "center",
      lineHeight: 100,
      letterSpacing: 1,
    });
    this.attemptsText.anchor.set(0.5, 1);
    this.attemptsText.position.set(540, 1535);
    this.container.addChild(this.attemptsText);

    this.model.surrenderPropositionCount$
      .pipe(distinctUntilChanged())
      .subscribe((value) => {
        this.attemptsText.text = `You have ${value} attempts available`;
      });

    // add SEND button
    this.sendBtn = this.createBtn(
      this.container,
      i18next.t("modals.send"),
      () => this.onSend()
    );
    this.sendBtn.position.set(320, this.bg.y + this.bg.height - 150);

    // add CANCEL button
    this.cancelBtn = this.createBtn(
      this.container,
      i18next.t("modals.cancel"),
      () => {
        this.onCancel();
      }
    );
    this.cancelBtn.position.set(740, this.bg.y + this.bg.height - 150);

    this.addAnimationItems([
      this.spinnerBg,
      this.bg,
      bgTriangle,
      this.message,
      this.opponentsBg,
      this.attemptsText,
      this.sendBtn,
      this.cancelBtn,
    ]);

    return this.container;
  }

  async show() {
    const opponents = await this.server.getRoomPlayers();
    this.createOpponentsList(opponents);

    super.show();

    this.sendBtn.disabled = false;
    this.cancelBtn.disabled = false;
  }

  async onSend() {
    this.server.sendOfferToGiveUp$.next(this.checkedOpponents);
    await this.onCancel();
  }

  async onCancel() {
    this.opponentsList.forEach((item) => {
      this.container.removeChild(item);
    });
    this.opponentsList = [];

    await super.hide();
    this.model.sendOfferToGiveUpModalLg$.next(false);
  }

  createOpponentsList(list) {
    list.forEach((player, index) => {
      const container = new PIXI.Container();
      container.eventMode = "static";
      container.selected = false;

      const circle = container.addChild(
        new PIXI.AnimatedSprite([
          PIXI.Texture.from("circle"),
          PIXI.Texture.from("circleSelected"),
        ])
      );
      circle.gotoAndStop(0);
      circle.position.set(0, 0);
      circle.scale.set(0.6);

      const name = container.addChild(
        new PIXI.Text(`Player ${capitalizeFirstLetter(player.color)}`, {
          fontFamily: "Akshar-Medium",
          fontSize: "60px",
          fill: getColorByName(player.color),
          lineHeight: circle.height,
        })
      );
      name.position.set(140, 0);

      container.position.set(310, 950 + index * 115);
      container.playerId = player.sessionId;

      container.hitArea = new PIXI.Rectangle(
        0,
        0,
        140 + name.width,
        circle.height
      );

      container.on('pointerdown', () => {
        circle.gotoAndStop(container.selected ? 0 : 1);
        container.selected = !container.selected;
        if (container.selected) {
          this.checkedOpponents.push(container.playerId);
        } else {
          const index = this.checkedOpponents.indexOf(container.playerId);
          this.checkedOpponents.splice(index, 1);
        }
      });

      this.opponentsList.push(container);
      this.container.addChild(container);
    });

  }
}
