import {Room} from "colyseus.js";
import EventEmitter from "eventemitter3";
import {uuidGenerate} from "../helpers/uuid";

const EVENTS_TYPE = {
    RECEIVED: 'R',
    EVENTS: 'E',
    PING: 'I',
    PONG: 'O',
};

const EVENTS_TYPE_MESSAGES = {
    PROXY_EVENTS: '__PE__',
};

export class EventsProxy extends EventEmitter {
    constructor() {
        super();

        this.__queueAvailable = true;
        /**
         * @type {Map<string, {name: string, message: any}>}
         * @private
         */
        this.__mapQueueMessages = new Map();
        /**
         * @type {Map<string, number>}
         * @private
         */
        this._mapUUIDTimeout = new Map();

        /**
         * @type {Room}
         * @private
         */
        this.__room = undefined;

        this.isConnected = false;
        this.availableReconnect = true;

        this._queueInterval = setInterval(() => {
            if (!this.__queueAvailable) {
                return;
            }
            this._nextSend()
        }, 3000);

        this._enablePingPong = false;
        this._pingPongTimeout = undefined;
        this._pingPongLocalTimeout = undefined;
        this._pingPongServerAvailable = true;
    }

    /**
     * When on join to the room
     * @param room {Room}
     */
    onJoinRoom(room) {
        this.__room = room;
        this.__room.onMessage(EVENTS_TYPE_MESSAGES.PROXY_EVENTS, this._onServerMessage.bind(this));

        this.enableRingPong();
    }

    enableRingPong() {
        if (this._enablePingPong) {
            return;
        }

        this._enablePingPong = true;
        this._runPingPongTimer();
    }

    /**
     * Broadcast event to server
     * @param name {string}
     * @param [message] message {any | undefined}
     */
    send(name, message) {
        const uuid = uuidGenerate();

        this.__mapQueueMessages.forEach((value, key) => {
            if (value.name === name) {
                this.__mapQueueMessages.delete(key);
                this._mapUUIDTimeout.delete(key);
            }
        });

        this.__mapQueueMessages.set(uuid, {
            name,
            message
        });

        this._nextSend();
    }

    /**
     * Subscribe to server messages
     * @param name
     * @param callback
     */
    onMessage(name, callback) {
        this.on(name, callback);
    }

    /**
     * Remove all listeners and variables
     * @param event
     * @return {EventsProxy}
     */
    removeAllListeners(event) {
        super.removeAllListeners(event);

        this._pingPongTimeout && clearTimeout(this._pingPongTimeout);
        this._enablePingPong = false;
        this.__room = undefined;
        this.__mapQueueMessages.clear();
        this._queueInterval && clearInterval(this._queueInterval);

        return this;
    }

    clearTimers() {
        this._runPingPongTimer();
    }

    clearMessages() {
        this.__mapQueueMessages.clear();
        this._mapUUIDTimeout.clear();
    }

    _onServerMessage(rawMessage) {
        const {type, messages = []} = rawMessage;
        if (!Array.isArray(messages)) {
            console.error(`Message from server to proxy is not Array! ${JSON.stringify(messages)}`);
            return;
        }

        switch (type) {
            case EVENTS_TYPE.RECEIVED:
                for (let i = 0; i < messages.length; i++) {
                    this.__mapQueueMessages.delete(messages[i]);
                    this._mapUUIDTimeout.delete(messages[i]);
                }
                break;

            case EVENTS_TYPE.PONG:
                this._pingPongServerAvailable = true;
                this._runPingPongTimer();
                break;

            case EVENTS_TYPE.EVENTS:
                const uuids = messages.map(e => e.uuid);
                this.__room.send(EVENTS_TYPE_MESSAGES.PROXY_EVENTS, {type: EVENTS_TYPE.RECEIVED, messages: uuids});

                for (let i = 0; i < messages.length; i++) {
                    const {name, message} = messages[i];
                    this.emit(name, message);
                }
                break;

            default:
                console.error(`Not found type "${type}", message: ${JSON.stringify(messages)}`);
                break;
        }
    }

    _nextSend() {
        this.__queueAvailable = false;
        const time = +new Date();

        const messages = Array.from(this.__mapQueueMessages.entries())
            .map(([uuid, data]) => ({...data, uuid}))
            .filter(item => this._filterByTimeout(item.uuid, time));

        if (this.__room && this.isConnected && this._pingPongServerAvailable) {
            this.__room.send(EVENTS_TYPE_MESSAGES.PROXY_EVENTS, {type: EVENTS_TYPE.EVENTS, messages})
        }

        this.__queueAvailable = true;
    }

    _sendPingPongToServer() {
        this._pingPongLocalTimeout && clearTimeout(this._pingPongLocalTimeout);
        this._pingPongLocalTimeout = setTimeout(() => {
            this._pingPongServerAvailable = false;

            if (this.availableReconnect) {
                this.emit('RECONNECT');
                console.warn(`Server is not ping-pong available!`);
            }

            this._runPingPongTimer();
        }, 10000);

        this.__room.send(EVENTS_TYPE_MESSAGES.PROXY_EVENTS, {type: EVENTS_TYPE.PING});
    }

    _runPingPongTimer() {
        this._pingPongLocalTimeout && clearTimeout(this._pingPongLocalTimeout);
        this._pingPongTimeout && clearTimeout(this._pingPongTimeout);
        this._pingPongTimeout = setTimeout(() => this._sendPingPongToServer(), 5000);
    }

    stopPingPong() {
        clearTimeout(this._pingPongTimeout);
        this._pingPongTimeout = undefined;

        clearTimeout(this._pingPongLocalTimeout);
        this._pingPongLocalTimeout = undefined;
    }

    _filterByTimeout(uuid, time) {
        const value = this._mapUUIDTimeout.get(uuid);
        if (value === undefined) {
            this._mapUUIDTimeout.set(uuid, time);
            return true;
        }

        return (time - value) > 2000;
    }
}
