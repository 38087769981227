import * as PIXI from "pixi.js";
import i18next from 'i18next';
import {filter, interval} from "rxjs";
import {delay, distinctUntilChanged} from "rxjs/operators";
import {gsap} from "gsap";
import {EnumTopType, TopContainer} from "../objects/TopContainer";
import {SpriteButton} from "../objects/SpriteButton";
import {onButtonPressedAnimation} from "../helpers/buttonHelper";
import {MenuButton} from "../objects/MenuButton";

export class MatchMaking extends PIXI.Container {
    constructor(model, soundManager, server) {
        super();

        this.model = model;
        this.soundManager = soundManager;
        this.server = server;
        this.topContainer = this.addChild(new TopContainer(EnumTopType.MATCH, this.model, this.soundManager, this.server));

        this.addDecoCutters();
        const currentPlayer = this.addChild(this.addPlayerIcon());
        currentPlayer.position.set(464, 768 + 100);

        this.opponentsContainer = this.addChild(new PIXI.Container());
        this.opponentsContainer.position.set(0, 1172);

        this.addText();
        this.exitBtn = this.addExitBtn();
        this.addTimer();
        this.addMenuBtn();

        interval(500)
            .pipe(filter(_ => this.model.activeMatchMaking$.value === true))
            .subscribe(async _ => {
                const players = await this.server.getRoomPlayers();
                this.updatedOpponents(players);
            });

        this.model.activeMatchMaking$
            .subscribe(() => {
                this.timer.visible = false;
                currentPlayer.setAvatarIndex(this.model.gdh.avatarIndex, this.model.gdh.selectedColor);
                const stake = `${this.model.gdh.formatCurrency(this.model.gdh.possibleWin, this.model.gdh.currency)}`;
                this.topContainer.setStake(stake);
                this.exitBtn.visible = true;
            });

        this.model.activeMatchMaking$
            .pipe(distinctUntilChanged(), filter(e => !e))
            .subscribe(_ => this.updatedOpponents([]));

        this.model.avatarSaved$
            .subscribe(data => {
                currentPlayer.setAvatarIndex(data, this.model.gdh.selectedColor);
            });
    }

    addDecoCutters() {
        const topLine = this.addChild(PIXI.Sprite.from('decoCutter'));
        topLine.scale.y = -1;
        topLine.position.set(152, 689 + 140);

        const matchVS = this.addChild(PIXI.Sprite.from('matchVS'));
        matchVS.position.set(465, 1001 + 100);

        const bottomLine = this.addChild(PIXI.Sprite.from('decoCutter'));
        bottomLine.position.set(154, 1512);
    }

    addPlayerIcon() {
        const container = new PIXI.Container();
        // TODO: need refactor
        const avatar = container.addChild(new PIXI.AnimatedSprite([
            PIXI.Texture.from('avatarRoundedRed'),
            PIXI.Texture.from('avatarRoundedBlue'),
            PIXI.Texture.from('avatarRoundedGreen'),
            PIXI.Texture.from('avatarRoundedYellow'),
        ]));
        avatar.gotoAndStop(0);

        const avatarImage = container.addChild(new PIXI.AnimatedSprite([
            PIXI.Texture.from('avatarEmptyWithoutBg'),
            PIXI.Texture.from('yourPrefAvatar1'),
            PIXI.Texture.from('yourPrefAvatar2'),
            PIXI.Texture.from('yourPrefAvatar3'),
            PIXI.Texture.from('yourPrefAvatar4'),
            PIXI.Texture.from('yourPrefAvatar5'),
            PIXI.Texture.from('yourPrefAvatar6'),
        ]));
        avatarImage.gotoAndStop(5);

        container.setAvatarIndex = (avatarIndex, color) => {
            const colorIndex = {'red': 0, 'blue': 1, 'green': 2, 'yellow': 3};
            avatar.gotoAndStop(colorIndex[color]);

            if (typeof avatarIndex === 'number') {
                if (avatarIndex > 5) {
                    avatarIndex = 5;
                }

                avatarImage.gotoAndStop(avatarIndex + 1);
                avatarImage.position.set(8, 10);
                avatarImage.scale.set(0.95);
            } else {
                avatarImage.gotoAndStop(0);
                avatarImage.position.set(23, 33);
                avatarImage.scale.set(0.46);
            }
        }

        return container;
    }

    /**
     * @param list {{username: string, avatarId: number, color: string, sessionId: string}[]}
     */
    updatedOpponents(list = []) {
        this.opponentsContainer.removeChildren();

        const xLength = {
            1: [464],
            2: [256, 670],
            3: [110, 464, 816],
        }

        for (let i = 0; i < list.length; i++) {
            const opponent = this.opponentsContainer.addChild(this.addPlayerIcon());
            opponent.position.set(xLength[list.length][i], 0);

            const { avatarId, color } = list[i];
            opponent.setAvatarIndex(avatarId, color);
        }
    }

    addText() {
        const text = this.addChild(new PIXI.Text(i18next.t('waiting_scene.searching_for_players'), {
            fontFamily: 'Mybahnschrift',
            fontSize: '48px',
            fontWeight: '700',
            fill: "#E6C300",
            align: "center",
        }));
        text.position.set(292, 1409);
    }

    addExitBtn() {
        const exit = this.addChild(new SpriteButton({
            default: 'exitButton',
            over: 'exitButtonHover',
            pressed: 'exitButtonPressed',
        }));
        exit.pivot.set(exit.width / 2, exit.height / 2);
        exit.position.set(121, 1800);
        exit.pressed$.subscribe(_ => {
            this.soundManager.playSound('click');
            onButtonPressedAnimation(exit);
        });
        exit.click$.subscribe(_ => {
            if (this.model.activeMatchMaking$.value === true) {
                this.onExit();
            }
        });
        this.model.activeMatchMaking$
            .pipe(delay(1500))
            .subscribe((value) => {
            if (value === true) {
                console.log('UNLOCK');
                exit.disabled = false;
            } else {
                exit.disabled = true;
            }
        });
        return exit;
    }

    async onExit() {
        this.model.activeMatchMaking$.next('skip');
        this.model.serverGameStarted$.next(false);
        this.model.gameIsStarted$.next(false);
        this.model.clear();
        this.model.leaveGame$.next({isLeaved: true, isCashout: false});
        this.model.leaveGameModal$.next(false);

        await new Promise(resolve => {
            gsap.delayedCall(1, resolve);
        });

        await this.server.join();

        this.model.activeMatchMaking$.next(false);
    }

    addTimer() {
        this.timer = this.addChild(new PIXI.Text('00:00', {
            fontFamily: 'Mybahnschrift',
            fontSize: '96px',
            fontWeight: '100',
            fill: "#FFFFFF",
            align: "center",
            stroke: "#1F042C",
            strokeThickness: 10,
        }));
        this.timer.position.set(421, 1739);

        this.model.timerPlayerMulti$
            .pipe(filter(_ => this.model.activeMatchMaking$.value === true))
            .subscribe(data => {
                this.timer.visible = data.available;
                this.exitBtn.visible = !data.available;
                this.timer.text = `00:0${data.time}`;
            })
    }

    addMenuBtn() {
        this.addChild(new MenuButton(this.model, this.soundManager));
    }
}
