import {gsap} from "gsap";

/**
 * Animate button pressed
 * @param btn
 */
export const onButtonPressedAnimation = (btn) => {
    gsap.getTweensOf(btn).forEach(tween => {
        tween.pause(1);
        tween.kill();
    });

    const timeline = gsap.timeline({ yoyo: false })
        .to(btn, {
            width: btn.width * 0.95,
            height: btn.height * 0.95,
            duration: 0.1,
            ease: "power2.out"
        })
        .to(btn, {
            width: btn.width,
            height: btn.height,
            duration: 0.1,
            ease: "power2.out"
        })
        .call(() => {
            timeline.kill();
        })
}
