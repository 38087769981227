export const EnumLayouts = {
    LOBBY_BG: 'lobby_bg',
    MAIN_BG: 'main_bg',
    LOADER: 'loader',
    FADE_IN_OUT: 'fade_in_out',
    START_MODE: 'start_mode',
    START: 'start',
    MODALS: 'modals',
    MENU: 'menu',
    GAME: 'game',
    MATCH_MAKING: 'matchMaking',
    UI: 'ui',
    PREFERENCES: 'preferences',
}
