import * as PIXI from 'pixi.js';
import {gsap} from "gsap";

export class VerticalScroll extends PIXI.Container {
    constructor(width, height) {
        super();

        this.originalWidth = width;
        this.originalHeight = height;

        this.eventMode = 'static';
        this.buttonMode = true;
        this.hitArea = new PIXI.Rectangle(0, 0, width + 100, height);

        const mask = new PIXI.Graphics();
        mask.beginFill(0x000000,1);
        mask.drawRect(0, 0, this.originalWidth, this.originalHeight);
        mask.endFill();

        this.contentContainer = this.addChild(new PIXI.Container());
        this.contentContainer.mask = mask;
        this.contentContainer.addChild(mask);

        this.content = this.contentContainer.addChild(new PIXI.Container());

        const bigButton = new PIXI.Graphics();
        bigButton.beginFill(0x3a1549);
        bigButton.drawRect(0, 0, width, height);
        bigButton.endFill();
        this.addChild(bigButton);
        bigButton.alpha = 0;
        bigButton.eventMode = 'static';

        this.scrollContainer = this.addChild(new PIXI.Container());
        this.scrollContainer.position.set(911, 0);

        const scrollBg = new PIXI.Graphics();
        scrollBg.beginFill(0x3a1549);
        scrollBg.drawRoundedRect(0, 0, 20, this.originalHeight, 10);
        scrollBg.endFill();
        this.scrollContainer.addChild(scrollBg);

        this.scrollbar = this.scrollContainer.addChild(new PIXI.Graphics());
        this.scrollbar.beginFill(0xffffff);
        this.scrollbar.drawRoundedRect(0, 0, 20, 400, 10);
        this.scrollbar.endFill();
        this.scrollbar.eventMode = 'static';

        bigButton.on('pointerdown', this.onPointerDown, this);
        this.on('pointermove', this.onPointerMove, this);
        window.addEventListener('touchend', () => this.onPointerUp());
        window.addEventListener('mouseup', () => this.onPointerUp());
        window.addEventListener('wheel', (event) => this.onScroll(event));
        this.scrollbar.on('pointerdown', this.onDragStart, this);

        this.content.y = 0;
        this.startY = 0;
        this.previousDiffY = 0;
        this.dragging = false;

        this.tweenInertia = gsap.to(this.content, {x: 0, duration: 0, paused: true});

        this.scrollbar.y = 0;
    }

    onDragStart(event) {
        this.dragPoint = event.data.getLocalPosition(this.parent);
        this.data = event.data;
        this.dragPoint.y -= this.scrollbar.y;
        this.isScrollbarDragging = true;
        this.scrollbar.alpha = 0.8;
    }

    onActivate() {
        this.visible = true;
        this.content.y = 0;
        this.updateScroll();
        this.updateScrollbarPosition();
        this.active = true;
    }

    onDisactivate() {
        this.visible = false;
        this.active = false;
    }

    updateScroll() {
        const { height } = this.content.getBounds();
        this.contentHeight = height + 50;
    }

    onPointerDown(event) {
        if (!this.active) { return; }

        this.clearInertia();

        this.dragging = true;
        this.startY = event.data.global.y;
        this.scrollbar.alpha = 0.8;
    }

    onPointerMove(event) {
        if (!this.active) { return; }

        if (this.dragging) {
            const delta = event.data.global.y - this.startY;
            this.content.y += delta;
            this.previousDiffY = event.data.global.y - this.startY;
            this.startY = event.data.global.y;
            this.clampContent();
            this.updateScrollbarPosition();
        }

        if (this.isScrollbarDragging) {
            const newPosition = this.data.getLocalPosition(this.parent);
            const deltaY = (newPosition.y - this.dragPoint.y) - this.scrollbar.y;
            const maxY = this.scrollContainer.height - this.scrollbar.height;

            const mainFullMoveY = this.contentHeight - this.originalHeight;
            const scrollFullMoveY = this.originalHeight - this.scrollbar.height;

            if (this.scrollbar.y <= 0 && deltaY < 0) return;
            if (this.scrollbar.y >= maxY && deltaY > 0) return;

            this.scrollbar.y = newPosition.y - this.dragPoint.y;
            if (this.scrollbar.y < 0) this.scrollbar.y = 0;
            if (this.scrollbar.y > maxY) this.scrollbar.y = maxY;

            this.content.y = -this.scrollbar.y * mainFullMoveY / scrollFullMoveY;

            this.previousDiffY = deltaY - this.startY;
            this.startY = this.content.y;
            this.clampContent();
        }
    }

    onPointerUp() {
        if (!this.active) { return; }

        if (this.dragging) {
            this.tweenInertia = gsap.to(this.content, {
                y: this.content.y + (this.previousDiffY * 10),
                duration: 0.5,
                onUpdate: () => {
                    this.updateScrollbarPosition();
                    this.clampContent()
                }
            });
        }

        this.dragging = false;
        this.isScrollbarDragging = false;
        this.scrollbar.alpha = 1;

        this.clearInertia();
    }

    onScroll(event) {
        if (!this.active) { return; }

        this.content.y -= event.deltaY;
        this.previousDiffY = event.deltaY - this.startY;
        this.startY = this.content.y;
        this.clampContent();
        this.updateScrollbarPosition();
    }

    clampContent() {
        const minY = this.originalHeight - this.contentHeight;
        if (this.content.y > 0) {
            this.content.y = 0;
            this.clearInertia();
        } else if (this.content.y < minY) {
            this.content.y = minY;
            this.clearInertia();
        }
    }

    updateScrollbarPosition() {
        const mainFullMoveY = this.contentHeight - this.originalHeight;
        const scrollFullMoveY = this.originalHeight - 400;

        this.scrollbar.y = -(scrollFullMoveY * this.content.y) / mainFullMoveY;
    }

    clearInertia() {
        if (this.tweenInertia && this.tweenInertia.isActive()) {
            this.tweenInertia.paused();
            this.tweenInertia.kill();
            this.tweenInertia = undefined;
        }
    }
}
