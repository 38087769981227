import * as PIXI from "pixi.js";
import i18next from "i18next";
import GameUtils from "../utils/GameUtils";
import Constants from "../helpers/constants/Constants";

export class MenuSettings extends PIXI.Container {
    /**
     * @param model {Model}
     * @param server {Server}
     * @param soundManager {SoundManager}
     */
    constructor(model, server, soundManager) {
        super();

        this.model = model;
        this.server = server;
        this.soundManager = soundManager;

        this.addTopBar();

        this.addText('menu.sound_and_music', 599);

        const sound = this.addToggleBtn(['soundOn', 'soundOff'], () => {
            this.soundManager.toggleSound();
            this.model.gdh.sound = this.soundManager.soundOn;
            sound.setEnabled(this.model.gdh.sound);
            this.server.updatePreserenses$.next(undefined);
        });
        sound.position.set(266, 689);

        const music = this.addToggleBtn(['musicOn', 'musicOff'], () => {
            this.soundManager.toggleMusic();
            this.model.gdh.music = this.soundManager.musicOn;
            music.setEnabled(this.model.gdh.music);
            this.server.updatePreserenses$.next(undefined);
        });
        music.position.set(266, 879);

        this.addText('menu.hints', 1069);

        const hints = this.addToggleBtn(['hintsOn', 'hintsOff'], () => {
            this.model.gdh.hints = !this.model.gdh.hints;
            hints.setEnabled(this.model.gdh.hints);
            this.model.updateHintsSettings$.next(undefined);
        });
        hints.position.set(266, 1159);

        this.model.playerPreferences$
            .subscribe(_ => {
                if (this.soundManager.soundOn !== this.model.gdh.sound) {
                    this.soundManager.toggleSound();
                }

                if (this.soundManager.musicOn !== this.model.gdh.music) {
                    this.soundManager.toggleMusic();
                }

                sound.setEnabled(this.model.gdh.sound);
                music.setEnabled(this.model.gdh.music);

                let cookieHintsVisible = +GameUtils.getCookie(Constants.Cookies.COOKIE_HINTS_VISIBLE);
                if (cookieHintsVisible === undefined) {
                    cookieHintsVisible = 1;
                }
                this.model.gdh.hints = cookieHintsVisible !== 0;
                hints.setEnabled(this.model.gdh.hints);
            });
    }

    addTopBar() {
        const topBarText = this.addChild(new PIXI.Text(i18next.t('menu.sound_settings'), {
            fontFamily: "Mybahnschrift",
            fontSize: "64px",
            fill: "#ffffff",
            align: "center",
        }));
        topBarText.anchor.set(0.5);
        topBarText.position.set(538, 76);
    }

    addText(text, _y) {
        const textField = this.addChild(new PIXI.Text(i18next.t(text), {
            fontFamily: "Mybahnschrift",
            fontSize: "40px",
            fill: "#FFD809",
            wordWrap: true,
            wordWrapWidth: 1200
        }));
        textField.anchor.set(0.5, 0);
        textField.position.set(545, _y);
    }

    addToggleBtn(textures = [], clickFn = () => {}) {
        const container = this.addChild(new PIXI.Container());
        const bg = container.addChild(PIXI.Sprite.from('soundBg'));
        bg.eventMode = 'static';
        bg.buttonMode = true;
        bg.on('pointerdown', (event) => {
            event.stopPropagation();
            event.preventDefault();
            this.soundManager.playSound('click');
            clickFn();
        });

        const iconActive = container.addChild(PIXI.Sprite.from(textures[0]));
        iconActive.pivot.set(iconActive.width / 2, iconActive.height / 2);
        iconActive.scale.set(1.24);
        iconActive.position.set(105, 79);

        if (textures[0] === 'musicOn') {
            iconActive.position.set(90, 72);
        } else if (textures[0] === 'hintsOn') {
            iconActive.position.set(95, 75);
            iconActive.scale.set(1);
        }

        const iconDisable = container.addChild(PIXI.Sprite.from(textures[1]));
        iconDisable.scale.set(1.24);
        iconDisable.pivot.set(iconDisable.width / 2, iconDisable.height / 2);
        iconDisable.position.set(109, 92);
        iconDisable.visible = false;

        if (textures[1] === 'hintsOff') {
            iconDisable.scale.set(1);
        }

        const switcher = container.addChild(new PIXI.AnimatedSprite([
            PIXI.Texture.from('switchOn'),
            PIXI.Texture.from('switchOff'),
        ]));
        switcher.gotoAndStop(0);
        switcher.pivot.set(switcher.width / 2, switcher.height / 2);
        switcher.scale.set(1.34);
        switcher.position.set(441, 77);

        container.switcher = switcher;

        container.setEnabled = (value) => {
            iconActive.visible = !!value;
            iconDisable.visible = !value;
            switcher.gotoAndStop(value ? 0 : 1);
        };

        return container;
    }
}
