import * as PIXI from "pixi.js";
import i18next from 'i18next';
import {BaseModal} from "./BaseModal";

export class SessionLostModal extends BaseModal {
    constructor(rootContainer, model, resizeManager, soundManager) {
        super(rootContainer, model, resizeManager, soundManager);
    }

    createModal() {
        const container = new PIXI.Container();
        const bg = container.addChild(PIXI.Sprite.from('sessionLostModal'));
        bg.pivot.set(bg.width / 2, bg.height / 2);
        bg.position.set(541, 971);

        const text = container.addChild(new PIXI.Text(i18next.t('modals.session_lost'), {
            fontFamily: "Akshar-Medium",
            fontSize: "48px",
            fill: "#FFFFFF",
            align: "center",
            wordWrapWidth: 900,
            wordWrap: true,
        }));
        text.anchor.set(0.5);
        text.position.set(545, 1119);

        this.btn = this.createBtn(container, 'modals.exit', () => this.onExit());
        this.btn.position.set(519, 1414);

        this.addAnimationItems([bg, text, this.btn]);

        return container;
    }

    async onExit() {
        this.btn.disabled = true;
        await this.hide();

        this.model.clear();
        this.model.leaveGameAfterCrush$.next(undefined);
        this.model.sessionLost$.next(false);
        this.model.destroyRoom$.next(true);
    }

    show() {
        super.show();
        this.btn.disabled = false;
    }
}
