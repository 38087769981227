import GameProperties, {PlayersPositions} from "../data/GameProperties";

const startFieldIndexes = {
    [PlayersPositions.TL]: 0,
    [PlayersPositions.TR]: 13,
    [PlayersPositions.BR]: 26,
    [PlayersPositions.BL]: 39
};

const relativeToAbsolute = [
    {team: PlayersPositions.TL, relative: -1, absolute: -1},
    {team: PlayersPositions.TR, relative: -1, absolute: -1},
    {team: PlayersPositions.BR, relative: -1, absolute: -1},
    {team: PlayersPositions.BL, relative: -1, absolute: -1}
];

// tokens base config
for (let i = 0; i < 52; i++) {
    relativeToAbsolute.push({team: PlayersPositions.TL, relative: i, absolute: (startFieldIndexes[PlayersPositions.TL] + i) % 52})
    relativeToAbsolute.push({team: PlayersPositions.TR, relative: i, absolute: (startFieldIndexes[PlayersPositions.TR] + i) % 52})
    relativeToAbsolute.push({team: PlayersPositions.BR, relative: i, absolute: (startFieldIndexes[PlayersPositions.BR] + i) % 52})
    relativeToAbsolute.push({team: PlayersPositions.BL, relative: i, absolute: (startFieldIndexes[PlayersPositions.BL] + i) % 52})
}

// tokens winning line
for (let i = 0; i < 6; i++) {
    relativeToAbsolute.push({team: PlayersPositions.TL, relative: 52 + i, absolute: `b${i}`})
    relativeToAbsolute.push({team: PlayersPositions.TR, relative: 52 + i, absolute: `r${i}`})
    relativeToAbsolute.push({team: PlayersPositions.BR, relative: 52 + i, absolute: `g${i}`})
    relativeToAbsolute.push({team: PlayersPositions.BL, relative: 52 + i, absolute: `y${i}`})
}

export const relativeToAbsoluteIndex = (relative, team) => {
    const find = relativeToAbsolute.find(e => e.relative === relative && e.team === team);
    if (!find) {
        return -1;
    }

    return find.absolute;
};

export const relativeFieldIndexToFindField = (relative, team) => {
    const absolute = relativeToAbsoluteIndex(relative, team);
    
    if (absolute === -1) {
        return null;
    }

    if (typeof absolute === 'string') {
        return GameProperties.finish[absolute];
    }

    return GameProperties.fields[absolute];
};

/**
 * For testing absolute position to relative by team
 * @param absolute
 * @param team
 * @return {string|number|null}
 */
// export const a2r = (absolute, team) => {
//     const find = relativeToAbsolute.find(e => e.absolute === absolute && e.team === team);
//     if (!find) {
//         return null;
//     }
//
//     return find.relative;
// };
