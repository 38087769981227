import * as PIXI from 'pixi.js';

export class ActivityHint extends PIXI.Container {
    constructor(model) {
        super();

        this.model = model;

        this.setup();

        this.model.updateHintsSettings$
          .subscribe(_ => {
              if (!this.model.gdh.hints) {
                  this.visible = false;
              }
          });
    }

    setup() {
        this.hintBg = this.addChild(
            new PIXI.Sprite(
                PIXI.Texture.from('hintEmpty')
            )
        );
        this.hintBg.anchor.set(0.5);
        this.hintBg.position.set(0, 0);

        this.hintImg = this.addChild(
            new PIXI.AnimatedSprite([
                PIXI.Texture.EMPTY,
                PIXI.Texture.from('hintSafe'),
                PIXI.Texture.from('hintKill'),
                PIXI.Texture.from('hintFinish'),
            ])
        );
        this.hintImg.gotoAndStop(2);
        this.hintImg.anchor.set(0.5);
        this.hintImg.position.set(0, -18);
    }

    setHint(id) {
        if (typeof id === 'number') {
            this.hintImg.gotoAndStop(id);
            this.hintBg.visible = id > 0;
        }
    }

    setVisible(val) {
        if (!this.model.gdh.hints && val) return;

        this.visible = val;
    }

    setHalfTransparent(val) {
        this.alpha = val ? 0.5 : 1;
    }
}
