import * as PIXI from "pixi.js";
import { gsap } from "gsap";
import i18next from 'i18next';
import {BehaviorSubject, filter, timer, take} from "rxjs";
import {distinctUntilChanged, pairwise, switchMap, mapTo} from "rxjs/operators";
import {EnumLayouts} from "../helpers/constants/EnumLayouts";
import { EnumGameModes } from "../helpers/constants/GameModes";


export class FadeLobbyGameScene extends PIXI.Container {
    /**
     * @param application {PIXI.Application}
     * @param sceneManager {SceneManager}
     * @param model {Model}
     * @param resizeManager {ResizeManager}
     */
    constructor(application, sceneManager, model, resizeManager) {
        super();

        this.application = application;
        this.sceneManager = sceneManager;
        this.model = model;

        this.canvasWidth = 1080;
        this.canvasHeight = 1920;

        this.graphics = new PIXI.Graphics();
        this.graphics.beginFill(0x080F3D, 0.7);
        this.graphics.drawRect(0, 0, this.canvasWidth, this.canvasHeight);
        this.graphics.endFill();

        this.container = this.addChild(new PIXI.Container());
        this.container.alpha = 0;

        this.blurContainer = this.container.addChild(new PIXI.Container());
        this.blurImage = undefined;

        this.container.addChild(this.graphics);

        /**
         * @type {BehaviorSubject<boolean>}
         */
        this.loadingShow$ = new BehaviorSubject(false);
        this.loadingContainer = this.container.addChild(new PIXI.Container());

        const loadingImage = this.loadingContainer.addChild(PIXI.Sprite.from('loaderSimple'));
        loadingImage.pivot.set(loadingImage.width / 2, loadingImage.height / 2);
        loadingImage.scale.set(1.2);
        loadingImage.position.set(539, 968);

        const text = this.loadingContainer.addChild(new PIXI.Text(`${i18next.t('preload_scene.loading')}...`, {
            fontFamily: 'Akshar-Bold',
            fontSize: '85px',
            fill: "#FFDB15",
            align: "center",
            stroke: "#240035",
            strokeThickness: 10
        }));
        text.anchor.set(0.5);
        text.position.set(541, 1174);

        this.loadingContainer.visible = false;

        let lastLoopTween;

        this.loadingShow$.pipe(
            distinctUntilChanged(),
            switchMap((value) => {
                // Emit the event after a delay of 2 seconds
                return timer(value ? 2000 : 0).pipe(mapTo(value));
            })
        )
            .subscribe(value => {
                this.loadingContainer.visible = value;

                if (value === false) {
                    lastLoopTween && lastLoopTween.kill(loadingImage);
                    lastLoopTween = undefined;
                    return;
                }

                if (lastLoopTween) { return; }
                lastLoopTween = gsap.fromTo(loadingImage, {rotation: 0}, { rotation: Math.PI * 2, duration: 2, repeat: -1, ease: 'none'});
            });

        this._initSwitchScenes();

        resizeManager.resize$
            .subscribe(({ shiftY, scale }) => {
                this.graphics.y = -(shiftY / scale);
                this.graphics.scale.y = 1 / scale;
            });
    }

    fadeLobbyToMain(callback = () => {}) {
        return new Promise(resolve => {
            if (this.timelineTo && this.timelineTo.isActive()) {
                this.timelineTo.pause(0);
                this.timelineTo.kill();
                this.timeline = undefined;
            }

            if (this.timelineFrom && this.timelineFrom.isActive()) {
                this.timelineFrom.pause(0);
                this.timelineFrom.kill();
                this.timelineFrom = undefined;
            }

            this.timelineTo = gsap.timeline({ yoyo: false })
                .to(this.container, { alpha: 1, duration: 0.3, ease: "power2.out" })
                .call(() => {
                    callback();

                    if (this.timelineTo && this.timelineTo.isActive()) {
                        this.timelineTo.pause(0);
                        this.timelineTo.kill();
                        this.timelineTo = undefined;
                    }

                    const subscription = this.model.serverGameStarted$
                        .pipe(
                            filter(e => !!e),
                            take(1)
                        )
                        .subscribe(_ => {
                            this.timelineFrom.restart();
                        });

                    if(this.model.serverGameStarted$.value === true) {
                        subscription.unsubscribe();
                        this.timelineFrom.restart();
                    }
                });

            this.timelineFrom = gsap.timeline({ yoyo: false, paused: true })
                .to(this.container, { alpha: 0, duration: 0.3, ease: "power2.out" })
                .call(() => {
                    this.timelineFrom.kill();
                    this.timelineFrom = undefined;
                    resolve();
                })
        })
    }

    fadeMainToLobby(callback = () => {}) {
        return new Promise(resolve => {
            if (this.timeline && this.timeline.isActive()) {
                this.timeline.pause(0);
                this.timeline.kill();
                this.timeline = undefined;
            }

            this.timeline = gsap.timeline({ yoyo: false })
                .to(this.container, { alpha: 1, duration: 0.3, ease: "power2.out" })
                .call(() => callback())
                .to(this.container, { alpha: 0, duration: 0.3, ease: "power2.out" })
                .call(() => {
                    this.timeline.kill();
                    this.timeline = undefined;
                    resolve();
                })
        })
    }

    _initSwitchScenes() {
        this.model.gameIsStarted$
            .pipe(distinctUntilChanged(), pairwise())
            .subscribe(([prevActive, isActive]) => {
                if (prevActive === isActive) { return; }

                if (isActive) {
                    if (this.model.mode$.value === EnumGameModes.MULTI) {
                        this._switchLobbyToMultiMain();
                    } else {
                        this._switchLobbyToMain();
                    }

                } else {
                    this._switchMainToLobby();
                }
            })

        this.model.activeMatchMaking$
            .pipe(filter(_ =>
                this.model.gameIsStarted$.value === true &&
                this.model.serverGameEnded$.value === undefined &&
                this.model.activeMatchMaking$.value === true &&
                this.model.mode$.value === 'multi'
            ))
            .subscribe(_ => {
                this._switchMainToMultiMain();
            });
    }

    async _switchLobbyToMain() {
        await this.createBlurImage();
        this.loadingShow$.next(true);

        this.sceneManager.show(EnumLayouts.FADE_IN_OUT);
        await this.fadeLobbyToMain(() => {
            this.sceneManager.hide(EnumLayouts.LOBBY_BG);
            this.sceneManager.hide(EnumLayouts.START);
            this.sceneManager.show(EnumLayouts.MAIN_BG);
            this.sceneManager.show(EnumLayouts.GAME);
            this.sceneManager.show(EnumLayouts.UI);
        });

        this.sceneManager.hide(EnumLayouts.FADE_IN_OUT);

        this.destroyBlurImage();
        this.loadingShow$.next(false);
    }

    async _switchLobbyToMultiMain() {
        await this.createBlurImage();
        this.loadingShow$.next(true);

        this.sceneManager.show(EnumLayouts.FADE_IN_OUT);

        await this.fadeLobbyToMain(() => {
            this.sceneManager.hide(EnumLayouts.START);
            this.sceneManager.show(EnumLayouts.MATCH_MAKING);
        });

        this.destroyBlurImage();
        this.loadingShow$.next(false);

        const value = await this.model.activeMatchMaking$.pipe(filter(e => e !== true), take(1)).toPromise();
        if (value === 'skip') {
            return;
        }

        await this.createBlurImage();
        this.loadingShow$.next(true);

        await this.fadeMainToLobby(() => {
            this.sceneManager.hide(EnumLayouts.LOBBY_BG);
            this.sceneManager.hide(EnumLayouts.MATCH_MAKING);
            this.sceneManager.hide(EnumLayouts.MENU);
            this.sceneManager.show(EnumLayouts.MAIN_BG);
            this.sceneManager.show(EnumLayouts.GAME);
            this.sceneManager.show(EnumLayouts.UI);
        });

        this.sceneManager.hide(EnumLayouts.FADE_IN_OUT);

        this.destroyBlurImage();
        this.loadingShow$.next(false);
    }

    async _switchMainToMultiMain() {
        await this.createBlurImage();
        this.loadingShow$.next(true);

        this.sceneManager.show(EnumLayouts.FADE_IN_OUT);

        await this.fadeLobbyToMain(() => {
            this.sceneManager.hide(EnumLayouts.MAIN_BG);
            this.sceneManager.hide(EnumLayouts.GAME);
            this.sceneManager.hide(EnumLayouts.UI);
            this.sceneManager.show(EnumLayouts.LOBBY_BG);
            this.sceneManager.show(EnumLayouts.MATCH_MAKING);
        });

        this.destroyBlurImage();
        this.loadingShow$.next(false);

        const value = await this.model.activeMatchMaking$.pipe(filter(e => e !== true), take(1)).toPromise();
        if (value === 'skip') {
            return;
        }

        await this.createBlurImage();
        this.loadingShow$.next(true);

        await this.fadeMainToLobby(() => {
            this.sceneManager.hide(EnumLayouts.LOBBY_BG);
            this.sceneManager.hide(EnumLayouts.MATCH_MAKING);
            this.sceneManager.show(EnumLayouts.MAIN_BG);
            this.sceneManager.show(EnumLayouts.GAME);
            this.sceneManager.show(EnumLayouts.UI);
        });

        this.sceneManager.hide(EnumLayouts.FADE_IN_OUT);

        this.destroyBlurImage();
        this.loadingShow$.next(false);
    }

    async _switchMainToLobby() {
        await this.createBlurImage();
        this.loadingShow$.next(true);

        this.sceneManager.show(EnumLayouts.FADE_IN_OUT);
        await this.fadeMainToLobby(() => {
            this.sceneManager.show(EnumLayouts.LOBBY_BG);
            this.sceneManager.show(EnumLayouts.START);
            this.sceneManager.hide(EnumLayouts.MAIN_BG);
            this.sceneManager.hide(EnumLayouts.GAME);
            this.sceneManager.hide(EnumLayouts.MATCH_MAKING);
            this.sceneManager.hide(EnumLayouts.UI);
        });

        this.sceneManager.hide(EnumLayouts.FADE_IN_OUT);

        this.destroyBlurImage();
        this.loadingShow$.next(false);
    }

    async createBlurImage() {
        const sprite = await this.getScreenshot();
        this.blurImage = this.blurContainer.addChild(sprite);
        this.blurImage.filters = [new PIXI.filters.BlurFilter(10)];
    }

    destroyBlurImage() {
        if (this.blurImage && this.blurImage.filters) {
            this.blurImage.filters.map((e) => e.destroy());
            this.blurImage.filters = [];
            this.blurContainer.removeChild(this.blurImage);
            this.blurImage.destroy(true);
        }
        
    }

    async getScreenshot() {
        const screenshotDataUrl = await this.application.renderer.extract.canvas();
        const texture = PIXI.Texture.from(screenshotDataUrl);
        return new PIXI.Sprite(texture);
    }
}
