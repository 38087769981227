import {interval} from "rxjs";
import {gsap} from "gsap";

const hiddenTick$ = interval(1000 / 10); // 10 FPS on hidden mode
let lastHiddenSubscription;

document.addEventListener("visibilitychange", () => {
    if (document.hidden) {
        lastHiddenSubscription = hiddenTick$.subscribe(() => {
            gsap.ticker.tick();
        });
    } else if (lastHiddenSubscription && typeof lastHiddenSubscription.unsubscribe === 'function') {
        lastHiddenSubscription.unsubscribe();
    }
});
